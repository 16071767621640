import {useContext} from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'

import {AuthenticationContext} from '@app/screens/App'

declare global {
  interface Window {
    analytics: any
  }
}

window.analytics = window.analytics || {}

interface UseAnalyticsInterface {
  track: (eventName: string, properties: {}) => void
}

const useAnalytics = (
  page?: string,
  properties?: {},
): UseAnalyticsInterface => {
  const authState = useContext(AuthenticationContext)

  useDeepCompareEffect((): void => {
    if (window.analytics && page && authState.alSpid && authState.portalId) {
      window.analytics.page(page, {
        ...properties,
        serviceProviderId: authState.alSpid,
        portalId: authState.portalId,
      })
    }
  }, [
    {page, alSpid: authState.alSpid, portalId: authState.portalId},
    properties,
  ])

  const track = (eventName: string, eventProperties: {}): void => {
    if (window.analytics && window.analytics.track) {
      window.analytics.track(eventName, {
        ...eventProperties,
        serviceProviderId: authState.alSpid,
        portalId: authState.portalId,
      })
    }
  }

  return {track}
}

export default useAnalytics
