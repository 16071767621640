/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Semantic} from '@mhd/components-library'
import {useFormContext, Controller} from 'react-hook-form'

import {FormFieldProps} from '.'

import * as styles from './styles'

const TEXTAREA_MAX_LENGTH = 500

const TextArea = (
  props: FormFieldProps & Semantic.TextAreaProps,
): JSX.Element => {
  const form = useFormContext()
  const {
    label,
    optional,
    assistiveText,
    name,
    rules,
    maxLength,
    ...otherProps
  } = props

  const assistive = assistiveText
    ? assistiveText
    : optional
    ? 'Optional'
    : undefined

  return (
    <div css={styles.fieldWrapper}>
      <Controller
        control={form.control}
        name={name}
        render={({onChange, onBlur, value, name, ref}): JSX.Element => (
          <Semantic.TextArea
            ref={ref}
            css={styles.textArea}
            maxLength={maxLength || TEXTAREA_MAX_LENGTH}
            name={name}
            rows="4"
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            {...otherProps}
          />
        )}
        rules={rules}
      />

      <label css={styles.label}>{props.label}</label>

      {assistive && <p css={styles.inputAssistiveText}>{assistive}</p>}
    </div>
  )
}

export default TextArea
