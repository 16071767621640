import React from 'react'
import {useMutation} from '@apollo/client'
import {useForm, FormProvider} from 'react-hook-form'

import Button from '@app/components/Button'
import Input from '@app/components/Form/Input'
import NavBarLayout from '@app/components/Layout/NavBarLayout'

import {LOGIN} from '@app/graphql/auth.graphql'

import useToaster from '@app/helpers/customHooks/useToaster'

type FormInputs = {
  emailAddress: string
  password: string
}

export interface LoginProps {
  alLeadId?: number
  alUrl: string
  intent?: string
}

const Login = (props: LoginProps): JSX.Element => {
  const form = useForm<FormInputs>()
  const toaster = useToaster()

  const [login, {loading: loginLoading}] = useMutation(LOGIN)

  const handleLogin = async (loginInfo: FormInputs): Promise<void> => {
    const result = await login({
      variables: {
        emailAddress: loginInfo.emailAddress,
        password: loginInfo.password,
        alUrl: props.alUrl,
      },
    })

    if (result.errors || result.data.login.error) {
      toaster.error(
        'The Email and Password combination is incorrect. Please try again',
      )
    } else {
      window.location.href = `/?s=${result.data.login.success.alSpid}&t=${
        result.data.login.success.authToken
      }&u=${props.alUrl}&i=${props.intent || 'my-customers'}${
        props.alLeadId ? `&l=${props.alLeadId}` : ''
      }`
    }
  }

  return (
    <NavBarLayout title="Login" hideBack>
      {toaster.render()}

      <FormProvider {...form}>
        <Input
          ref={form.register}
          id="bmt__login_email-input"
          inputMode="email"
          label="Email"
          name="emailAddress"
          type="email"
        />
        <Input
          ref={form.register}
          id="bmt__login_password-input"
          label="Password"
          name="password"
          type="password"
        />

        <Button
          id="bmt__login_index_login-button"
          loading={loginLoading}
          onClick={form.handleSubmit(handleLogin)}
        >
          Login
        </Button>
      </FormProvider>
    </NavBarLayout>
  )
}

export default Login
