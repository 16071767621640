/** @jsx jsx */
import {jsx} from '@emotion/core'
import {HTMLProps} from 'react'
import {RegisterOptions, useFormContext} from 'react-hook-form'

import {FormFieldProps} from '.'

import * as styles from './styles'
export interface InputProps {
  label: string
  name: string
  suffix?: string
  onUpdate?: (value: string) => void
  rules?: RegisterOptions
  inputMode?: string //https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode
}

const NUMBER_INPUT_MAX = 1000000
const INPUT_MAX_LENGTH = 255

const Input = (
  props: FormFieldProps & InputProps & HTMLProps<HTMLInputElement>,
): JSX.Element => {
  const {
    label,
    optional,
    suffix,
    prefix,
    assistiveText,
    onUpdate,
    rules,
    name,
    ...otherProps
  } = props

  const form = useFormContext()
  const value = form.watch(name)
  const emptyValue = value === '' || value === null || value === undefined
  const hasError = !!form.errors[name]
  const errorMsg = form.errors[name]?.message

  return (
    <div
      css={[
        styles.fieldWrapper,
        hasError ? styles.fieldError : null,
        props.disabled ? styles.inputDisabled : null,
      ]}
    >
      <label>
        {prefix && <div css={styles.inputPrefix}>{prefix}</div>}
        <input
          ref={form.register(rules)}
          css={[
            styles.input,
            suffix ? styles.inputWithSuffix : null,
            prefix ? styles.inputWithPrefix : null,
          ]}
          max={otherProps.type === 'number' ? NUMBER_INPUT_MAX : undefined}
          maxLength={
            rules?.maxLength ? Number(rules.maxLength) : INPUT_MAX_LENGTH
          }
          min={otherProps.type === 'number' ? -NUMBER_INPUT_MAX : undefined}
          name={name}
          {...otherProps}
          onChange={(e): void => {
            if (onUpdate) onUpdate(e.target.value)
          }}
        />

        <span
          className="label"
          css={
            emptyValue && props.type !== 'date'
              ? [styles.label, styles.placeholderLabel]
              : styles.label
          }
        >
          {label}
        </span>

        {suffix && <div css={styles.inputSuffix}>{suffix}</div>}
      </label>

      {(errorMsg || assistiveText || optional) && (
        <p
          css={[
            styles.inputAssistiveText,
            errorMsg ? styles.inputErrorMsg : null,
          ]}
        >
          {errorMsg || assistiveText || 'Optional'}
        </p>
      )}
    </div>
  )
}

export default Input
