import gql from 'graphql-tag'

export const STORED_CUSTOMER = gql`
  fragment Customer on StoredCustomerResponse {
    id
    customerId
    alId
    leadId
    fullName
    firstName
    lastName
    email
    phone
    address {
      fullAddress
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
    default
  }
`

export const SAVE_CUSTOMER = gql`
  ${STORED_CUSTOMER}
  mutation SaveCustomer($portalId: ID!, $input: StoredCustomerInput!) {
    saveCustomer(portalId: $portalId, input: $input) {
      ...Customer
      notes
    }
  }
`

export const GET_CUSTOMER = gql`
  ${STORED_CUSTOMER}
  query GetCustomer($portalId: ID!, $customerId: ID!) {
    getCustomer(portalId: $portalId, customerId: $customerId) {
      ...Customer
      notes
    }
  }
`

export const GET_CUSTOMERS = gql`
  ${STORED_CUSTOMER}
  query GetCustomers(
    $portalId: ID!
    $offset: Int!
    $limit: Int!
    $sortOrder: String!
    $filter: String
  ) {
    getCustomers(
      portalId: $portalId
      offset: $offset
      limit: $limit
      sortOrder: $sortOrder
      filter: $filter
    ) {
      customers {
        ...Customer
      }
      totalRows
      offset
      limit
      sortOrder
      filter
    }
  }
`

export const GET_CONTACTS = gql`
  query GetContacts(
    $portalId: ID!
    $offset: Int!
    $limit: Int!
    $sortOrder: String!
    $customerId: Int
    $filter: String
    $recordStatus: [RecordStatus]
  ) {
    getContacts(
      portalId: $portalId
      offset: $offset
      limit: $limit
      sortOrder: $sortOrder
      customerId: $customerId
      filter: $filter
      recordStatus: $recordStatus
    ) {
      contacts {
        id
        customerId
        fullName
        firstName
        lastName
        email
        phone
        address {
          lineOne
          city
          state
          zip
        }
        default
      }
      totalRows
      offset
      limit
      sortOrder
    }
  }
`

export const SAVE_ROOT_CUSTOMER = gql`
  mutation SaveRootCustomer($portalId: ID!, $input: RootCustomerInput!) {
    saveRootCustomer(portalId: $portalId, input: $input) {
      id
      alId
      displayName
      fullName
      firstName
      lastName
      email
      phone
      address {
        lineOne
        lineTwo
        city
        state
        zip
        country
      }
    }
  }
`

export const GET_ROOT_CUSTOMERS = gql`
  query GetRootCustomers(
    $portalId: ID!
    $offset: Int!
    $limit: Int!
    $sortOrder: String!
    $filter: String
  ) {
    getRootCustomers(
      portalId: $portalId
      offset: $offset
      limit: $limit
      sortOrder: $sortOrder
      filter: $filter
    ) {
      customers {
        id
        alId
        displayName
        fullName
        firstName
        lastName
        email
        phone
        address {
          lineOne
          lineTwo
          city
          state
          zip
          country
        }
      }
      totalRows
      offset
      limit
      sortOrder
      filter
    }
  }
`
export const GET_ROOT_CUSTOMER = gql`
  query GetRootCustomer($portalId: ID!, $customerId: ID!) {
    getRootCustomer(portalId: $portalId, customerId: $customerId) {
      id
      alId
      fullName
      displayName
      firstName
      lastName
      email
      phone
      address {
        lineOne
        lineTwo
        city
        state
        zip
        country
      }
    }
  }
`
