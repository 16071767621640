import React, {useState} from 'react'
import Modal, {ModalProps} from '@app/components/Layout/Modal'

interface UseModalInterface {
  hide: () => void
  show: () => void
  render: JSX.Element | null
}

export default (modalProps: ModalProps): UseModalInterface => {
  const [visible, setVisible] = useState(false)

  const show = (): void => setVisible(true)

  const hide = (): void => {
    setVisible(false)
  }

  const render = <Modal visible={visible} {...modalProps} />

  return {
    hide,
    show,
    render,
  }
}
