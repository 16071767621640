import {css} from '@emotion/core'
import {textLight, textMedium, danger, text} from '@app/styles/colors'
import {borderRadius} from '@app/styles/variables'

const labelTop = '-0.5rem'
const labelLeft = '1rem'
const labelFontSize = '1.4rem'

export const fieldWrapper = css({
  position: 'relative',
  padding: '0.75rem 0 0 0',
  marginBottom: '2rem',
})

export const fieldValue = css({
  marginTop: '0 !important',
})

export const label = css({
  position: 'absolute',
  top: labelTop,
  left: labelLeft,
  fontSize: labelFontSize,
  padding: '0 5px',
  backgroundColor: 'white',
  zIndex: 11,
  background: 'linear-gradient(180deg, transparent 47%, white 47%)',
  fontWeight: 500,
})

export const placeholderLabel = css({
  top: '1.6rem',
  transition: 'all 0.4s',
  fontSize: '1.6rem',
  color: textMedium,
  fontWeight: 400,
})

export const multiselectLabel = css({
  zIndex: 11,
})

export const textArea = css({
  border: `1px solid ${textLight} !important`,
  borderRadius: borderRadius,
  padding: '1.2rem !important',
  resize: 'none',
  width: '100%',

  '&:focus': {
    '~ span': {
      fontWeight: 500,
      color: text,
    },
  },
})

export const input = css({
  padding: '1.2rem 1.6rem',
  background: 'white',
  border: `1px solid ${textLight} !important`,
  borderRadius: borderRadius,
  width: '100%',
})

export const inputDisabled = css({
  opacity: 0.4,
})

export const emptyInput = css({
  '&:focus': {
    '~ span': {
      top: labelTop,
      left: labelLeft,
      fontSize: labelFontSize,
      zIndex: 2,
      fontWeight: 500,
      color: text,
    },
  },
})

export const multiselect = css({
  width: '100%',
  '&.active ~ label': {
    zIndex: 15,
  },
})

export const fieldError = css({
  '& input': {
    borderColor: `${danger} !important`,
    boxShadow: 'none !important',
  },
  '& span.label': {
    color: `${danger} !important`,
    fontWeight: 500,
  },
  '& label': {
    color: `${danger} !important`,
    fontWeight: 500,
  },
  '& .dropdown:not(.active)': {
    borderColor: `${danger} !important`,
  },
})

export const inputAssistiveText = css({
  fontSize: '1.4rem',
  color: textMedium,
})

export const inputErrorMsg = css({
  color: danger,
})

export const inputSuffix = css({
  position: 'absolute',
  top: '1.6rem',
  right: '1rem',
})

export const inputWithSuffix = css({
  paddingRight: '3rem',
})

export const inputPrefix = css({
  position: 'absolute',
  top: '1.6rem',
  left: '1rem',
})

export const inputWithPrefix = css({
  paddingLeft: '3rem',
})

export const searchInput = css({
  paddingRight: '3.5rem',
})

export const searchIcon = css({
  position: 'absolute',
  right: '1rem',
  top: '1.6rem',
  height: 24,
  width: 24,
})

export const cityState = css({
  gridTemplateColumns: '63% auto',
})

export const zip = css({
  gridTemplateColumns: '50% auto',
})
