/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Semantic} from '@mhd/components-library'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'

export interface ItemClickEventProperties {
  description: string
  activityLocation: string
}

export interface ButtonProps {
  id?: string
  event?: ItemClickEventProperties | {}
  onClick: () => void
}

const Button = (
  props: ButtonProps & Semantic.StrictButtonProps,
): JSX.Element => {
  const {track} = useAnalytics()

  const {event, secondary, onClick, ...otherProps} = props

  const handleClick = (): void => {
    if (event) {
      track('Item Clicked', event)
    }
    onClick()
  }

  return (
    <Semantic.Button
      basic={secondary}
      primary={!secondary}
      fluid
      onClick={handleClick}
      {...otherProps}
    />
  )
}

export default Button
