/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Semantic} from '@mhd/components-library'
import {useFormContext, Controller} from 'react-hook-form'

import {FormFieldProps} from '.'

import * as styles from './styles'

const Dropdown = (
  props: FormFieldProps & Semantic.DropdownProps,
): JSX.Element => {
  const form = useFormContext()
  const {label, name, ...otherProps} = props
  return (
    <div css={styles.fieldWrapper}>
      <Controller
        control={form.control}
        name={name}
        render={({onChange, onBlur, value, name, ref}): JSX.Element => (
          <Semantic.Dropdown
            ref={ref}
            css={styles.input}
            basic
            fluid
            selection
            {...otherProps}
            name={name}
            value={value}
            onBlur={onBlur}
            onChange={(e, {value}): void => onChange(value)}
          >
            {props.children}
          </Semantic.Dropdown>
        )}
      />

      <label css={styles.label}>{props.label}</label>
    </div>
  )
}

export default Dropdown
