import {css} from '@emotion/core'
import {Semantic} from '@mhd/components-library'

export const message = css({
  borderRadius: '0.5rem !important',
  padding: '4px 10px !important',
  fontSize: '16px !important',
  marginLeft: 'auto !important',
  font: 'Rubik !important',
  position: 'absolute',
  fontWeight: 'bold',
})

export const messageColor = (type: string): Semantic.SemanticCOLORS => {
  switch (type) {
    case 'error':
      return 'red'
    case 'success':
      return 'green'
    default:
      return 'green'
  }
}
export const toasterSvg = css({
  width: 20,
  height: 20,
  marginRight: 10,
  verticalAlign: '-0.9rem',
})
export const editAddressText = css({
  verticalAlign: '-0.5rem',
})
