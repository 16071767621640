/** @jsx jsx */
import {jsx} from '@emotion/core'
import {ReactNode, useContext} from 'react'

import {NavContext} from '@app/context/NavContext'

import NavBar, {NavBarProps} from '../Nav/NavBar'

import * as styles from './styles'

export interface NavBarLayoutProps {
  children: ReactNode
  footer?: ReactNode
  greyBg?: boolean
}

const NavBarLayout = (props: NavBarLayoutProps & NavBarProps): JSX.Element => {
  const {goBack} = useContext(NavContext)

  const handleBack = (): boolean => {
    const isBack = !props.onBack || props.onBack()
    if (isBack) goBack()
    return isBack
  }

  const {children, footer, greyBg, ...otherProps} = props

  return (
    <div
      css={[styles.navBarLayout, props.footer ? styles.layoutWithFooter : null]}
    >
      <NavBar {...otherProps} onBack={handleBack} />
      <div css={greyBg ? styles.contentGreyBg : null} />
      <div>{children}</div>

      {footer}
    </div>
  )
}

export default NavBarLayout
