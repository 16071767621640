import {css} from '@emotion/core'
import {textLight, textMedium, text} from '@app/styles/colors'

export const cityState = css({
  gridTemplateColumns: '63% auto',
})

export const zip = css({
  gridTemplateColumns: '50% auto',
})

export const customerDetail = css({
  display: 'grid',
  gridTemplateColumns: '10% auto',
  gridRowGap: '2rem',

  '& *': {
    alignSelf: 'center',
  },
  '& .icon': {
    marginTop: '-0.7rem',
  },
})

export const selectCustomerInfo = css({
  fontSize: '1.4rem',
  color: textMedium,
  marginTop: '2rem',
})

export const selectCustomerHeader = css({
  color: textLight,
  fontSize: '1.4rem',
  fontWeight: 700,
  marginBottom: '0.6rem',
})

export const subHeader = css({
  display: 'grid',
  gridColumnGap: 20,
  gridRowGap: 25,
  gridTemplateColumns: '50% auto',
})

export const duplicateContact = css({
  textAlign: 'right',
  fontSize: '14px',
})

export const duplicateContactImg = css({
  verticalAlign: 'middle',
  paddingRight: '0.4rem',
})

export const formSectionHeader = css({
  marginBottom: '0.8rem !important',
})

export const scrollBar = css({
  marginTop: '2rem',
  maxHeight: '55vh',
  overflowX: 'hidden',
  overflowY: 'scroll',
  paddingRight: '0.5em',
  '&.scroll-bar::-webkit-scrollbar': {
    display: 'none',
  },
})

export const notesSection = css({
  marginTop: '1.5rem',
  whiteSpace: 'pre-wrap',
})
export const notes = css({
  color: text,
  '&:hover,&:focus': {
    color: text,
  },
})
