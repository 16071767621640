import {css} from '@emotion/core'
import {containerPadding} from '@app/styles/variables'
import {bgGrey, bgGreyLight} from '@app/styles/colors'

export const item = css({
  marginRight: `-${containerPadding}`,
  marginLeft: `-${containerPadding}`,
  paddingLeft: containerPadding,
  paddingRight: containerPadding,
  position: 'relative',
  overflow: 'hidden',
})

export const clickableItem = css({
  cursor: 'pointer',
  paddingTop: '1.5rem',
  paddingBottom: '1.5rem',
  borderBottom: `1px solid ${bgGrey}`,
  '&:first-of-type': {
    borderTop: `1px solid ${bgGrey}`,
  },
  backgroundColor: 'white',
})

export const alternatingItem = css({
  '&:nth-of-type(even)': {
    backgroundColor: bgGreyLight,
  },
  paddingTop: '1.0rem',
  paddingBottom: '1.0rem',
})

export const caret = css({
  position: 'absolute',
  right: '1rem',
  top: '37%',
})
