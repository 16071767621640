/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Semantic} from '@mhd/components-library'

import {actionDropdown} from './styles'

const ActionDropdown = (props: Semantic.StrictDropdownProps): JSX.Element => (
  <Semantic.Dropdown
    css={actionDropdown}
    direction="left"
    icon="ellipsis horizontal"
    id="bmt__nav_action-button"
    button
    floating
  >
    {props.children}
  </Semantic.Dropdown>
)

export default ActionDropdown
