import {useQuery} from '@apollo/client'

import {GET_ROOT_CUSTOMER} from '@app/graphql/customer.graphql'
import useGraphOptions from '@app/helpers/customHooks/graphOptions'
import {Customer} from '@app/models/customerModels'

interface UseRootCustomerInterface {
  rootCustomer?: Customer
}

const useRootCustomer = (rootCustomerId?: number): UseRootCustomerInterface => {
  const {data} = useQuery(
    GET_ROOT_CUSTOMER,
    useGraphOptions({customerId: rootCustomerId}),
  )

  return {
    rootCustomer: data?.getRootCustomer,
  }
}

export default useRootCustomer
