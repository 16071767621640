/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Semantic} from '@mhd/components-library'

import * as styles from './styles'

export interface StatusLabelProps {
  status: string
  onClick?: () => void
}

const StatusLabel = (props: StatusLabelProps): JSX.Element => (
  <Semantic.Label
    as={props.onClick ? 'a' : null}
    color={styles.statusColor(props.status)}
    css={[styles.statusLabel, props.onClick ? styles.statusButton : null]}
    id="bmt__transaction-edit_status_status-label"
    size="tiny"
    basic
    onClick={props.onClick}
  >
    {props.status}{' '}
    {props.onClick && <Semantic.Icon name="angle down" style={{margin: 0}} />}
  </Semantic.Label>
)

export default StatusLabel
