import {useContext} from 'react'
import {useQuery} from '@apollo/client'

import useGraphOptions from '@app/helpers/customHooks/graphOptions'

import {AuthenticationContext} from '@app/screens/App'

import {GET_NEXT_INVOICE_NUMBER} from '@app/graphql/invoice.graphql'
import {GET_NEXT_QUOTE_NUMBER} from '@app/graphql/quote.graphql'

interface UseTransactionInterface {
  nextNumber?: string
}

const useTransaction = (
  quote: boolean | undefined = undefined,
): UseTransactionInterface => {
  const authState = useContext(AuthenticationContext)
  const isQuote = quote || authState.intent.includes('quote')

  // next invoice/quote number
  const nextNumberQuery = useQuery(
    isQuote ? GET_NEXT_QUOTE_NUMBER : GET_NEXT_INVOICE_NUMBER,
    {
      ...useGraphOptions({}),
      fetchPolicy: 'no-cache',
    },
  )
  const nextNumber = nextNumberQuery.data
    ? isQuote
      ? nextNumberQuery.data.getNextQuoteNumber
      : nextNumberQuery.data.getNextInvoiceNumber
    : undefined

  return {
    nextNumber: nextNumber ? nextNumber.number : undefined,
  }
}

export default useTransaction
