/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useState, useEffect, useContext} from 'react'
import {useLazyQuery} from '@apollo/client'
import {Semantic} from '@mhd/components-library'
import Button from '@app/components/Button'
import Loading from '@app/components/Loading'

import {NavContext} from '@app/context/NavContext'
import {PageNames} from '@app/helpers/constants'
import useGraphOptions from '@app/helpers/customHooks/graphOptions'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'
import useCustomer from '@app/helpers/customHooks/useCustomer'
import useModal from '@app/helpers/customHooks/useModal'
import useTransaction from '@app/helpers/customHooks/useTransaction'

import ImportQuoteModal from '@app/screens/Invoices/AddEditInvoice/ImportQuoteModal'

import {GET_QUOTES} from '@app/graphql/quote.graphql'
import {Quote} from '@app/models/quoteModels'
import {Invoice} from '@app/models/invoiceModels'

import InvoiceForm from './InvoiceForm'

import * as styles from './styles'

const NewInvoice = (): JSX.Element => {
  const {toaster} = useContext(NavContext)
  const {leadId, customer, isCustomer} = useCustomer()
  const {nextNumber} = useTransaction()

  const {track} = useAnalytics(PageNames.createInvoice, {
    leadId,
    invoiceId: null,
  })

  // Load quotes for customer if existing customer/lead
  const graphOptions = useGraphOptions({})
  const [getQuotes, quotes] = useLazyQuery(GET_QUOTES, {
    fetchPolicy: 'cache-and-network',
  })
  useEffect(() => {
    if (customer || leadId) {
      const filters: {customerId?: number; leadId?: number} = {}

      if (customer && customer.id) {
        filters.customerId = customer.id
      } else {
        filters.leadId = leadId
      }

      getQuotes({
        context: graphOptions.context,
        variables: {
          ...graphOptions.variables,
          ...filters,
          offset: 0,
          limit: 500,
          customerId: customer?.id,
        },
      })
    }
  }, []) // eslint-disable-line

  const [invoice, setInvoice] = useState<Invoice>({
    number: nextNumber,
    createdDate: new Date().toLocaleString(),
    pdfName: '',
    amount: 0,
    discountAmount: 0,
    items: [],
    status: 'Draft',
  })

  // Import Quote modal, load imported quote
  const [showImport, setShowImport] = useState(true)
  const importQuoteModal = useModal({
    children: (
      <ImportQuoteModal
        customerId={customer?.id}
        leadId={customer?.leadId || leadId}
        onClose={(): void => importQuoteModal.hide()}
        onQuoteSelected={(importQuote: Quote): void => {
          track('Item Clicked', {
            description: 'user selects a quote',
            activityLocation: PageNames.importQuote,
          })

          importQuoteModal.hide()
          setShowImport(false)

          setInvoice(prev => ({
            ...prev,
            discount: importQuote?.discount,
            tax: importQuote?.tax,
            items: importQuote?.items || [],
            quoteId: importQuote?.id,
            customerNotes: importQuote?.customerNotes,
            privateNotes: importQuote.privateNotes,
          }))
          toaster.success('Quote Imported!')
        }}
      />
    ),
  })

  useEffect(() => {
    if (nextNumber) {
      setInvoice({...invoice, number: nextNumber})
    }
  }, [nextNumber])

  if (!customer || !invoice.number) return <Loading al />

  const hasQuotes = quotes.data && quotes.data.getQuotes.quotes.length > 0
  return (
    <InvoiceForm
      customer={customer}
      hideImportQuote={showImport}
      invoice={invoice}
      isCustomer={isCustomer}
      leadId={leadId}
      pageName={PageNames.createInvoice}
    >
      {showImport && hasQuotes && (
        <div className="fullWidth" css={styles.importBanner}>
          <Semantic.Icon
            css={styles.bannerClose}
            name="close"
            link
            onClick={(): void => {
              setShowImport(false)
            }}
          />
          Start building your invoice, or import line items from an existing
          quote.
          <Button
            css={styles.importButton}
            event={{
              description: 'user clicks the import existing quote button',
              activityLocation: PageNames.createInvoice,
              invoiceId: null,
            }}
            secondary
            onClick={(): void => {
              importQuoteModal.show()
            }}
          >
            Import Existing Quote
          </Button>
        </div>
      )}
      {importQuoteModal.render}
    </InvoiceForm>
  )
}

export default NewInvoice
