import {PERCENT_TYPE, PRODUCT_TYPE, SERVICE_TYPE} from '../helpers/constants'
import {PriceListItem} from '@app/models/priceListItemModels'
import {Discount} from '@app/models/transactionModels'
import {SalesTax} from '@app/models/salesTaxModels'

// https://stackoverflow.com/questions/3108986/gaussian-bankers-rounding-in-javascript
const bankersRound = (num: number, decimalPlaces = 2): number => {
  const d = decimalPlaces || 0
  const m = 10 ** d
  const n = +(d ? num * m : num).toFixed(8) // Avoid rounding errors
  const i = Math.floor(n)
  const f = n - i
  const e = 1e-8 // Allow for rounding errors in f
  const bankRound = i % 2 === 0 ? i : i + 1
  const r = f > 0.5 - e && f < 0.5 + e ? bankRound : Math.round(n)
  return d ? r / m : r
}

const itemTotal = (item: PriceListItem): number =>
  bankersRound(
    item.type === PRODUCT_TYPE && item.product
      ? Number(item.product.quantity) * Number(item.product.price)
      : item.type === SERVICE_TYPE && item.service
      ? Number(item.service.duration) * Number(item.service.rate)
      : 0,
  )

export default class Calculator {
  public constructor(
    private readonly items?: PriceListItem[],
    private readonly tax?: SalesTax,
    private readonly discount?: Discount,
  ) {}

  private bankersRound = bankersRound
  private itemTotal = itemTotal
  static bankersRound = bankersRound
  static itemTotal = itemTotal

  public subtotal(): number {
    return (
      this.items?.reduce((total: number, item: PriceListItem): number => {
        return total + this.itemTotal(item)
      }, 0) || 0
    )
  }

  public taxAmount(): number {
    return this.conditionalTaxAmount(true)
  }

  public discountAmount(): number {
    return this.bankersRound(
      this.discount?.type === PERCENT_TYPE
        ? this.subtotal() * this.discount.amount * 0.01
        : this.discount?.amount || 0,
    )
  }

  public nonDiscountedTotal(): number {
    return this.subtotal() + this.conditionalTaxAmount(false)
  }

  public discountedTotal(): number | undefined {
    return !this.discount || !this.items ? undefined : this.total()
  }

  public total(): number {
    return this.bankersRound(
      this.subtotal() + this.taxAmount() - this.discountAmount(),
    )
  }

  private conditionalTaxAmount(withDiscount: boolean): number {
    return this.bankersRound(
      this.taxableSubtotal(withDiscount) * (this.tax ? this.tax.value : 0),
    )
  }

  private taxableSubtotal(withDiscount: boolean): number {
    const taxableTotal = this.taxableItemTotal()

    if (!withDiscount || !this.discount || this.discount.postTax) {
      return taxableTotal
    }

    const subtotal = this.subtotal()

    if (subtotal === 0) {
      return 0
    }

    return this.bankersRound(
      this.discount.type === PERCENT_TYPE
        ? taxableTotal - taxableTotal * this.discount.amount * 0.01
        : taxableTotal - this.discount.amount * (taxableTotal / subtotal),
    )
  }

  private taxableItemTotal(): number {
    return (
      this.items?.reduce((total: number, item: PriceListItem): number => {
        return item.taxable ? total + this.itemTotal(item) : total
      }, 0) || 0
    )
  }
}
