/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Fragment, useState} from 'react'
import {useForm, FormProvider} from 'react-hook-form'
import {Semantic} from '@mhd/components-library'
import {formatRate} from '@mhd/components-library/dist/helpers/formatter'

import Button from '@app/components/Button'
import Dropdown from '@app/components/Form/Dropdown'
import Input from '@app/components/Form/Input'
import TextArea from '@app/components/Form/TextArea'
import NavBarLayout from '@app/components/Layout/NavBarLayout'
import CloseIcon from '@app/components/Layout/CloseIcon'

import {
  addServerErrors,
  formatNumber,
  formatCurrency,
  VALIDATE_DECIMAL_NUMBER,
} from '@app/helpers'
import Calculator from '@app/helpers/calculator'
import {MAX_INPUT_NUMBER, PageNames} from '@app/helpers/constants'

import {useDialog, useSaveDialog} from '@app/helpers/customHooks/dialog'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'

import {PriceListItem} from '@app/models/priceListItemModels'
import useDeepCompareEffect from 'use-deep-compare-effect'

export interface PriceListItemModalProps {
  item: PriceListItem
  isNew?: boolean
  onSubmit: (
    item: PriceListItem,
    close?: boolean,
  ) => Promise<string | undefined>
  onRemove?: () => void
  onClose: () => void
  onBack?: () => void
}

export interface PriceListFormInputs {
  type: string
  name: string
  description: string
  quantity?: number | string
  duration: number | string
  price?: number | string
  taxable: boolean
  sku?: string
  category?: string
}

const PriceListItemModal = ({
  item,
  ...props
}: PriceListItemModalProps): JSX.Element => {
  const defaultValues = (): PriceListFormInputs => {
    const service = item?.service
    const product = item?.product
    return {
      type: item?.type || 'Product',
      name: item?.name || '',
      description: item?.description || '',
      quantity: props.isNew ? '1' : product ? product.quantity.toString() : '',
      duration: props.isNew ? '1' : service ? service.duration.toString() : '',
      sku: product ? item.sku : '',
      price: product
        ? formatRate(product.price.toString())
        : service
        ? formatRate(service.rate.toString())
        : '',
      taxable: item && item.taxable === false ? false : true,
    }
  }

  const form = useForm<PriceListFormInputs>({
    criteriaMode: 'all',
    defaultValues: defaultValues(),
  })
  const {isDirty} = form.formState

  useDeepCompareEffect(() => {
    form.reset(defaultValues())
  }, [item || {}])

  const isProduct = form.watch('type') === 'Product'
  const isCreate = props.isNew && !item.id
  const [isBack, setIsBack] = useState(false)
  const idRoot = 'bmt__transaction-edit_items_price-list-item-modal_'

  const pageName = !props.isNew
    ? PageNames.editItem
    : isCreate
    ? PageNames.createItem
    : PageNames.addItem
  const {track} = useAnalytics(pageName)

  const handleSubmit = async (formData: PriceListFormInputs): Promise<void> => {
    if (!props.isNew) {
      track('Item Clicked', {
        description: 'user clicks save item from items list edit item page',
        activityLocation: PageNames.editItem,
      })
    } else if (isCreate) {
      track('Item Clicked', {
        description:
          'clicking save & add button from the items list create item',
        activityLocation: PageNames.createItem,
      })
    } else {
      track('Item Clicked', {
        description: 'user clicks the add item button',
        activityLocation: PageNames.addItem,
      })
    }

    const error = await props.onSubmit(
      {
        id: item?.id || undefined,
        name: item?.name || formData.name,
        type: isCreate ? formData.type : item?.type,
        description: formData.description,
        sku: formData.sku,
        product:
          formData.type === 'Product'
            ? {
                quantity: isCreate ? 1 : Number(formData.quantity),
                price: Number(formData.price),
              }
            : undefined,
        service:
          formData.type === 'Service'
            ? {
                duration: isCreate ? 1 : Number(formData.duration),
                rate: Number(formData.price),
              }
            : undefined,
        taxable: formData.taxable,
        category: formData.category || undefined,
      },
      !isBack,
    )
    if (error) {
      if (error.includes('exists')) {
        addServerErrors({name: ['Item Name already exists']}, form.setError)
      }
      return
    }

    if (isBack && props.onBack) {
      props.onBack()
      setIsBack(false)
    }
  }

  // Remove
  const removeDialog = useDialog({
    title: 'Warning Remove Item',
    content: 'Are you sure you want to remove this item?',
    primaryText: 'Remove',
    secondaryText: 'Cancel',
    onSubmit: (remove: boolean): boolean => {
      if (remove && props.onRemove) {
        props.onRemove()
      }
      return true
    },
  })

  const handleRemove = (): void => {
    track('Item Clicked', {
      description: 'user clicks remove item from the items list edit item page',
      activityLocation: PageNames.editItem,
    })
    removeDialog.show()
  }

  // Save
  const saveDialog = useSaveDialog({
    onSubmit: (save: boolean): boolean => {
      if (save) {
        form.handleSubmit(handleSubmit)()
      } else if (isBack && props.onBack) {
        props.onBack()
        setIsBack(false)
      } else {
        props.onClose()
      }
      return true
    },
  })

  // Close
  const handleClose = (): void => {
    if (isCreate) {
      saveDialog.show()
      return
    }

    if (isDirty) {
      saveDialog.show()
      return
    }

    props.onClose()
  }

  const quantityValue = form.watch('quantity')
  const durationValue = form.watch('duration')
  const quantity = isProduct ? quantityValue : durationValue

  const price = form.watch('price')

  const blankFields =
    price === '' ||
    (isProduct && quantityValue === '') ||
    (!isProduct && durationValue === '')

  const total = blankFields
    ? '-'
    : formatCurrency(Calculator.bankersRound(Number(quantity) * Number(price)))

  return (
    <NavBarLayout
      actionButton={
        <div onClick={handleClose}>
          <CloseIcon />
        </div>
      }
      hideBack={!props.onBack}
      title={!props.isNew ? 'Edit Item' : isCreate ? 'Create Item' : 'Add Item'}
      onBack={(): boolean => {
        if (isCreate) {
          setIsBack(true)

          if (isDirty) {
            saveDialog.show()
          } else if (props.onBack) {
            props.onBack()
          }
        } else if (props.onBack) {
          props.onBack()
        }

        return false
      }}
    >
      <FormProvider {...form}>
        <Dropdown
          disabled={!isCreate}
          id={`${idRoot}type-field`}
          label="Type"
          name="type"
          options={[
            {
              key: 'Product',
              text: 'Product',
              value: 'Product',
            },
            {
              key: 'Service',
              text: 'Service',
              value: 'Service',
            },
          ]}
        />
        {form.watch('category') && (
          <Input
            css={{color: '#666'}}
            disabled={!isCreate}
            id={`${idRoot}category-field`}
            label="Category"
            name="category"
          />
        )}
        <Input
          css={isCreate ? {} : {color: '#666'}}
          disabled={!isCreate}
          id={`${idRoot}item-name-field`}
          label="Item Name"
          maxLength={50}
          name="name"
          rules={{
            required: 'This field is required.',
            pattern: {
              value: /^[^”“":]+$/,
              message:
                'The following characters can’t be used in the Item Name: double quote (") and colon (:).',
            },
          }}
        />
        <TextArea
          id={`${idRoot}description-field`}
          label="Description"
          maxLength={500}
          name="description"
          placeholder="Enter a description..."
          optional
        />
        {isProduct ? (
          <Fragment>
            <Input
              id={`${idRoot}sku-field`}
              label="SKU"
              maxLength={100}
              name="sku"
              rules={{
                pattern: {
                  value: /^[^:]+$/,
                  message: 'The SKU cannot contain a colon (:).',
                },
              }}
            />
            {!isCreate && (
              <Input
                id={`${idRoot}quantity-field`}
                inputMode="decimal"
                label="Quantity"
                min={0}
                name="quantity"
                rules={{
                  max: {
                    value: MAX_INPUT_NUMBER,
                    message: `Maximum value ${formatNumber(MAX_INPUT_NUMBER)}`,
                  },
                  min: {
                    value: 0,
                    message: 'Cannot be negative',
                  },
                  pattern: VALIDATE_DECIMAL_NUMBER,
                }}
                type="number"
              />
            )}
          </Fragment>
        ) : (
          !isCreate && (
            <Input
              id={`${idRoot}duration-field`}
              inputMode="decimal"
              label="Duration (hours)"
              min={0}
              name="duration"
              rules={{
                min: {
                  value: 0,
                  message: 'Cannot be negative',
                },
                max: {
                  value: MAX_INPUT_NUMBER,
                  message: `Maximum value ${formatNumber(MAX_INPUT_NUMBER)}`,
                },
                pattern: VALIDATE_DECIMAL_NUMBER,
              }}
              step={0.01}
              type="number"
            />
          )
        )}
        <Input
          id={`${idRoot}${isProduct ? 'price' : 'rate'}-field`}
          inputMode="decimal"
          label={isProduct ? 'Price' : 'Rate'}
          min={0}
          name="price"
          rules={{
            min: {
              value: 0,
              message: 'Cannot be negative',
            },
            max: {
              value: MAX_INPUT_NUMBER,
              message: `Maximum value ${formatNumber(MAX_INPUT_NUMBER)}`,
            },
            pattern: VALIDATE_DECIMAL_NUMBER,
          }}
          step={0.01}
          type="number"
        />
        <Dropdown
          id={`${idRoot}taxable-field`}
          label="Taxable"
          name="taxable"
          options={[
            {
              key: true,
              text: 'Yes',
              value: true,
            },
            {
              key: false,
              text: 'No',
              value: false,
            },
          ]}
        />
        Total:
        <Semantic.Header as="h1">{total}</Semantic.Header>
        <div css={{display: 'flex'}}>
          {!props.isNew ? (
            <Button
              id={`${idRoot}remove-item-button`}
              secondary
              onClick={handleRemove}
            >
              Remove Item
            </Button>
          ) : null}
          <Button
            id={`${idRoot}${
              !props.isNew
                ? 'save-item-button'
                : isCreate
                ? 'save-add-button'
                : 'add-item-button'
            }`}
            onClick={form.handleSubmit(handleSubmit)}
          >
            {!props.isNew ? 'Save Item' : isCreate ? 'Save & Add' : 'Add Item '}
          </Button>
        </div>
      </FormProvider>
      {saveDialog.render}
      {removeDialog.render}
    </NavBarLayout>
  )
}

export default PriceListItemModal
