/** @jsx jsx */
import {jsx} from '@emotion/core'
import {ChangeEventHandler} from 'react'
import {DebounceInput} from 'react-debounce-input'

import magnify from '../../assets/images/magnify.svg'

import * as styles from './styles'

export interface SearchInputProps {
  onChange: ChangeEventHandler
  value: string
  label: string
}

const SearchInput = (props: SearchInputProps): JSX.Element => {
  const idRoot = 'bmt__form_search-input_'
  const {label, value, ...otherProps} = props

  return (
    <div css={styles.fieldWrapper}>
      <label>
        <DebounceInput
          css={[
            styles.input,
            !value ? styles.emptyInput : null,
            styles.searchInput,
          ]}
          debounceTimeout={300}
          id={`${idRoot}field`}
          inputMode="search"
          minLength={3}
          value={value}
          {...otherProps}
        />

        <img
          alt="search"
          css={styles.searchIcon}
          id={`${idRoot}icon`}
          src={magnify}
        />

        <span
          className="label"
          css={!value ? [styles.label, styles.placeholderLabel] : styles.label}
        >
          {props.label}
        </span>
      </label>
    </div>
  )
}

export default SearchInput
