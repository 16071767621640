import {css} from '@emotion/core'

export const selectItemHeader = css({
  position: 'sticky',
  top: 0,
  backgroundColor: 'white',
  zIndex: 1,
})

export const selectItemListHeader = css({
  display: 'flex !important',
  justifyContent: 'space-between',
})
