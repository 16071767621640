/** @jsx jsx */
import {jsx} from '@emotion/core'
import Button from '@app/components/Button'

import * as styles from './styles'
import {TransactionStatus, PageNames} from '@app/helpers/constants'

export interface ChangeStatusButtonProps {
  id?: string
  disabled?: boolean
  invoiceId?: number
  loading?: boolean
  status: TransactionStatus
  selected?: boolean
  onClick: (status: TransactionStatus) => void
}

const ChangeStatusButton = (props: ChangeStatusButtonProps): JSX.Element => (
  <Button
    color={props.selected ? styles.statusColor(props.status) : undefined}
    css={[
      styles.changeStatusButton,
      props.selected ? styles.changeStatusButtonSelected : null,
    ]}
    disabled={props.disabled}
    event={{
      description: `user taps on the ${props.status &&
        props.status.toLowerCase()} status from the list of statuses for ${
        props.invoiceId ? 'invoices' : 'quotes'
      }.`,
      activityLocation: props.invoiceId
        ? PageNames.createInvoice
        : PageNames.createQuote,
    }}
    id={props.id}
    loading={props.loading}
    secondary
    onClick={(): void => props.onClick(props.status)}
  >
    {props.status}
  </Button>
)

export default ChangeStatusButton
