/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Semantic} from '@mhd/components-library'

import ListItem from '@app/components/List/ListItem'
import {formatCurrency, itemQuantity, itemRate} from '@app/helpers'
import Calculator from '@app/helpers/calculator'

import {PriceListItem} from '@app/models/priceListItemModels'

const {Header} = Semantic

export interface TransactionItemProps {
  item: PriceListItem
  onClick: () => void
}

const TransactionItem = (props: TransactionItemProps): JSX.Element => {
  return (
    <ListItem>
      <div css={{display: 'flex'}}>
        <div css={{flexGrow: 1, overflowX: 'hidden'}}>
          {props.item.category && (
            <Header as="h5" css={{marginBottom: '0 !important'}} disabled>
              <Header.Subheader>{props.item.category}</Header.Subheader>
            </Header>
          )}
          <button
            className="link-button"
            css={{textAlign: 'left'}}
            onClick={props.onClick}
          >
            {props.item.name}
          </button>
        </div>
        <div>
          <Header
            as="h5"
            css={{marginBottom: '0 !important'}}
            textAlign="right"
            disabled
          >
            <Header.Subheader>
              {itemQuantity(props.item)} x{' '}
              {formatCurrency(itemRate(props.item))}
            </Header.Subheader>
          </Header>
          <Header as="h2" css={{marginTop: '0 !important', textAlign: 'right'}}>
            {formatCurrency(Calculator.itemTotal(props.item))}
          </Header>
        </div>
      </div>
    </ListItem>
  )
}

export default TransactionItem
