/** @jsx jsx */
import {jsx, css, keyframes} from '@emotion/core'
import spinner from '../assets/images/spin.svg'

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const spinnerStyle = css({
  animation: `${spin} 2s linear infinite;`,
  height: 20,
  width: 20,
})

const Loading = (props: any): JSX.Element => {
  return (
    <div
      className={`${props.inline ? 'inline-load' : ''} ${
        props.className || ''
      }`}
      data-testid="loading"
      style={{
        position: 'relative',
        textAlign: 'center',
        margin: '10px 0',
        ...props.style,
      }}
    >
      <img css={spinnerStyle} src={spinner} />
    </div>
  )
}

export default Loading
