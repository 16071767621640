/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useEffect, useContext, useState, ReactNode} from 'react'
import moment from 'moment'
import {useForm, FormProvider} from 'react-hook-form'

import {useMutation, useQuery} from '@apollo/client'
import {Semantic} from '@mhd/components-library'

import Button from '@app/components/Button'
import EmailModal from '@app/components/Email/EmailModal'
import PdfPreview from '@app/components/Email/PdfPreview'
import DataField from '@app/components/Form/DataField'
import Input from '@app/components/Form/Input'
import TextArea from '@app/components/Form/TextArea'
import Footer from '@app/components/Layout/Footer'
import NavBarLayout from '@app/components/Layout/NavBarLayout'
import ActionDropdown from '@app/components/Nav/ActionButton'
import CustomerInfo from '@app/components/TransactionEdit/CustomerInfo'
import Items from '@app/components/TransactionEdit/Items'
import StatusButton from '@app/components/TransactionEdit/Status/StatusButton'
import Totals from '@app/components/TransactionEdit/Totals'

import {AuthenticationContext} from '@app/screens/App'
import {NavContext} from '@app/context/NavContext'

import {TransactionStatus} from '@app/helpers/constants'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'
import {useSaveDialog, useDialog} from '@app/helpers/customHooks/dialog'
import useGraphOptions, {
  useMutationOptions,
} from '@app/helpers/customHooks/graphOptions'
import useModal from '@app/helpers/customHooks/useModal'
import useRootCustomer from '@app/helpers/customHooks/useRootCustomer'
import Calculator from '@app/helpers/calculator'

import {Invoice, InvoiceInput} from '@app/models/invoiceModels'
import {Customer} from '@app/models/customerModels'
import {PriceListItem} from '@app/models/priceListItemModels'
import {Quote} from '@app/models/quoteModels'
import {SalesTax} from '@app/models/salesTaxModels'

import {GET_INVOICES, SAVE_INVOICE} from '@app/graphql/invoice.graphql'
import {GET_SALESTAXES} from '@app/graphql/salesTax.graphql'

import ImportQuoteModal from './ImportQuoteModal'
import InvoiceStatusSlideout from './InvoiceStatusSlideout'

import * as styles from '@app/components/Nav/styles'

export interface InvoiceFormProps {
  createSuccess?: boolean
  leadId?: number
  customer?: Customer
  isCustomer?: boolean
  invoice: Invoice
  pageName: string
  children?: ReactNode
  hideImportQuote?: boolean
  customerId?: number
}

type InvoiceFormInputs = {
  poNumber: string
  dueDate: string
  issuedDate: string
  privateNotes: string
  customerNotes: string
}

const InvoiceForm = ({leadId, ...props}: InvoiceFormProps): JSX.Element => {
  const salesTaxList = useQuery(
    GET_SALESTAXES,
    useGraphOptions({
      offset: 0,
      limit: 500,
    }),
  )
  const authState = useContext(AuthenticationContext)
  const {goBack, goBackTo, toaster} = useContext(NavContext)

  const {track} = useAnalytics(props.pageName)

  const defaultValues = (i?: Invoice): InvoiceFormInputs => ({
    poNumber: i?.poNumber || '',
    dueDate: i?.dueDate ? moment(i.dueDate).format('YYYY-MM-DD') : '',
    issuedDate: i?.issuedDate
      ? moment(i.issuedDate).utc().format('YYYY-MM-DD')
      : moment(i?.createdDate).format('YYYY-MM-DD'),
    privateNotes: i?.privateNotes || '',
    customerNotes: i?.customerNotes || '',
  })

  const form = useForm<InvoiceFormInputs>({
    defaultValues: defaultValues(props.invoice),
  })

  const {isDirty} = form.formState

  const [invoice, setInvoice] = useState(
    props.invoice ? {...props.invoice} : undefined,
  )
  const [items, setItems] = useState(invoice?.items || [])
  const [discount, setDiscount] = useState(invoice?.discount)
  const [discountAmount, setDiscountAmount] = useState(
    invoice?.discountAmount || 0,
  )
  const [salesTax, setSalesTax] = useState(invoice?.tax)
  const [salesTaxes, setSalesTaxes] = useState<SalesTax[]>([])

  const [status, setStatus] = useState(
    (invoice?.status || 'Draft') as TransactionStatus,
  )
  const [unsaved, setUnsaved] = useState(invoice && !invoice.id)
  const [quoteId, setQuoteId] = useState<number | undefined>(invoice?.quoteId)

  const [customer, setCustomer] = useState(props.customer)

  const {rootCustomer} = useRootCustomer(
    props.customerId || customer?.customerId,
  )

  const idRoot = 'bmt__invoices_add-edit-invoice_invoice-form_'
  const watchIssuedDate = form.watch('issuedDate')

  useEffect(() => {
    setCustomer(props.customer)
  }, [props.customer])

  const [saveInvoice, {data: saveData, loading: saveLoading}] = useMutation(
    SAVE_INVOICE,
    {
      ...useMutationOptions({
        query: GET_INVOICES,
        queryName: 'getInvoices',
        listName: 'invoices',
        isNew: !invoice?.id,
        mutationName: 'saveInvoice',
        variables: {
          customerId: customer?.id,
        },
      }),
    },
  )

  useEffect(() => {
    form.setValue('customerNotes', props.invoice.customerNotes)
    form.setValue('privateNotes', props.invoice.privateNotes)

    setItems(props.invoice?.items)
    setQuoteId(props.invoice?.quoteId)
    setDiscount(props.invoice?.discount)
    setDiscountAmount(props.invoice?.discountAmount || 0)
    setStatus(props.invoice?.status as TransactionStatus)
    setSalesTax(props.invoice?.tax)
  }, [props.invoice.quoteId]) // eslint-disable-line react-hooks/exhaustive-deps

  const mergeSalesTaxes = (salesTaxToSet: SalesTax[]): SalesTax[] => {
    const curTax = props.invoice && props.invoice.tax
    if (curTax) {
      salesTaxToSet = salesTaxToSet.filter(
        x => x.id !== curTax.id || x.value !== curTax.value,
      )
      salesTaxToSet.unshift(curTax)
    }
    return salesTaxToSet
  }

  useEffect(() => {
    if (salesTaxList.loading) {
      return
    }

    const combinedSalesTaxes: SalesTax[] =
      (salesTaxList.data && salesTaxList.data.getSalesTaxes.salesTaxes) || []
    const filteredCombinedSalesTaxes = combinedSalesTaxes.filter(
      x => x.id !== '0',
    )

    if (props.invoice && props.invoice.tax && props.invoice.tax.id === '0') {
      // backwards compatible
      filteredCombinedSalesTaxes.unshift(props.invoice.tax)
    }

    setSalesTaxes(mergeSalesTaxes(filteredCombinedSalesTaxes))
  }, [salesTaxList.loading]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = async (
    formData: InvoiceFormInputs,
  ): Promise<Invoice | undefined> => {
    if (!customer) return undefined
    const input: InvoiceInput = {
      id: saveData?.saveInvoice?.id || invoice?.id,
      number: invoice?.number,
      createdDate: invoice?.createdDate || new Date().toLocaleString(),
      poNumber: formData.poNumber,
      dueDate: formData.dueDate
        ? moment.utc(moment(formData.dueDate)).format()
        : undefined,
      issuedDate: formData.issuedDate
        ? moment.utc(moment(formData.issuedDate)).format()
        : undefined,
      customerNotes: formData.customerNotes,
      privateNotes: formData.privateNotes,
      customer: {
        id: customer.id,
        leadId: customer.leadId || leadId,
        alId: customer.alId,
        customerId: props.customerId || customer?.customerId,
        firstName: customer.firstName,
        lastName: customer.lastName,
        phone: customer.phone,
        email: customer.email,
        address:
          (customer.address && {
            lineOne: customer.address.lineOne,
            lineTwo: customer.address.lineTwo,
            city: customer.address.city,
            state: customer.address.state,
            zip: customer.address.zip,
            country: customer.address.country,
          }) ||
          {},
      },
      isCustomer: props.isCustomer,
      tax: salesTax,
      items,
      quoteId,
      discount,
      discountAmount,
      status,
    }
    const variables = {input}
    if (authState.paymentId) {
      variables['paymentId'] = authState.paymentId
    }

    try {
      const result = await saveInvoice({variables})
      if (!result.errors) {
        form.reset(defaultValues(result.data.saveInvoice))
        setUnsaved(false)
        setInvoice(result.data.saveInvoice)
      } else {
        toaster.error('Error. Please Try Again.')
      }
      return !result.errors && result.data ? result.data.saveInvoice : undefined
    } catch (ex) {
      toaster.error('Error. Please Try Again.')
      return undefined
    }
  }

  const trashDialog = useDialog({
    title: 'Warning Unsaved Changes',
    content: 'Would you like to discard your changes?',
    primaryText: 'Discard',
    secondaryText: 'Cancel',
    destructive: true,
    onSubmit: (discard: boolean): void => {
      if (discard) {
        window.location.href = '/done'
      }
    },
  })

  const saveDialog = useSaveDialog({
    onSubmit: (save: boolean): Promise<boolean> => {
      if (save) {
        const result = form.handleSubmit(async data => {
          const result = await handleSave(data)
          if (
            authState.intent === 'invoices-new-customer' ||
            authState.intent === 'invoices-customers' ||
            authState.intent === 'invoice-payment-request'
          ) {
            window.location.href = '/done'
          } else if (result) {
            goBack(() => {
              toaster.success('Invoice Saved!')
            })
          }
        })()

        // eslint-disable-next-line no-undef
        return new Promise<boolean>(resolve => resolve(!!result))
      }

      if (
        authState.intent === 'invoices-new-customer' ||
        authState.intent === 'invoice-payment-request'
      ) {
        window.location.href = '/done'
      } else if (authState.intent === 'invoices-customers') {
        goBackTo('/customers')
      } else {
        goBack()
      }
      // eslint-disable-next-line no-undef
      return new Promise<boolean>(resolve => resolve(true))
    },
  })

  const handleBack = (): boolean => {
    if (authState.intent === 'invoices-payment') {
      window.location.href = '/done'
      return false
    }
    if (isDirty || unsaved) {
      saveDialog.show()
      return false
    } else if (
      authState.intent === 'invoices-new-customer' ||
      authState.intent === 'invoices-customers' ||
      authState.intent === 'invoice-payment-request'
    ) {
      window.location.href = '/done'
      return false
    } else {
      return true
    }
  }

  const handleUpdateItems = (
    updatedItems: PriceListItem[],
    type: 'added' | 'updated' | 'removed' | undefined = 'updated',
  ): void => {
    toaster.success(
      `Item ${
        type === 'added'
          ? 'Added!'
          : type === 'removed'
          ? 'Removed.'
          : 'Updated.'
      }`,
    )

    setUnsaved(true)
    setItems(updatedItems)

    const calc = new Calculator(updatedItems, salesTax, discount)

    setDiscountAmount(calc.discountAmount())
  }

  const handleUpdateSalesTaxes = (updatedSalesTaxes: SalesTax[]): void => {
    setUnsaved(true)
    setSalesTaxes(mergeSalesTaxes(updatedSalesTaxes))
  }

  const importQuoteModal = useModal({
    children: (
      <ImportQuoteModal
        customerId={customer?.id}
        leadId={customer?.leadId || leadId}
        previousQuoteId={quoteId}
        onClose={(): void => importQuoteModal.hide()}
        onQuoteSelected={(importedQuote: Quote): void => {
          setItems(importedQuote.items)
          setQuoteId(importedQuote.id)
          setDiscount(importedQuote.discount)
          form.setValue('privateNotes', importedQuote?.privateNotes || '', {
            shouldDirty: true,
            shouldValidate: true,
          })

          form.setValue('customerNotes', importedQuote?.customerNotes || '', {
            shouldDirty: true,
            shouldValidate: true,
          })

          const importSalesTax =
            importedQuote && importedQuote.tax
              ? importedQuote.tax
              : invoice
              ? invoice.tax
              : undefined

          setSalesTax(importSalesTax)

          let updatedSalesTaxes = salesTaxes

          if (importSalesTax) {
            if (importSalesTax.id === '0') {
              updatedSalesTaxes = updatedSalesTaxes.filter(x => x.id !== '0')
            }

            updatedSalesTaxes.unshift(importSalesTax)
          }

          setSalesTaxes(updatedSalesTaxes)
          setUnsaved(true)
          importQuoteModal.hide()
          toaster.success(
            quoteId ? 'Imported Quote changed.' : 'Quote Imported!',
          )
        }}
      />
    ),
  })

  const emailInvoiceModal = useModal({
    children: (
      <EmailModal
        customer={customer}
        pdfName={saveData ? saveData.saveInvoice.pdfName : ''}
        transaction={invoice}
        type="Invoice"
        onClose={(): void => emailInvoiceModal.hide()}
      />
    ),
  })

  const handleEmailInvoiceModal = async (
    data: InvoiceFormInputs,
  ): Promise<void> => {
    const savedInvoice = await handleSave(data)
    if (savedInvoice) {
      track('Item Clicked', {
        description: 'user clicks the email invoice button',
        activityLocation: props.pageName,
        invoiceId: savedInvoice.id,
      })
      emailInvoiceModal.show()
    }
  }

  const pdfPreviewModal = useModal({
    children: (
      <PdfPreview
        invoiceId={invoice?.id}
        type="Invoice"
        onClose={(): void => pdfPreviewModal.hide()}
      />
    ),
  })

  const handlePdfPreviewModal = async (): Promise<void> => {
    form.handleSubmit(async data => {
      const success = await handleSave(data)
      if (success) {
        pdfPreviewModal.show()
      }
    })()
  }

  const handleNext = async (): Promise<void> => {
    form.handleSubmit(async data => {
      const result = await handleSave(data)
      if (authState.intent === 'invoice-payment-request') {
        window.location.href = `/next?invoiceId=${result?.id}`
      } else {
        window.location.href = '/done'
      }
    })()
  }

  const handleSaveButton = async (): Promise<void> => {
    form.handleSubmit(async data => {
      const result = await handleSave(data)
      if (result) {
        toaster.success('Invoice Saved!')
      }
    })()
  }

  return (
    <NavBarLayout
      actionButton={
        authState.intent === 'invoice-payment-request' ? (
          <Semantic.Icon
            alt="Delete"
            css={[styles.navIcon, styles.navTrash]}
            data-testid="nav-delete"
            id="bmt__nav_navbar_delete-button"
            name="trash alternate outline"
            link
            onClick={(): void => {
              trashDialog.show()
            }}
          />
        ) : (
          <ActionDropdown>
            <Semantic.Dropdown.Menu>
              <Semantic.Dropdown.Item
                disabled={saveLoading}
                id={`${idRoot}save-invoice-item`}
                text="Save Invoice"
                onClick={handleSaveButton}
              />
              {!props.hideImportQuote && (
                <Semantic.Dropdown.Item
                  disabled={saveLoading}
                  text={quoteId ? 'Change Quote' : 'Import Quote'}
                  onClick={(): void => importQuoteModal.show()}
                />
              )}
            </Semantic.Dropdown.Menu>
          </ActionDropdown>
        )
      }
      footer={
        <Footer>
          <Button
            event={{
              description: 'user clicks preview button',
              activityLocation: props.pageName,
            }}
            fluid={false}
            id={`${idRoot}preview-button`}
            secondary
            onClick={handlePdfPreviewModal}
          >
            Preview
          </Button>

          {authState.intent === 'invoice-payment-request' ? (
            <Button
              id={`${idRoot}next-button`}
              loading={!invoice}
              onClick={handleNext}
            >
              Next
            </Button>
          ) : (
            <Button
              id={`${idRoot}email-invoice-button`}
              loading={!invoice}
              onClick={form.handleSubmit(handleEmailInvoiceModal)}
            >
              Email Invoice
            </Button>
          )}
        </Footer>
      }
      title={invoice && invoice.id ? 'Edit Invoice' : 'Create an Invoice'}
      onBack={handleBack}
    >
      {props.children}

      {toaster.render()}
      <div className="section">
        <StatusButton
          invoiceId={invoice?.id}
          slideoutComponent={InvoiceStatusSlideout}
          status={status}
          onStatusChanged={(newStatus: TransactionStatus): void =>
            setStatus(newStatus)
          }
        />
        <CustomerInfo
          contact={customer}
          rootCustomer={rootCustomer}
          onUpdate={(updatedCustomer: Customer): void => {
            setCustomer(updatedCustomer)
            setUnsaved(true)
            toaster.success('Customer updated for this Invoice.')
          }}
        />
      </div>

      <FormProvider {...form}>
        <div className="section grid cols-2">
          <DataField
            label="Invoice #"
            value={invoice ? invoice.number : undefined}
          />
          <Input
            id={`${idRoot}issued-date-field`}
            label="Issued Date"
            name="issuedDate"
            type="date"
          />

          <Input
            id={`${idRoot}po-number-field`}
            label="PO Number"
            maxLength={31}
            name="poNumber"
            rules={{
              maxLength: 31,
            }}
          />

          <Input
            id={`${idRoot}due-date-field`}
            label="Due Date"
            name="dueDate"
            rules={{
              min: {
                value: watchIssuedDate,
                message: 'Due Date must be on or after Issued Date',
              },
            }}
            type="date"
          />
        </div>

        <div className="section">
          <Items
            items={items}
            pageName={props.pageName}
            onUpdateItems={handleUpdateItems}
          />
        </div>

        <Totals
          amountPaid={invoice?.balanceDue?.paid || 0}
          discount={discount}
          items={items}
          salesTax={salesTax}
          salesTaxes={salesTaxes}
          showBalanceDue
          onDiscountUpdated={(a, d): void => {
            setDiscountAmount(a)
            setDiscount(d)
            setUnsaved(true)
          }}
          onSalesTaxesUpdated={handleUpdateSalesTaxes}
          onSalesTaxUpdated={(tax): void => {
            setSalesTax(tax)
            setUnsaved(true)
          }}
        />

        <div className="section">
          <TextArea
            id={`${idRoot}customer-notes-field`}
            label="Customer Notes"
            name="customerNotes"
          />

          <TextArea
            assistiveText="Private notes will not be shared with the customer."
            id={`${idRoot}private-notes-field`}
            label="Private Notes"
            name="privateNotes"
          />
        </div>
      </FormProvider>

      {importQuoteModal.render}
      {saveDialog.render}
      {trashDialog.render}
      {emailInvoiceModal.render}
      {pdfPreviewModal.render}
    </NavBarLayout>
  )
}

export default InvoiceForm
