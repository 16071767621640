/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useContext} from 'react'
import {useMutation, useQuery} from '@apollo/client'
import {FormProvider, useForm} from 'react-hook-form'

import Button from '@app/components/Button'
import DataField from '@app/components/Form/DataField'
import Input from '@app/components/Form/Input'
import MultiselectInput from '@app/components/Form/MulitiselectInput'
import TextArea from '@app/components/Form/TextArea'
import NavBarLayout from '@app/components/Layout/NavBarLayout'
import CloseIcon from '@app/components/Layout/CloseIcon'

import {PageNames} from '@app/helpers/constants'
import {AuthenticationContext} from '@app/screens/App'

import useGraphOptions, {
  useMutationOptions,
} from '@app/helpers/customHooks/graphOptions'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'
import useModal from '@app/helpers/customHooks/useModal'

import {SendEmailInput} from '@app/models/emailModels'
import {Customer} from '@app/models/customerModels'
import {Transaction} from '@app/models/transactionModels'

import EmailConfirmation from './EmailConfirmation'

import {GET_PORTAL} from '@app/graphql/portal.graphql'
import {SEND_QUOTE} from '@app/graphql/quote.graphql'
import {SEND_INVOICE} from '@app/graphql/invoice.graphql'

export interface EmailModalProps {
  type: string
  customer?: Customer
  transaction?: Transaction
  pdfName: string
  onClose: () => void
}

export type EmailFormInputs = {
  to: string[]
  cc?: string[]
  subject: string
  bodyMessage?: string
}

const EmailModal = ({
  type,
  customer,
  transaction,
  ...props
}: EmailModalProps): JSX.Element => {
  const pageName =
    type === 'Quote' ? PageNames.emailQuote : PageNames.emailInvoice
  const {track} = useAnalytics(pageName)

  const [send, {loading: saveLoading}] = useMutation(
    type === 'Quote' ? SEND_QUOTE : SEND_INVOICE,
    useMutationOptions(),
  )

  const defaultValues = (): EmailFormInputs => ({
    to: customer?.email ? [customer?.email] : [],
    cc: [],
    subject: '',
    bodyMessage: '',
  })

  const form = useForm<EmailFormInputs>({
    defaultValues: defaultValues(),
  })

  const authState = useContext(AuthenticationContext)
  const portal = useQuery(GET_PORTAL, {
    ...useGraphOptions({leadId: authState.alLeadId}, {}, true),
    onCompleted: data => {
      const phone =
        data.getAlPortal?.primaryContactInfo?.primaryPhoneNumber?.formatted
      const companyName = data.getAlPortal?.name
      form.setValue('cc', [data.getAlPortal?.primaryContactInfo?.email])
      form.setValue(
        'subject',
        `Your ${type} from ${companyName} is ready for review`,
      )
      form.setValue(
        'bodyMessage',
        `Hi ${customer?.firstName},${
          type == 'Quote'
            ? `\n\nPlease see the attached quote for the work we discussed. If you have any additional questions, please reach out to us at ${phone}.\n\nThank you for considering us at ${companyName}!`
            : `\n\nThank you so much for choosing ${companyName}! Please see the attached PDF for the invoice details. \n\nIf you are satisfied with the work we completed, please write a review and let us know!`
        }`,
      )
    },
  })

  const emailConfirmationModal = useModal({
    children: (
      <EmailConfirmation
        customer={customer}
        transactionId={transaction?.id}
        type={type}
      />
    ),
  })

  const handleSubmit = async (formData: EmailFormInputs): Promise<boolean> => {
    let success = false

    const input: SendEmailInput = {
      [type === 'Quote' ? 'quoteId' : 'invoiceId']: transaction?.id || 0,
      portalName: portal.data.getAlPortal.name,
      emailRequest: {
        toEmails: Array.isArray(formData.to)
          ? formData.to.join(',')
          : formData.to,
        ccEmails: Array.isArray(formData.cc)
          ? formData.cc.join(',')
          : formData.cc,
        subject: formData.subject,
        message: formData.bodyMessage,
      },
    }
    const response = await send({variables: {alUrl: authState.alUrl, input}})

    if (type === 'Quote') {
      success = response.data.sendQuote.sent
    } else {
      success = response.data.sendInvoice.sent
    }

    if (success) {
      track(`${type} Sent`, {
        leadId: customer?.leadId,
        [`${type?.toLowerCase()}Id`]: transaction?.id,
      })
      emailConfirmationModal.show()
    }
    return success
  }

  const handleClose = (): void => {
    props.onClose()
  }

  return (
    <NavBarLayout
      actionButton={
        <div onClick={handleClose}>
          <CloseIcon />
        </div>
      }
      title={`Send ${type}`}
      hideBack
    >
      <FormProvider {...form}>
        <MultiselectInput
          label="To"
          name="to"
          noResultsMessage="Enter email address..."
          required
        />

        <MultiselectInput
          label="CC"
          loading={portal.loading}
          name="cc"
          noResultsMessage="Enter email address..."
        />

        <Input
          label="Subject"
          name="subject"
          rules={{
            required: 'Subject is required',
            maxLength: 78,
          }}
        />

        <TextArea
          label="Body Message"
          maxLength={3500}
          name="bodyMessage"
          placeholder="Enter a message..."
        />

        <DataField label="Attachment" value={props.pdfName} />

        <div css={{marginTop: 25}}>
          <Button
            disabled={saveLoading}
            event={{
              description: `user clicks the send ${type?.toLowerCase()} button`,
              activityLocation: pageName,
            }}
            onClick={form.handleSubmit(handleSubmit)}
          >{`Send ${type}`}</Button>
        </div>
      </FormProvider>

      {emailConfirmationModal.render}
    </NavBarLayout>
  )
}

export default EmailModal
