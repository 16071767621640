import ToasterMessage from '@app/components/ToasterMessage/ToasterMessage'
import React, {useState} from 'react'

export interface ToasterMessageProps {
  content: string
}

export interface UseToasterInterface {
  success: (message: string) => void
  error: (message: string) => void
  render: () => JSX.Element | null
  hide: () => void
}

export default (): UseToasterInterface => {
  const [visible, setVisible] = useState(false)
  const [type, setType] = useState<'success' | 'error'>('success')
  const [content, setContent] = useState('')

  const show = (type: 'success' | 'error', content: string): void => {
    setType(type)
    setVisible(true)
    setContent(content)
    setTimeout(() => {
      setVisible(false)
    }, 5000)
  }
  const success = (message: string): void => {
    show('success', message)
  }

  const error = (message: string): void => {
    show('error', message)
  }

  const hide = (): void => setVisible(false)

  const render = (): JSX.Element | null =>
    visible ? <ToasterMessage content={content} type={type} /> : null

  return {
    success,
    error,
    render,
    hide,
  }
}
