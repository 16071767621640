import gql from 'graphql-tag'

export const GET_FEATURES = gql`
  query GetFeatures($portalId: String!) {
    getFeatures(portalId: $portalId) {
      name
      enabled
    }
  }
`
