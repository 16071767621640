import gql from 'graphql-tag'
import {CUSTOMER, TRANSACTION_ITEM} from './transaction.graphql'

const INVOICE = gql`
  ${TRANSACTION_ITEM}
  ${CUSTOMER}
  fragment Invoice on InvoiceResponse {
    id
    number
    createdDate
    dueDate
    issuedDate
    poNumber
    customerNotes
    privateNotes
    pdfName
    status
    tax {
      id
      name
      value
    }
    quoteId
    discountAmount
    nonDiscountedTotal
    total
    discount {
      amount
      type
      postTax
    }
    items {
      ...TransactionItem
    }
    customer {
      ...Customer
    }
    balanceDue {
      total
      remaining
      paid
    }
  }
`

export const GET_INVOICE = gql`
  ${INVOICE}
  query GetInvoice($portalId: ID!, $invoiceId: ID!) {
    getInvoice(portalId: $portalId, invoiceId: $invoiceId) {
      ...Invoice
    }
  }
`

export const GET_INVOICES = gql`
  query GetInvoices(
    $portalId: ID!
    $offset: Int!
    $limit: Int!
    $leadId: ID
    $customerId: ID
  ) {
    getInvoices(
      portalId: $portalId
      offset: $offset
      limit: $limit
      leadId: $leadId
      customerId: $customerId
    ) {
      invoices {
        id
        number
        createdDate
        updatedDate
        total
        sentDate
        status
      }
      offset
      limit
      totalRows
    }
  }
`

export const GET_NEXT_INVOICE_NUMBER = gql`
  query GetNextInvoiceNumber($portalId: ID!) {
    getNextInvoiceNumber(portalId: $portalId) {
      number
    }
  }
`

export const PREVIEW_INVOICE = gql`
  mutation PreviewInvoice(
    $portalId: ID!
    $alUrl: String!
    $input: SendPreviewInput!
  ) {
    previewInvoice(portalId: $portalId, alUrl: $alUrl, input: $input) {
      html
    }
  }
`

export const SAVE_INVOICE = gql`
  ${INVOICE}
  mutation SaveInvoice($portalId: ID!, $input: InvoiceInput!, $paymentId: ID) {
    saveInvoice(portalId: $portalId, input: $input, paymentId: $paymentId) {
      ...Invoice
    }
  }
`

export const SEND_INVOICE = gql`
  mutation SendInvoice(
    $portalId: ID!
    $alUrl: String!
    $input: SendPreviewInput!
  ) {
    sendInvoice(portalId: $portalId, alUrl: $alUrl, input: $input) {
      sent
    }
  }
`

export const CHANGE_STATUS = gql`
  mutation ChangeStatus($portalId: ID!, $invoiceId: ID!, $newStatus: String!) {
    changeStatus(
      portalId: $portalId
      invoiceId: $invoiceId
      newStatus: $newStatus
    ) {
      __typename
      ... on InvoiceResponse {
        id
        status
      }
      ... on ErrorResponse {
        code
        details
      }
    }
  }
`
