/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useEffect} from 'react'
import {useQuery, useLazyQuery} from '@apollo/client'
import {Semantic} from '@mhd/components-library'

import NavBarLayout from '@app/components/Layout/NavBarLayout'
import TransactionListItem from '@app/components/TransactionList/TransactionListItem'
import CloseIcon from '@app/components/Layout/CloseIcon'

import {PageNames} from '@app/helpers/constants'
import {useDialog} from '@app/helpers/customHooks/dialog'
import useGraphOptions from '@app/helpers/customHooks/graphOptions'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'

import {MinimalQuote, Quote} from '@app/models/quoteModels'
import {GET_QUOTE, GET_QUOTES} from '@app/graphql/quote.graphql'

export interface ImportQuoteModalProps {
  customerId?: number
  leadId?: number
  previousQuoteId?: number
  onQuoteSelected: (item: Quote) => void
  onClose: () => void
}

const ImportQuoteModal = (props: ImportQuoteModalProps): JSX.Element => {
  const {track} = useAnalytics(PageNames.importQuote, {leadId: props.leadId})

  // Quote list
  const quotes = useQuery(
    GET_QUOTES,
    useGraphOptions(
      {
        offset: 0,
        limit: 500,
        customerId: props.customerId,
        leadId: props.customerId ? undefined : props.leadId,
      },
      {fetchPolicy: 'cache-and-network'},
    ),
  )

  // Previous Quote
  const [loadPreviousQuote, previousQuote] = useLazyQuery(
    GET_QUOTE,
    useGraphOptions({quoteId: Number(props.previousQuoteId)}),
  )

  useEffect((): void => {
    if (props.previousQuoteId) {
      loadPreviousQuote()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Select a Quote
  const [loadQuote, quote] = useLazyQuery(GET_QUOTE, {
    fetchPolicy: 'cache-and-network',
  })

  const confirmDialog = useDialog({
    title: 'Warning',
    content: `Are you sure you want to change the imported quote from Quote #${previousQuote.data?.getQuote.number} to Quote #${quote.data?.getQuote.number}?`,
    primaryText: 'Save',
    secondaryText: 'Discard',
    onSubmit: (confirm: boolean): void => {
      if (confirm) {
        props.onQuoteSelected(quote.data.getQuote)
      } else {
        props.onClose()
      }
    },
  })

  useEffect((): void => {
    if (quote.data) {
      if (!props.previousQuoteId) {
        props.onQuoteSelected(quote.data.getQuote)
      } else {
        confirmDialog.show()
      }
    }
  }, [quote.data]) // eslint-disable-line react-hooks/exhaustive-deps

  const graphOptions = useGraphOptions({})
  const handleQuoteSelected = (quote: MinimalQuote): void => {
    track('Item Clicked', {
      description: 'user selects a quote',
      activityLocation: PageNames.importQuote,
    })
    loadQuote({
      context: graphOptions.context,
      variables: {
        ...graphOptions.variables,
        quoteId: Number(quote.id),
      },
    })
  }

  return (
    <NavBarLayout
      actionButton={
        <div onClick={(): void => props.onClose()}>
          <CloseIcon />
        </div>
      }
      subtitle={props.previousQuoteId ? 'Select a Quote' : undefined}
      title={props.previousQuoteId ? 'Change Imported Quote' : 'Select a Quote'}
      hideBack
    >
      <Semantic.Header as="h4">Existing Quotes</Semantic.Header>
      {quotes.data &&
        quotes.data.getQuotes.quotes.map((x: MinimalQuote) => (
          <TransactionListItem
            key={x.id}
            selected={x.id === props.previousQuoteId}
            transaction={x}
            type="Quote"
            {...(x.id !== props.previousQuoteId && {
              onClick: (): void => handleQuoteSelected(x),
            })}
          />
        ))}

      {confirmDialog.render}
    </NavBarLayout>
  )
}

export default ImportQuoteModal
