/** @jsx jsx */

import {jsx} from '@emotion/core'
import {useEffect} from 'react'
import {useMutation} from '@apollo/client'
import {FormProvider, useForm} from 'react-hook-form'

import {useMutationOptions} from '@app/helpers/customHooks/graphOptions'

import Button from '@app/components/Button'
import Input from '@app/components/Form/Input'
import SlideoutLayout from '@app/components/Layout/Slideout'

import {SalesTax} from '@app/models/salesTaxModels'
import {SAVE_SALESTAX} from '@app/graphql/salesTax.graphql'
import {addServerErrors, VALIDATE_DECIMAL_NUMBER} from '@app/helpers'

export interface DiscountModalProps {
  salesTax?: SalesTax
  onClose: () => void
  onSave: (salesTax: SalesTax) => void
}

type FormInputs = {
  name: string
  value: string
}

const SalesTaxModal = (props: DiscountModalProps): JSX.Element => {
  const idRoot = 'bmt__transaction-edit_sales-tax-modal_'

  const defaultValues = (): FormInputs => ({
    name: props.salesTax?.name || '',
    value: props.salesTax ? (props.salesTax.value * 100).toFixed(2) : '',
  })
  const form = useForm({
    defaultValues: defaultValues(),
  })
  const [saveSalesTax] = useMutation(SAVE_SALESTAX, useMutationOptions())

  useEffect(() => {
    form.reset(defaultValues())
  }, [props.salesTax]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (formData: FormInputs): Promise<void> => {
    const input = {
      id: props.salesTax?.id,
      name: props.salesTax?.name || formData.name,
      value: Number((Number(formData.value) / 100).toFixed(4)),
    }
    try {
      const result = await saveSalesTax({variables: {input}})

      if (!result.errors) {
        const newSalesTaxObj = result.data.saveSalesTax
        const newSalesTax: SalesTax = {
          id: newSalesTaxObj.id,
          name: newSalesTaxObj.name,
          value: newSalesTaxObj.value,
        }
        props.onSave(newSalesTax)
      }
    } catch (ex) {
      addServerErrors(
        {name: ['A Tax Rate with this name already exists.']},
        form.setError,
      )
    }
  }

  const handleClose = (): void => {
    props.onClose()
  }

  return (
    <SlideoutLayout
      title={props.salesTax ? 'Edit Tax' : 'Add New Tax'}
      onClose={handleClose}
    >
      <FormProvider {...form}>
        <Input
          disabled={!!props.salesTax}
          label="Name"
          maxLength={80}
          name="name"
          rules={{
            maxLength: 80,
            required: 'Name is required',
            pattern: {
              value: /^[^”“":]+$/,
              message:
                'The following characters can’t be used in Name: double quote (“) and colon (:).',
            },
          }}
        />
        <Input
          inputMode="decimal"
          label="Rate (%)"
          maxLength={100}
          name="value"
          rules={{
            maxLength: 100,
            required: 'Value is required',
            pattern: VALIDATE_DECIMAL_NUMBER,
            min: 0,
            max: 100,
          }}
          suffix="%"
          type="number"
        />
      </FormProvider>

      <div className="section">
        <Button
          id={`${idRoot}${
            props.salesTax ? 'save-changes' : 'add-apply-new-tax'
          }-button`}
          onClick={form.handleSubmit(handleSubmit)}
        >
          {props.salesTax ? 'Save Changes' : 'Add & Apply New Tax'}
        </Button>
      </div>
    </SlideoutLayout>
  )
}

export default SalesTaxModal
