/** @jsx jsx */
import {jsx} from '@emotion/core'
import {ReactNode} from 'react'
import {Semantic} from '@mhd/components-library'

import * as styles from './style'

export interface ListItemProps {
  onClick?: () => void
  children: ReactNode
  showCaret?: boolean
  alternating?: boolean
  selected?: boolean
}

const ListItem = (props: ListItemProps): JSX.Element => {
  const {
    onClick,
    children,
    showCaret,
    alternating,
    selected,
    ...otherProps
  } = props
  return (
    <div
      css={[
        styles.item,
        onClick || selected || alternating === false
          ? styles.clickableItem
          : styles.alternatingItem,
      ]}
      onClick={onClick}
      {...otherProps}
    >
      {children}
      {showCaret && <Semantic.Icon css={styles.caret} name="angle right" />}
    </div>
  )
}

export default ListItem
