/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useContext} from 'react'
import {Redirect, useHistory} from 'react-router'

import {AuthenticationContext} from '../App'
import ErrorPage from '@app/components/ErrorPage'

const Home = (): JSX.Element => {
  const authState = useContext(AuthenticationContext)
  const history = useHistory()
  let pathname = ''
  let state = {}

  switch (authState.intent) {
    case 'quotes':
      if (authState.alLeadId) {
        pathname = '/quotes'
      } else {
        pathname = '/leads'
      }
      break
    case 'invoices':
      if (authState.alLeadId) {
        pathname = '/invoices'
      } else {
        pathname = '/leads'
      }
      break
    case 'quotes-new-customer':
    case 'invoices-new-customer':
      pathname = '/customer/new'
      break
    case 'quotes-customers':
    case 'invoices-customers':
    case 'my-customers':
      pathname = '/customers'
      break
    case 'invoices-payment':
      pathname = '/invoices/new'
      break
    case 'customer-payments':
      pathname = '/customer'
      state = {tab: 'payments'}
      break
    case 'invoice-payment-request':
      if (authState.invoiceId) {
        pathname = `/invoices/edit/${authState.invoiceId}`
      } else {
        pathname = '/invoices/new'
      }
      break
    default:
      return <ErrorPage />
  }

  return <Redirect to={{pathname, search: history.location.search, state}} />
}

export default Home
