import {css} from '@emotion/core'
import {bgGreyLight} from '@app/styles/colors'

export const customerInfo = css({
  fontSize: '1.4rem',
})

export const customerAddress = css({
  display: 'flex',
  flexWrap: 'wrap',
})

export const editAddressSvg = css({
  width: 24,
  height: 24,
  marginRight: 9,
})

export const editAddressText = css({
  verticalAlign: 'super',
  fontWeight: 500,
})

export const totals = css({
  backgroundColor: bgGreyLight,
  padding: '30px 20px 20px 20px',
})

export const totalsGrid = css({
  display: 'grid',
  gridTemplateColumns: 'auto 33%',
  gridTemplateRows: 'auto auto auto',
  '& *': {
    marginTop: '0 !important',
  },
})

export const taxRateCss = css({
  marginTop: 8,
  marginBottom: 8,
  position: 'relative',
  padding: '0.75rem 0 0 0',
})

export const label = css({
  position: 'absolute',
  top: '-0.3rem',
  left: '1rem',
  fontSize: '1.4rem',
  padding: '0 5px',
  backgroundColor: 'white !important',
  zIndex: 11,
  background: 'linear-gradient(180deg, transparent 47%, white 47%)',
  fontWeight: 500,
})
