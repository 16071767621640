import {css} from '@emotion/core'
import {containerPadding} from '@app/styles/variables'
import {bgGrey, bgGreyLight} from '@app/styles/colors'

export const modalTransitionDuration = 300

export const modalBackground = css({
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: 99,
})

export const defaultModalStyle = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  background: 'white',
  zIndex: 100,
  transition: `all ${modalTransitionDuration}ms linear`,
  overflowY: 'auto',
  overflowX: 'hidden',
}

export const scrollableModal = {
  position: 'absolute',
  bottom: 'unset',
  minHeight: '100%',
  overflowY: 'unset',
  overflowX: 'unset',
}

export const transitionModalStyles = {
  entering: {top: '100%'},
  entered: {top: 0},
  exiting: {top: '100%'},
  exited: {top: '100%'},
}

export const transitionSlideout = {
  entering: {top: '100%', bottom: '-100%'},
  entered: {top: 0, bottom: 0},
  exiting: {top: '100%', bottom: '-100%'},
  exited: {top: '100%', bottom: '-100%'},
}

export const slideout = {
  background: 'transparent',
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'column',
}

export const slideoutContent = {
  background: 'white',
  borderTopRightRadius: 20,
  borderTopLeftRadius: 20,
  maxHeight: 650,
}

export const footer = css({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  zIndex: 20,
  backgroundColor: 'white',
  padding: `1.5rem ${containerPadding}`,
  borderTop: `1px solid ${bgGrey}`,
})

export const navBarLayout = css({
  padding: containerPadding,
})

export const layoutWithFooter = css({
  paddingBottom: '7.5rem',
})

export const contentGreyBg = css({
  backgroundColor: bgGreyLight,
  position: 'absolute',
  top: '10rem',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: -1,
})
