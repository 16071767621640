/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useQuery, useMutation} from '@apollo/client'

import {PREVIEW_QUOTE} from '@app/graphql/quote.graphql'
import {PREVIEW_INVOICE} from '@app/graphql/invoice.graphql'
import NavBarLayout from '@app/components/Layout/NavBarLayout'
import useGraphOptions from '@app/helpers/customHooks/graphOptions'
import CloseIcon from '@app/components/Layout/CloseIcon'

import {textLight} from '@app/styles/colors'
import {borderRadius} from '@app/styles/variables'
import {useEffect, useContext} from 'react'
import {AuthenticationContext} from '@app/screens/App'
import {GET_PORTAL} from '@app/graphql/portal.graphql'
import Loading from '@app/components/Loading'

export interface PdfPreviewProps {
  type: string
  quoteId?: number
  invoiceId?: number
  onClose: () => void
}

const PdfPreview = (props: PdfPreviewProps): JSX.Element => {
  const authState = useContext(AuthenticationContext)
  const portal = useQuery(
    GET_PORTAL,
    useGraphOptions({leadId: authState.alLeadId}, {}, true),
  )
  const [
    loadQuotePreview,
    {data: quoteData, loading: quoteLoading},
  ] = useMutation(PREVIEW_QUOTE)
  const [
    loadInvoicePreview,
    {data: invoiceData, loading: invoiceLoading},
  ] = useMutation(PREVIEW_INVOICE)
  const graphOptions = useGraphOptions({
    alUrl: authState.alUrl,
  })

  useEffect(() => {
    if (portal.loading) return

    if (props.type === 'Quote') {
      const variables = {
        ...graphOptions.variables,
        input: {
          quoteId: props.quoteId,
          portalName: portal.data.name,
        },
      }

      loadQuotePreview({variables})
    } else {
      const variables = {
        ...graphOptions.variables,
        input: {
          invoiceId: props.invoiceId,
          portalName: portal.data.name,
        },
      }

      loadInvoicePreview({variables})
    }
  }, [portal.loading]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = (): void => {
    props.onClose()
  }

  return (
    <NavBarLayout
      actionButton={
        <div onClick={handleClose}>
          <CloseIcon />
        </div>
      }
      title={`Preview ${props.type}`}
      hideBack
    >
      {(props.type === 'Quote' &&
        (quoteLoading || !quoteData || !quoteData.previewQuote)) ||
      (props.type !== 'Quote' &&
        (invoiceLoading || !invoiceData || !invoiceData.previewInvoice)) ? (
        <Loading al inline />
      ) : (
        <div
          css={{
            padding: '1.2rem 1.6rem',
            background: 'white',
            border: `1px solid ${textLight} !important`,
            borderRadius: borderRadius,
            width: '100%',
            overflow: 'auto',
          }}
          dangerouslySetInnerHTML={{
            __html:
              props.type === 'Quote'
                ? quoteData.previewQuote.html || ''
                : invoiceData.previewInvoice.html || '',
          }}
        />
      )}
    </NavBarLayout>
  )
}
export default PdfPreview
