/** @jsx jsx */
import {jsx} from '@emotion/core'
import {ReactNode} from 'react'

import * as styles from './styles'

export interface FooterProps {
  children: ReactNode
}

const Footer = (props: FooterProps): JSX.Element => {
  return (
    <div css={styles.footer} id="footer">
      {props.children}
    </div>
  )
}

export default Footer
