import React from 'react'
import {UseToasterInterface} from '../helpers/customHooks/useToaster'
export interface NavContextFields {
  goBack: (callback?: () => void) => void
  goBackTo: (path: string) => void
  toaster: UseToasterInterface
}

export const NavContext = React.createContext<NavContextFields>({
  goBack: () => {},
  goBackTo: () => {},
  toaster: {
    success: () => {},
    error: () => {},
    render: () => null,
    hide: () => {},
  },
})
