import gql from 'graphql-tag'

export const GET_SALESTAX = gql`
  query GetSalesTax($portalId: ID!, $salesTaxId: ID!) {
    getSalesTax(portalId: $portalId, salesTaxId: $salesTaxId) {
      id
      name
      value
    }
  }
`

export const GET_SALESTAXES = gql`
  query GetSalesTaxes($portalId: ID!, $offset: Int!, $limit: Int!) {
    getSalesTaxes(portalId: $portalId, offset: $offset, limit: $limit) {
      salesTaxes {
        id
        name
        value
      }
      offset
      limit
      totalRows
    }
  }
`

export const SAVE_SALESTAX = gql`
  mutation SaveSalesTax($portalId: ID!, $input: SalesTaxInput!) {
    saveSalesTax(portalId: $portalId, input: $input) {
      id
      name
      value
    }
  }
`
