/** @jsx jsx */
import {jsx} from '@emotion/core'
import {TransitionGroup, Transition} from 'react-transition-group'

import * as styles from './styles'

export interface ModalProps {
  visible?: boolean
  children: any
  scrollable?: boolean
  slideout?: boolean
}

const Modal = (props: ModalProps): JSX.Element => {
  return (
    <TransitionGroup>
      <Transition
        key={props.visible ? '1' : '0'}
        timeout={styles.modalTransitionDuration}
      >
        {(state): JSX.Element | null => {
          if (!props.visible) return null
          return (
            <div>
              <div css={styles.modalBackground} />
              <div
                style={{
                  ...styles.defaultModalStyle,
                  ...(props.scrollable ? styles.scrollableModal : {}),
                  ...(props.slideout ? styles.slideout : {}),
                  ...(props.slideout
                    ? styles.transitionSlideout[state]
                    : styles.transitionModalStyles[state]),
                }}
              >
                <div>{props.children}</div>
              </div>
            </div>
          )
        }}
      </Transition>
    </TransitionGroup>
  )
}

export default Modal
