/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useState, useEffect} from 'react'
import {TransitionGroup, CSSTransition} from 'react-transition-group'

import {PriceListItem} from '@app/models/priceListItemModels'

import NewPriceListItemModal from './NewPriceListItemModal'
import SelectItem from './SelectItem'

export interface SelectItemModalProps {
  onAddItem: (item: PriceListItem) => void
  onClose: () => void
  onScrollableChange: (value: boolean) => void
}

const SelectItemModal = (props: SelectItemModalProps): JSX.Element => {
  const [isItemSelected, setIsItemSelected] = useState(false)
  const [itemId, setItemId] = useState<number | undefined>(undefined)

  useEffect((): void => {
    window.scrollTo(0, 0)
  }, [])

  const handleSelection = (item: PriceListItem): void => {
    setItemId(item.id)
    setIsItemSelected(true)
    props.onScrollableChange(false)
  }

  const handleNewItem = (): void => {
    setItemId(undefined)
    setIsItemSelected(true)
    props.onScrollableChange(false)
  }

  const handleAddItem = (item: PriceListItem): void => {
    props.onAddItem(item)
  }

  const [goingBack, setGoingBack] = useState(false)
  return (
    <TransitionGroup>
      <CSSTransition
        key={`${isItemSelected}`}
        classNames="slide"
        timeout={300}
        onExited={(): void => setGoingBack(false)}
      >
        <div className={goingBack ? 'back' : undefined}>
          {isItemSelected ? (
            <NewPriceListItemModal
              priceListItemId={itemId}
              onAddItem={handleAddItem}
              onBack={async (): Promise<void> => {
                await props.onScrollableChange(true)
                await setGoingBack(true)
                setIsItemSelected(false)
              }}
              onClose={props.onClose}
            />
          ) : (
            <SelectItem
              onClose={(): void => props.onClose()}
              onCreateItem={handleNewItem}
              onSelection={handleSelection}
            />
          )}
        </div>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default SelectItemModal
