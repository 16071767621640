/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {useMutation} from '@apollo/client'

import {AuthenticationContext} from '../App'

import {Customer} from '@app/models/customerModels'
import {SAVE_CUSTOMER} from '@app/graphql/customer.graphql'
import {useMutationOptions} from '@app/helpers/customHooks/graphOptions'
import {PageNames} from '@app/helpers/constants'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'

import CustomerForm from './CustomerForm'
import {NavContext} from '@app/context/NavContext'

const AddCustomer = (): JSX.Element => {
  const history = useHistory()
  const {intent} = useContext(AuthenticationContext)
  const {toaster} = useContext(NavContext)

  const pageName = PageNames.addCustomer
  const {track} = useAnalytics(pageName)

  const [saveCustomer, {loading}] = useMutation(
    SAVE_CUSTOMER,
    useMutationOptions(),
  )

  const handleSave = async (customerFields: Customer): Promise<void> => {
    const result = await saveCustomer({variables: {input: customerFields}})
    if (!result.errors) {
      const savedCustomer = result.data.saveCustomer
      track('Customer Created', {
        customerId: savedCustomer?.id,
      })

      let pathname = '/invoices/new'
      toaster.success('Customer Added')
      const state = {}

      if (intent.includes('my-customers')) {
        pathname = `/customer/${savedCustomer?.customerId}/contact/${savedCustomer?.id}`
        state['tab'] = 'quotes'
      } else if (intent.includes('quote')) {
        pathname = '/quotes/new'
        state['customer'] = savedCustomer
      } else if (intent.includes('invoice')) {
        state['customer'] = savedCustomer
      }
      history.push({
        pathname,
        state,
        search: history.location.search,
      })
    }
  }

  return <CustomerForm loading={loading} isRootCustomer onSave={handleSave} />
}

export default AddCustomer
