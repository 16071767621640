/** @jsx jsx */
import {jsx} from '@emotion/core'
import React, {useState, SyntheticEvent, useEffect} from 'react'
import {Semantic} from '@mhd/components-library'
import {useFormContext, useController} from 'react-hook-form'

import {isValidEmail} from '../../helpers'
import {FormFieldProps} from '.'
import * as styles from './styles'

export interface MultiselectInputProps {
  name: string
  noResultsMessage?: string
  loading?: boolean
  required?: boolean
}

export type MultiselectComponent = React.Component<
  Semantic.DropdownProps & MultiselectInputProps & FormFieldProps,
  any,
  any
>

const validateEmails = (
  values: Semantic.DropdownItemProps[],
): string | undefined => {
  const invalid = values.filter(
    (x: Semantic.DropdownItemProps) => !!x && !isValidEmail(String(x)),
  )
  return invalid.length > 0 ? 'Invalid email' : undefined
}

const MultiselectInput = (
  props: MultiselectInputProps & FormFieldProps,
): JSX.Element => {
  const [options, setOptions] = useState<Semantic.DropdownItemProps[]>([])

  const form = useFormContext()
  const {field} = useController({
    control: form.control,
    name: props.name,
    rules: {
      validate: {
        validEmails: validateEmails,
        required: (values: string[]): string | undefined =>
          props.required && values.length === 0
            ? 'Field is required'
            : undefined,
      },
    },
  })
  const {onChange, onBlur, value, name} = field

  const errorMsg = form.errors[props.name]?.message

  useEffect(() => {
    if (value?.length > 0 && options.length === 0) {
      setOptions([
        {
          key: value[0],
          text: value[0],
          value: value[0],
        },
      ])
    }
  }, [value])

  const handleAddOption = (
    e: SyntheticEvent<HTMLElement, Event>,
    {value}: Semantic.DropdownProps,
  ): void => {
    if (value) {
      const stringValue = value as string
      setOptions([
        {key: value.toString(), text: value.toString(), value: stringValue},
        ...options,
      ])
    } else {
      setOptions([])
    }
  }

  return (
    <div css={[styles.fieldWrapper, errorMsg ? styles.fieldError : null]}>
      <Semantic.Dropdown
        css={styles.multiselect}
        loading={props.loading}
        name={name}
        noResultsMessage={props.noResultsMessage}
        options={options}
        value={value}
        allowAdditions
        multiple
        search
        selection
        onAddItem={handleAddOption}
        onBlur={onBlur}
        onChange={(e, data): void => {
          onChange(data.value)
        }}
      />
      <label css={[styles.label, styles.multiselectLabel]}>{props.label}</label>

      {errorMsg && (
        <p
          css={[
            styles.inputAssistiveText,
            errorMsg ? styles.inputErrorMsg : null,
          ]}
        >
          {errorMsg}
        </p>
      )}
    </div>
  )
}

export default MultiselectInput
