/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Semantic} from '@mhd/components-library'
import cancel from '../../assets/images/cancel.svg'
import circle from '../../assets/images/circle.svg'

import * as styles from './styles'
export interface ToasterMessageProps {
  type: 'error' | 'success'
  content: string
}

const ToasterMessage = (props: ToasterMessageProps): JSX.Element => {
  return (
    <Semantic.Message
      color={styles.messageColor(props.type)}
      css={[styles.message]}
    >
      <p>
        <img
          alt=""
          css={styles.toasterSvg}
          src={props.type === 'error' ? cancel : circle}
        />
        <span css={styles.editAddressText}>{props.content}</span>
      </p>
    </Semantic.Message>
  )
}

export default ToasterMessage
