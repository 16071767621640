/** @jsx jsx */
import {jsx} from '@emotion/core'

import TransactionListItem from '@app/components/TransactionList/TransactionListItem'
import {TransactionItemComponentProps} from '@app/components/TransactionList'

import {PageNames} from '@app/helpers/constants'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'
import useHistory from '@app/helpers/customHooks/useHistory'

const InvoiceListItem = ({
  transaction,
}: TransactionItemComponentProps): JSX.Element => {
  const {track} = useAnalytics()
  const history = useHistory()

  return (
    <TransactionListItem
      transaction={transaction}
      type="Invoice"
      onClick={(): void => {
        track('Item Clicked', {
          description: 'an existing invoice is opened from the customer page',
          activityLocation: PageNames.customerInvoices,
        })

        history.push(`/invoices/edit/${transaction.id}`)
        track('Item Clicked', {
          description: 'user selects a card',
          activityLocation: PageNames.invoiceList,
        })
      }}
    />
  )
}

export default InvoiceListItem
