/** @jsx jsx */
import {jsx} from '@emotion/core'

import TransactionListItem from '@app/components/TransactionList/TransactionListItem'
import {TransactionItemComponentProps} from '@app/components/TransactionList'

import {PageNames} from '@app/helpers/constants'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'
import useHistory from '@app/helpers/customHooks/useHistory'

const QuoteListItem = ({
  transaction,
  ...props
}: TransactionItemComponentProps): JSX.Element => {
  const {track} = useAnalytics()
  const history = useHistory()
  return (
    <TransactionListItem
      transaction={transaction}
      type="Quote"
      onClick={(): void => {
        track('Item Clicked', {
          description: 'an existing quote is opened from the customer page',
          activityLocation: PageNames.customerQuotes,
        })

        history.push(`/quotes/edit/${transaction.id}`)
        track('Item Clicked', {
          description: 'user selects a card',
          activityLocation: PageNames.quoteList,
        })
      }}
      {...props}
    />
  )
}

export default QuoteListItem
