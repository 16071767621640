import {Global} from '@emotion/core'
import {useLazyQuery} from '@apollo/client'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import React, {useEffect, ReactNode, useState} from 'react'
import {TransitionGroup, CSSTransition} from 'react-transition-group'

import {NavContext} from '@app/context/NavContext'
import {UISettingsContext} from '@app/context/UISettingsContext'

import {AUTHENTICATE} from '@app/graphql/auth.graphql'
import {GET_FEATURES} from '@app/graphql/feature.graphql'
import {GraphAuthenticatedResponse} from '@app/models/authModels'
import {globals, overrides, animations} from '@app/styles/styles'
import {FeatureResponse} from '@app/models/featureModels'
import FeatureContext from '@app/context/FeatureContext'
import useToaster from '@app/helpers/customHooks/useToaster'

import Home from '@app/screens/Home'
import QuoteList from '@app/screens/Quotes/QuoteList'
import NewQuote from '@app/screens/Quotes/AddEditQuote/NewQuote'
import EditQuote from '@app/screens/Quotes/AddEditQuote/EditQuote'
import InvoiceList from '@app/screens/Invoices/InvoiceList'
import NewInvoice from '@app/screens/Invoices/AddEditInvoice/NewInvoice'
import EditInvoice from '@app/screens/Invoices/AddEditInvoice/EditInvoice'
import LeadList from '@app/screens/LeadList'
import AddCustomer from '@app/screens/Customers/AddCustomer'
import SelectCustomer from '@app/screens/Customers/SelectCustomer'
import ErrorPage from '@app/components/ErrorPage'
import {UiSettingsInterface} from '@app/models/settingsModel'
import CustomerDetail from '../Customers/CustomerDetail'
import Login from '../Login'
import Loading from '@app/components/Loading'

export interface RouteParams {
  customerId?: string
  contactId?: string
}

export type IntentType =
  | 'quotes'
  | 'invoices'
  | 'quotes-new-customer'
  | 'invoices-new-customer'
  | 'quotes-customers'
  | 'invoices-customers'
  | 'invoices-payment'
  | 'my-customers'
  | 'customer-payments'
  | 'invoice-payment-request'
  | ''

export interface AppProps {
  alSpid: number
  alToken: string
  intent: IntentType
  alLeadId: number
  alUrl: string
  token?: string
  portalId?: string
  uiSettings?: UiSettingsInterface
  customerId?: number
  invoiceId?: number
  paymentId?: number
}

export const AuthenticationContext = React.createContext<AppProps>({
  alSpid: 0,
  alToken: '',
  intent: '',
  alLeadId: 0,
  alUrl: '',
})

const App = (props: AppProps): JSX.Element => {
  const [authState, setAuthState] = useState<AppProps>({...props})
  const [noAuth, setNoAuth] = useState(false)
  const toaster = useToaster()

  const [authenticate, authRequest] = useLazyQuery<
    GraphAuthenticatedResponse,
    {alSpid: string; alToken: string; alUrl: string}
  >(AUTHENTICATE, {
    variables: {
      alSpid: props.alSpid.toString(),
      alToken: props.alToken,
      alUrl: props.alUrl,
    },
    onCompleted: data => {
      setAuthState({
        ...authState,
        portalId: JSON.parse(atob(data.authenticate.token.split('.')[1]))[
          'mhd:portal_id'
        ],
      })
    },
  })

  useEffect((): void => {
    if (props.alSpid && props.alToken) {
      authenticate()
    } else {
      setNoAuth(true)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const [goingBack, setGoingBack] = useState(false)

  useEffect((): void => {
    if (
      window.analytics &&
      window.analytics.load &&
      props.uiSettings &&
      props.uiSettings.segmentKey
    ) {
      window.analytics.load(props.uiSettings.segmentKey)
    }
  }, [props.uiSettings, props.uiSettings?.segmentKey]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect((): void => {
    if (authState.alSpid) {
      window.analytics.identify(authState.alSpid)
    }
  }, [authState.alSpid])

  // Feature Flags
  const [loadFeatures, featuresRequest] = useLazyQuery(GET_FEATURES)

  useEffect(() => {
    if (authState.portalId) {
      loadFeatures({variables: {portalId: authState.portalId}})
    }
  }, [authState.portalId])

  const features = featuresRequest.data?.getFeatures
    ? featuresRequest.data.getFeatures.reduce(
        (acc: FeatureResponse, cur: FeatureResponse) => ({
          ...acc,
          [cur.name]: cur.enabled,
        }),
        {},
      )
    : {}

  const backDone = (): void => setGoingBack(false)

  if (noAuth && props.alUrl) {
    return (
      <>
        <Login
          alLeadId={props.alLeadId}
          alUrl={props.alUrl}
          intent={props.intent}
        />
      </>
    )
  } else if (
    !authState.portalId ||
    !featuresRequest.called ||
    featuresRequest.loading
  ) {
    return (
      <>
        <Loading al inline />
      </>
    )
  } else if (authRequest.error) {
    return (
      <>
        <ErrorPage />
      </>
    )
  } else {
    return (
      <AuthenticationContext.Provider value={{...authState}}>
        <UISettingsContext.Provider value={props.uiSettings}>
          <FeatureContext.Provider value={features}>
            <BrowserRouter>
              <Global styles={[globals, overrides, animations]} />
              <Route
                render={({location, history}): ReactNode => (
                  <NavContext.Provider
                    value={{
                      toaster,
                      goBack: async (callback?: () => void): Promise<void> => {
                        await setGoingBack(true)
                        history.goBack()
                        if (callback) {
                          setTimeout(() => {
                            callback()
                          }, 300)
                        } else {
                          toaster.hide()
                        }
                      },
                      goBackTo: async (path: string): Promise<void> => {
                        await setGoingBack(true)
                        history.push({
                          pathname: path,
                          search: history.location.search,
                        })
                        toaster.hide()
                      },
                    }}
                  >
                    <TransitionGroup>
                      <CSSTransition
                        key={location.pathname}
                        classNames="slide"
                        timeout={300}
                        onExited={backDone}
                      >
                        <div className={goingBack ? 'back' : undefined}>
                          <Switch location={location}>
                            <Route component={Home} path="/" exact />
                            <Route component={QuoteList} path="/quotes" exact />
                            <Route
                              component={NewQuote}
                              path="/quotes/new"
                              exact
                            />
                            <Route
                              component={NewQuote}
                              path="/customer/:customerId/:contact/contactId/quotes/new"
                              exact
                            />
                            <Route
                              component={EditQuote}
                              path="/quotes/edit/:id"
                              exact
                            />
                            <Route
                              component={InvoiceList}
                              path="/invoices"
                              exact
                            />
                            <Route
                              component={NewInvoice}
                              path="/invoices/new"
                              exact
                            />
                            <Route
                              component={NewInvoice}
                              path="/customer/:customerId/:contact/contactId/invoices/new"
                              exact
                            />
                            <Route
                              component={EditInvoice}
                              path="/invoices/edit/:id"
                              exact
                            />
                            <Route component={LeadList} path="/leads" exact />
                            <Route
                              component={AddCustomer}
                              path="/customer/new"
                              exact
                            />
                            <Route
                              component={SelectCustomer}
                              path="/customers"
                              exact
                            />
                            <Route
                              component={CustomerDetail}
                              path="/customer/:customerId/contact/:contactId"
                              exact
                            />
                            <Route
                              component={CustomerDetail}
                              path="/customer/:customerId"
                              exact
                            />
                          </Switch>
                        </div>
                      </CSSTransition>
                    </TransitionGroup>
                  </NavContext.Provider>
                )}
              />
            </BrowserRouter>
          </FeatureContext.Provider>
        </UISettingsContext.Provider>
      </AuthenticationContext.Provider>
    )
  }
}

export default App
