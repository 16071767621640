/** @jsx jsx */
import {jsx} from '@emotion/core'
import {ReactNode} from 'react'
import {Semantic} from '@mhd/components-library'

import * as styles from './styles'
import * as navstyles from '../Nav/styles'

export interface SlideoutLayoutProps {
  closeButtonId?: string
  title: string
  children: ReactNode
  onClose: () => void
}

const SlideoutLayout = (props: SlideoutLayoutProps): JSX.Element => (
  <div css={styles.slideoutContent}>
    <div css={styles.navBarLayout}>
      <div css={[navstyles.navbar, {gridTemplateRows: '30px', height: 30}]}>
        <div />

        <Semantic.Header as="h4" css={navstyles.navHeader} textAlign="center">
          {props.title}
        </Semantic.Header>

        <div css={{textAlign: 'right'}}>
          <Semantic.Button
            css={{
              borderRadius: '12px !important',
              padding: '5px 10px !important',
            }}
            id={props.closeButtonId}
            size="mini"
            onClick={props.onClose}
          >
            Close
          </Semantic.Button>
        </div>
      </div>

      <hr className="fullWidth" />

      <div>{props.children}</div>
    </div>
  </div>
)

export default SlideoutLayout
