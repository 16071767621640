export const primary = '#048540'
export const primaryLight = '#0da863'
export const primaryDark = '#027a3a'

export const secondary = '#007db8'
export const secondaryLight = '#0a99c4'
export const secondaryDark = '#0067ab'

export const text = '#2a2a2a'
export const textMedium = '#666666'
export const textLight = '#929292'

export const bgGrey = '#cccccc'
export const bgGreyMedium = '#eaeaea'
export const bgGreyLight = '#f5f5f5'

export const danger = '#D41100'
export const dangerLight = '#e82e2e'
export const dangerDark = '#c20010'

export const darkOrange = '#CC4D1C'

export const denim = '#005F99'
export const denimDark = '#003359'
export const heart = '#FC5647'

export const textDanger = '#D41100'
