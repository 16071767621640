/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useContext} from 'react'
import {useQuery} from '@apollo/client'

import NavBarLayout from '@app/components/Layout/NavBarLayout'
import TransactionList from '@app/components/TransactionList'

import {GET_LEAD} from '@app/graphql/lead.graphql'
import {NavContext} from '@app/context/NavContext'
import {GET_QUOTES} from '@app/graphql/quote.graphql'
import {AuthenticationContext} from '@app/screens/App'
import useGraphOptions from '@app/helpers/customHooks/graphOptions'

import QuoteListItem from './QuoteListItem'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'
import useHistory from '@app/helpers/customHooks/useHistory'
import {
  PageNames,
  DefaultPaginationOptions,
  PAGE_SIZE,
} from '@app/helpers/constants'

const Quotes = (): JSX.Element => {
  const history = useHistory()
  const authState = useContext(AuthenticationContext)
  const {toaster} = useContext(NavContext)

  const {track} = useAnalytics(PageNames.leadQuoteList, {
    leadId: authState.alLeadId,
  })

  const lead = useQuery(
    GET_LEAD,
    useGraphOptions(
      {leadId: authState.alLeadId},
      {fetchPolicy: 'cache-and-network'},
      true,
    ),
  )
  const leadQuotes = useQuery(GET_QUOTES, {
    ...useGraphOptions(
      {
        ...DefaultPaginationOptions,
        leadId: authState.alLeadId,
      },
      {
        fetchPolicy: 'cache-and-network',
      },
    ),
  })

  const handleNewQuote = (): void => {
    track('Item Clicked', {
      description: 'user clicks the add quote button',
      activityLocation: PageNames.leadQuoteList,
    })
    history.push('/quotes/new')
  }

  const leadName = lead.data ? `${lead.data.getLead.fullName}` : ''

  return (
    <NavBarLayout
      subtitle={leadName}
      title="Quotes"
      onBack={(): boolean => {
        window.location.href = '/done'
        return false
      }}
    >
      {toaster.render()}

      <TransactionList
        buttonText="Create a Quote"
        emptyText={`No existing Quotes for this lead`}
        fetchMore={(): void => {
          leadQuotes.fetchMore({
            variables: {
              offset: leadQuotes.data?.getQuotes?.quotes.length || PAGE_SIZE,
            },
            updateQuery: (prev, {fetchMoreResult}) => {
              if (!fetchMoreResult) return prev
              return {
                ...prev,
                getQuotes: {
                  ...fetchMoreResult.getQuotes,
                  quotes: [
                    ...prev.getQuotes.quotes,
                    ...fetchMoreResult.getQuotes.quotes,
                  ],
                },
              }
            },
          })
        }}
        ItemComponent={QuoteListItem}
        title="Quote"
        totalRows={leadQuotes.data?.getQuotes?.totalRows}
        transactions={leadQuotes.data?.getQuotes?.quotes}
        onNew={handleNewQuote}
      />
    </NavBarLayout>
  )
}

export default Quotes
