/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useContext} from 'react'
import {useQuery} from '@apollo/client'

import NavBarLayout from '@app/components/Layout/NavBarLayout'
import TransactionList from '@app/components/TransactionList'

import {NavContext} from '@app/context/NavContext'
import {PageNames, PAGE_SIZE} from '@app/helpers/constants'
import useGraphOptions from '@app/helpers/customHooks/graphOptions'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'
import useHistory from '@app/helpers/customHooks/useHistory'

import {GET_LEAD} from '@app/graphql/lead.graphql'
import {GET_INVOICES} from '@app/graphql/invoice.graphql'
import {AuthenticationContext} from '@app/screens/App'

import InvoiceListItem from './InvoiceListItem'

const Invoices = (): JSX.Element => {
  const history = useHistory()
  const authState = useContext(AuthenticationContext)
  const {toaster} = useContext(NavContext)

  const {track} = useAnalytics(PageNames.leadInvoiceList, {
    leadId: authState.alLeadId,
  })

  const lead = useQuery(GET_LEAD, {
    ...useGraphOptions(
      {leadId: authState.alLeadId},
      {fetchPolicy: 'cache-and-network'},
      true,
    ),
  })
  const leadInvoices = useQuery(
    GET_INVOICES,
    useGraphOptions(
      {leadId: authState.alLeadId, offset: 0, limit: 500},
      {fetchPolicy: 'cache-and-network'},
    ), // todo : update for pagination
  )

  const handleNewInvoice = (): void => {
    track('Item Clicked', {
      description: 'user clicks the add invoice button',
      activityLocation: PageNames.leadInvoiceList,
    })
    history.push('/invoices/new')
  }

  const leadName = lead.data
    ? `${lead.data.getLead.firstName} ${lead.data.getLead.lastName}`
    : ''

  return (
    <NavBarLayout
      subtitle={leadName}
      title="Invoices"
      onBack={(): boolean => {
        window.location.href = '/done'
        return false
      }}
    >
      {toaster.render()}

      <TransactionList
        buttonText="Create an Invoice"
        emptyText={`No existing invoices for this lead`}
        fetchMore={(): void => {
          leadInvoices.fetchMore({
            variables: {
              offset:
                leadInvoices.data?.getInvoices?.invoices.length || PAGE_SIZE,
            },
            updateQuery: (prev, {fetchMoreResult}) => {
              if (!fetchMoreResult) return prev
              return {
                ...prev,
                getInvoices: {
                  ...fetchMoreResult.getInvoices,
                  invoices: [
                    ...prev.getInvoices.invoices,
                    ...fetchMoreResult.getInvoices.invoices,
                  ],
                },
              }
            },
          })
        }}
        ItemComponent={InvoiceListItem}
        title="Invoice"
        totalRows={leadInvoices.data?.getInvoices?.totalRows}
        transactions={leadInvoices.data?.getInvoices?.invoices}
        onNew={handleNewInvoice}
      />
    </NavBarLayout>
  )
}

export default Invoices
