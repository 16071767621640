import {css} from '@emotion/core'
import {heart} from '@app/styles/colors'

export const transactionListItem = css({
  marginTop: '0 !important',
  display: 'grid',
  gridColumnGap: 10,
  gridRowGap: 15,
  gridTemplateColumns: '50% 50%',
  gridTemplateRows: '16px 42px',

  '& h6': {
    marginBottom: '-0.3rem !important',
  },
  '& h3': {
    marginTop: '0 !important',
  },
})

export const withStatus = css({
  gridTemplateRows: '16px 42px 14px',
})

export const row = css({
  gridColumnStart: 1,
  gridColumnEnd: 3,
})

export const rowGrid = css({
  gridColumnStart: 1,
  gridColumnEnd: 3,
  display: 'grid',
  gridTemplateColumns: '25% 25% 25% 25%',
})

export const statusCol = css({
  gridColumnStart: 1,
  gridColumnEnd: 4,
})

export const selectCol = css({
  gridColumnStart: 4,
  gridColumnEnd: 5,
  textAlign: 'right',
  marginTop: '0 !important',
})

export const selectText = css({
  color: heart,
  fontWeight: 'normal',
  fontSize: '16px',
})
export const selectIcon = css({
  color: `${heart} !important`,
})
export const status = css({
  fontStyle: 'italic',
})
