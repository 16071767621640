import React, {useContext, useEffect} from 'react'
import {useQuery, useLazyQuery} from '@apollo/client'
import {useParams} from 'react-router'

import {PageNames} from '@app/helpers/constants'
import useGraphOptions from '@app/helpers/customHooks/graphOptions'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'
import {AuthenticationContext} from '@app/screens/App'

import {GET_INVOICE} from '@app/graphql/invoice.graphql'
import {GET_CUSTOMER} from '@app/graphql/customer.graphql'

import InvoiceForm from './InvoiceForm'
import Loading from '@app/components/Loading'

const EditInvoice = (): JSX.Element => {
  const authState = useContext(AuthenticationContext)
  const {id: invoiceId} = useParams()

  const invoice = useQuery(
    GET_INVOICE,
    useGraphOptions(
      {invoiceId: Number(invoiceId)},
      {fetchPolicy: 'cache-and-network'},
    ),
  )

  const customer = invoice.data?.getInvoice.customer
  const leadId = customer?.alId || authState.alLeadId
  const graphOptions = useGraphOptions({})
  const [getContact, {data: contactData}] = useLazyQuery(GET_CUSTOMER, {
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (customer || leadId) {
      getContact({
        context: graphOptions.context,
        variables: {
          ...graphOptions.variables,
          customerId: customer?.id,
        },
      })
    }
  }, [customer?.id])

  const customerId =
    contactData && contactData.getCustomer && contactData.getCustomer.customerId
  useAnalytics(PageNames.editInvoice, {leadId})

  if (!invoice.data?.getInvoice) return <Loading al />

  return (
    <InvoiceForm
      customer={customer}
      customerId={customerId}
      invoice={invoice.data?.getInvoice}
      leadId={leadId}
      pageName={PageNames.editInvoice}
    />
  )
}

export default EditInvoice
