/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Semantic} from '@mhd/components-library'

import * as styles from './styles'

const {Header} = Semantic

export interface DataFieldProps {
  value: string | number | undefined | null
  label: string
}

const DataField = (props: DataFieldProps): JSX.Element => (
  <div css={[styles.fieldWrapper, {marginBottom: 0}]}>
    <Header as="h5" subheader={props.label} />
    <Header as="h4" css={styles.fieldValue}>
      {props.value}
    </Header>
  </div>
)

export default DataField
