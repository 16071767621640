import gql from 'graphql-tag'

export const GET_PAYMENTS = gql`
  query GetPayments(
    $portalId: ID!
    $offset: Int!
    $limit: Int!
    $leadId: ID
    $customerId: ID
  ) {
    getPayments(
      portalId: $portalId
      offset: $offset
      limit: $limit
      leadId: $leadId
      customerId: $customerId
    ) {
      payments {
        id
        paymentDate
        createdDate
        amount
        source {
          invoiceNumber
        }
      }
      totalRows
    }
  }
`
