/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useContext} from 'react'
import {useForm, FormProvider} from 'react-hook-form'

import {Semantic} from '@mhd/components-library'

import Button from '@app/components/Button'
import AddressInput, {
  AddressFormInputs,
} from '@app/components/Form/AddressInput'
import Input from '@app/components/Form/Input'
import TextArea from '@app/components/Form/TextArea'
import NavBarLayout from '@app/components/Layout/NavBarLayout'
import CloseIcon from '@app/components/Layout/CloseIcon'

import {formatPhoneNumber} from '@app/helpers'
import {useSaveDialog} from '@app/helpers/customHooks/dialog'
import {PageNames} from '@app/helpers/constants'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'

import {Address, Customer} from '@app/models/customerModels'

import {AuthenticationContext} from '../App'

export interface CustomerFormInputs extends AddressFormInputs {
  displayName: string
  firstName: string
  lastName: string
  phone?: string
  email: string
  notes?: string
  address: Address
}

export interface CustomerFormProps {
  contact?: Customer
  rootCustomer?: Customer
  onSave: (customer: Customer) => void
  onClose?: () => void
  autoSave?: boolean
  isModal?: boolean
  loading?: boolean
  isRootCustomer?: boolean
  isCustomerDetails?: boolean
}

const CustomerForm = (props: CustomerFormProps): JSX.Element => {
  const isNewCustomer = !props.contact && !props.rootCustomer
  const enableNotes =
    (!props.isModal || props.isCustomerDetails) &&
    (props.isRootCustomer || !isNewCustomer)

  const authState = useContext(AuthenticationContext)
  const pageName = !props.contact ? PageNames.addCustomer : ''
  const {track} = useAnalytics(pageName)
  const idRoot = 'bmt__customers_customer-form_'

  const type = 'Customer'

  const form = useForm<CustomerFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      displayName: props.rootCustomer?.displayName,
      firstName: props.contact?.firstName || '',
      lastName: props.contact?.lastName || '',
      email: props.contact?.email || '',
      phone: formatPhoneNumber(props.contact?.phone || ''),
      notes: props.contact?.notes || '',
      address: {
        lineOne: props.contact?.address.lineOne || '',
        lineTwo: props.contact?.address.lineTwo || '',
        city: props.contact?.address.city || '',
        state: props.contact?.address.state || '',
        zip: props.contact?.address.zip || '',
      },
    },
  })

  const {isDirty} = form.formState

  const handleSave = async (formData: CustomerFormInputs): Promise<void> => {
    track('Item Clicked', {
      description: 'a user clicks the add customer button',
      activityLocation: PageNames.addCustomer,
    })

    const input: Customer = {
      id: props.contact?.id,
      alId: props.contact?.alId,
      leadId: props.contact?.leadId,
      ...formData,
      phone: formData.phone ? formatPhoneNumber(formData.phone) : '',
      email: formData.email || props.contact?.email || '',
    }

    if (enableNotes) {
      input.notes = formData.notes
    } else {
      input.saveNotes = true
    }

    if (!props.isCustomerDetails && !isNewCustomer) {
      input.firstName = props.contact?.firstName || ''
      input.lastName = props.contact?.lastName || ''
    }

    props.onSave(input)
  }

  const saveDialog = useSaveDialog({
    onSubmit: (save: boolean): boolean => {
      if (save) {
        form.handleSubmit(handleSave)()
      } else if (props.onClose) {
        props.onClose()
      }
      return true
    },
  })

  const handleClose = (): void => {
    if (isDirty) {
      saveDialog.show()
      return
    }
    if (props.onClose) {
      props.onClose()
    }
  }

  return (
    <NavBarLayout
      actionButton={
        props.contact || props.isModal ? (
          <CloseIcon onClick={handleClose} />
        ) : (
          <Semantic.Icon
            color="blue"
            name="check"
            onClick={form.handleSubmit(handleSave)}
          />
        )
      }
      hideBack={!!props.contact || props.isModal}
      title={`${props.contact ? 'Edit' : 'Add a'} ${type}`}
      onBack={(): boolean => {
        if (authState.intent.includes('new-customer')) {
          window.location.href = '/done'
          return false
        }
        return true
      }}
    >
      <FormProvider {...form}>
        <Input
          disabled={!props.isCustomerDetails && !isNewCustomer}
          id={`${idRoot}first-name-field`}
          label="First Name"
          name="firstName"
          rules={{
            required: 'First Name is required',
          }}
        />

        <Input
          disabled={!props.isCustomerDetails && !isNewCustomer}
          id={`${idRoot}last-name-field`}
          label="Last Name"
          name="lastName"
          rules={{
            required: 'Last Name is required',
          }}
        />

        <Input
          disabled={!isNewCustomer}
          id={`${idRoot}email-field`}
          inputMode="email"
          label="Email"
          name="email"
          rules={{
            required: 'Email is required',
            pattern: {
              value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              message: 'Valid email is required',
            },
          }}
        />

        <div className="section">
          <p>Optional Information</p>

          <Input
            id={`${idRoot}phone-field`}
            label="Phone Number"
            maxLength={14}
            name="phone"
            rules={{
              pattern: {
                value: /^[0-9-() ]*$/,
                message: 'Only numbers, dashes, and parentheses are supported',
              },
            }}
            type="tel"
          />

          <AddressInput />
        </div>

        {enableNotes && (
          <div className="section">
            <TextArea
              id={`${idRoot}notes-field`}
              label="Notes"
              maxLength={500}
              name="notes"
            />
          </div>
        )}
      </FormProvider>

      <Button
        id={`${idRoot}${props.contact ? 'save' : 'add-customer'}-button`}
        loading={props.loading}
        onClick={form.handleSubmit(handleSave)}
      >
        {props.contact ? 'Save' : `Add ${type}`}
      </Button>

      {saveDialog.render}
    </NavBarLayout>
  )
}

export default CustomerForm
