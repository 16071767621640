/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useEffect} from 'react'
import {useForm, FormProvider} from 'react-hook-form'

import Button from '@app/components/Button'
import Dropdown from '@app/components/Form/Dropdown'
import Input from '@app/components/Form/Input'
import SlideoutLayout from '@app/components/Layout/Slideout'

import {useDialog} from '@app/helpers/customHooks/dialog'

import {Discount} from '@app/models/transactionModels'
import {dangerDark} from '@app/styles/colors'
import {PERCENT_TYPE, DOLLAR_TYPE} from '@app/helpers/constants'
import {VALIDATE_DECIMAL_NUMBER} from '@app/helpers'

export interface DiscountModalProps {
  maxDiscount: number
  total: number
  discount?: Discount
  onClose: () => void
  onSave: (discount: Discount) => void
  onRemove: () => void
}

type FormInputs = {
  amount: number
  type: string
  postTax: boolean
}

const DiscountModal = (props: DiscountModalProps): JSX.Element => {
  const idRoot = 'bmt__transaction-edit_discount-modal_'

  const defaultValues = (): FormInputs => ({
    amount: props.discount?.amount || 0,
    type: props.discount?.type || PERCENT_TYPE,
    postTax: props.discount ? props.discount.postTax : true,
  })

  const form = useForm({
    defaultValues: defaultValues(),
  })

  useEffect(() => {
    form.reset(defaultValues())
  }, [props.discount]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (formData: FormInputs): Promise<void> => {
    props.onSave({...formData, amount: Number(formData.amount)})
  }

  const handleClose = (): void => {
    props.onClose()
  }

  const removeDialog = useDialog({
    destructive: true,
    title: 'Remove Discount?',
    content: 'Are you sure you want to remove this discount?',
    primaryText: 'Remove',
    secondaryText: 'Cancel',
    onSubmit: (remove: boolean): void => {
      if (remove) {
        props.onRemove()
      } else {
        props.onClose()
      }
    },
  })

  return (
    <SlideoutLayout
      closeButtonId={`${idRoot}close-button`}
      title={props.discount ? 'Edit Discount' : 'Add Discount'}
      onClose={handleClose}
    >
      <FormProvider {...form}>
        <Input
          id={`${idRoot}amount-field`}
          inputMode="decimal"
          label="Amount"
          maxLength={100}
          name="amount"
          prefix={form.watch('type') === PERCENT_TYPE ? '-' : '-$'}
          rules={{
            required: 'Amount is required',
            pattern: VALIDATE_DECIMAL_NUMBER,
            min: 0,
            max:
              form.watch('type') === PERCENT_TYPE
                ? 100
                : form.getValues('postTax')
                ? props.maxDiscount
                : props.total,
          }}
          suffix={form.watch('type') === PERCENT_TYPE ? '%' : undefined}
          type="number"
        />

        <Dropdown
          id={`${idRoot}type-field`}
          label="Type"
          name="type"
          options={[
            {
              key: true,
              text: 'Percent',
              value: PERCENT_TYPE,
            },
            {
              key: false,
              text: 'Dollar',
              value: DOLLAR_TYPE,
            },
          ]}
        />

        <Dropdown
          id={`${idRoot}position-field`}
          label="Position"
          name="postTax"
          options={[
            {
              key: true,
              text: 'Post-Tax',
              value: true,
            },
            {
              key: false,
              text: 'Pre-Tax',
              value: false,
            },
          ]}
        />

        <div className="section">
          {props.discount && (
            <button
              className="link-button"
              css={{
                color: dangerDark,
                width: '100%',
                padding: '1rem',
                marginBottom: '1rem',
              }}
              id={`${idRoot}remove-discount-button`}
              onClick={(): void => removeDialog.show()}
            >
              Remove Discount
            </button>
          )}
          <Button
            id={`${idRoot}${
              props.discount ? 'save-changes' : 'add-discount'
            }-button`}
            onClick={form.handleSubmit(handleSubmit)}
          >
            {props.discount ? 'Save Changes' : 'Add Discount'}
          </Button>
        </div>
      </FormProvider>

      {removeDialog.render}
    </SlideoutLayout>
  )
}

export default DiscountModal
