/* eslint @typescript-eslint/no-unused-expressions: 0 */
import React from 'react'
import '@mhd/components-library/semantic/dist/semantic.min.css'
import axios from 'axios'
import ReactDOM from 'react-dom'
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  from,
} from '@apollo/client'

import App, {IntentType} from './screens/App'
import {Parameters} from './models/parameters'
import * as serviceWorker from './serviceWorker'
import {UiSettingsInterface} from './models/settingsModel'

function omitTypename(key: string, value: unknown): unknown {
  return key === '__typename' ? undefined : value
}

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename,
    )
  }

  return forward(operation)
})

const cache = new InMemoryCache({
  typePolicies: {
    ItemResponse: {
      keyFields: false,
    },
    CustomerResponse: {
      keyFields: false,
    },
  },
})

const httpLink = new HttpLink({
  uri: `/graphql`,
})
const client = new ApolloClient({
  cache,
  link: from([cleanTypenameLink, httpLink]),
})

const getUrlParams = (): Parameters => {
  const search = window.location.search
  const hashes = search.slice(search.indexOf('?') + 1).split('&')
  const params: Parameters = {
    s: 0,
    t: '',
    i: '',
    l: 0,
    u: '',
    c: 0,
    iid: 0,
    pi: 0,
  }

  hashes.forEach(hash => {
    const [key, val] = hash.split('=')

    params[key] =
      key === 's' || key === 'l'
        ? Number(decodeURIComponent(val).replace(/\D+/gm, '') || '0')
        : val
  })

  return params
}
;(async (): Promise<void> => {
  const uiSettings = await axios.get<UiSettingsInterface>('/ui-settings')

  const params = getUrlParams()

  ReactDOM.render(
    <ApolloProvider client={client}>
      <App
        alLeadId={params.l}
        alSpid={params.s}
        alToken={params.t}
        alUrl={params.u}
        customerId={params.c}
        intent={params.i as IntentType}
        invoiceId={params.iid}
        paymentId={params.pi}
        uiSettings={uiSettings.data}
      />
    </ApolloProvider>,
    document.getElementById('root'),
  )
})()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
