import {css} from '@emotion/core'

export const leadListItem = css({
  padding: '1.0rem 1.6rem 1.0rem 2.0rem !important',
  fontFamily: 'Helvetica !important',
  fontWeight: 400,
  '& .ui.header': {
    fontWeight: 'normal',
  },
})

export const leadTime = css({
  color: '#4A4A4A !important',
  float: 'left',
})

export const leadTypeLabel = css`
  font-size: 1.1rem !important;
  border-radius: 100px !important;
  padding: 1px 6px !important;
  float: right;
  display: inline-block;
  width: fit-content;
  text-transform: uppercase !important;
  min-width: 72px;
  text-align: center;

  &.orange {
    color: #e96841 !important;
    border-color: #e96841 !important;
  }

  &.blue {
    color: #12a3ee !important;
    border-color: #12a3ee !important;
  }
`

export const leadName = css({
  clear: 'both',
  marginTop: '1.0rem !important',
  marginBottom: '1.0rem !important',
  color: '#1C232D !important',
})

export const leadInfo = css({
  lineHeight: '1.7rem !important',
  color: '#5E5B5B !important',
})
