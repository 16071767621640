import gql from 'graphql-tag'

export const GET_PORTAL = gql`
  query GetPortal($portalId: ID!, $alUrl: String!) {
    getAlPortal(portalId: $portalId, alUrl: $alUrl) {
      id
      name
      address {
        fullAddress
        lineOne
        lineTwo
        city
        state
        zip
        country
      }
      primaryContactInfo {
        primaryPhoneNumber {
          original
          formatted
        }
        fullName
        firstName
        lastName
        email
        website
      }
    }
  }
`
