/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useQuery} from '@apollo/client'

import TransactionList from '@app/components/TransactionList'

import {
  PageNames,
  DefaultPaginationOptions,
  PAGE_SIZE,
} from '@app/helpers/constants'
import useGraphOptions from '@app/helpers/customHooks/graphOptions'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'

import {GET_PAYMENTS} from '@app/graphql/payment.graphql'

import PaymentListItem from './PaymentListItem'
import {Customer} from '@app/models/customerModels'
import {useContext} from 'react'
import FeatureContext from '@app/context/FeatureContext'
import {CollectPaymentButtonFlag} from '@app/helpers/featureFlags'

export interface CustomerPaymentProps {
  customer: Customer
}

const CustomerPayments = ({customer}: CustomerPaymentProps): JSX.Element => {
  const collectPaymentButton = useContext(FeatureContext)[
    CollectPaymentButtonFlag
  ]

  const {data, fetchMore} = useQuery(
    GET_PAYMENTS,
    useGraphOptions(
      {
        ...DefaultPaginationOptions,
        customerId: customer.id,
      },
      {
        fetchPolicy: 'cache-and-network',
      },
    ),
  )

  useAnalytics(PageNames.customer, {customerId: customer.id})
  const {track} = useAnalytics(PageNames.customerPayments)

  const handleNewPayment = (): void => {
    track('Item Clicked', {
      description: 'collect payment button is clicked from the customer page',
      activityLocation: PageNames.customerPayments,
    })
    window.location.href = `/done/invoice-list?customerId=${customer.id}`
  }

  return (
    <div className="fullWidth">
      <TransactionList
        buttonId={
          collectPaymentButton
            ? 'bmt__payments_customer-payments_collect-payment-button'
            : undefined
        }
        buttonText={collectPaymentButton ? 'Collect Payment' : undefined}
        emptyText={`No existing payments for this customer.`}
        fetchMore={(): void => {
          fetchMore({
            variables: {
              offset: data?.getPayments?.payments?.length || PAGE_SIZE,
            },
            updateQuery: (prev, {fetchMoreResult}) => {
              if (!fetchMoreResult) return prev
              return {
                ...prev,
                getPayments: {
                  ...fetchMoreResult.getPayments,
                  payments: [
                    ...prev.getPayments.payments,
                    ...fetchMoreResult.getPayments.payments,
                  ],
                },
              }
            },
          })
        }}
        ItemComponent={PaymentListItem}
        title="Payment"
        totalRows={data?.getPayments?.totalRows}
        transactions={data?.getPayments?.payments}
        onNew={handleNewPayment}
      />
    </div>
  )
}

export default CustomerPayments
