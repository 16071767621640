export const MAX_INPUT_NUMBER = 1000000

export const PageNames = {
  quoteList: 'mhd : send quote list',
  createQuote: 'mhd : create quote',
  editQuote: 'mhd : edit quote',
  emailQuote: 'mhd : email quote',
  quoteSent: 'mhd : quote sent',
  leadQuoteList: 'mhd : lead quote list',

  invoiceList: 'mhd : send invoice list',
  createInvoice: 'mhd : create invoice',
  editInvoice: 'mhd : edit invoice',
  emailInvoice: 'mhd : email invoice',
  invoiceSent: 'mhd : invoice sent',
  leadInvoiceList: 'mhd : lead invoice list',

  importQuote: 'mhd : import quote',
  createInvoiceQuote: 'mhd : create a quote and mhd : create an invoice',
  addItem: 'mhd : add item',

  customer: 'mhd : customer',
  selectCustomer: 'mhd : select a customer',
  addCustomer: 'mhd : add a customer',
  selectContact: 'mhd : select a contact',

  customerQuotes: 'mhd : customer page quote list',
  customerInvoices: 'mhd : customer page invoice list',
  customerPayments: 'mhd : customer page payments list',
  customerBusiness: 'mhd : my business customer list',
  customerDetails:
    'mhd : customer page quote list & mhd : customer page invoice list & mhd : customer page payments list',

  itemList: 'mhd : items list',
  createItem: 'mhd : items list create item',
  editItem: 'mhd : items list edit item',
}

export const PAGE_SIZE = 50
export const DefaultPaginationOptions = {
  offset: 0,
  limit: PAGE_SIZE,
  sortOrder: 'asc',
}

export const PERCENT_TYPE = 'Percent'
export const DOLLAR_TYPE = 'Dollar'
export const ACTIVE_STATUS = 'Active'
export const PRODUCT_TYPE = 'Product'
export const SERVICE_TYPE = 'Service'

export type TransactionStatus =
  | 'Draft'
  | 'Sent'
  | 'Approved'
  | 'Declined'
  | 'More Info'
  | 'Partially Paid'
  | 'Paid'
  | 'Void'
  | 'Overdue'
