import React, {useEffect, useState} from 'react'
import {PageNames} from '@app/helpers/constants'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'
import useCustomer from '@app/helpers/customHooks/useCustomer'
import useTransaction from '@app/helpers/customHooks/useTransaction'
import Loading from '@app/components/Loading'

import QuoteForm from './QuoteForm'

const NewQuote = (): JSX.Element => {
  const {leadId, customer, isCustomer} = useCustomer()
  const {nextNumber} = useTransaction(true)

  useAnalytics(PageNames.createQuote, {
    leadId,
    quoteId: null,
  })

  const [quote, setQuote] = useState({
    id: undefined,
    number: nextNumber,
    leadId,
    createdDate: new Date().toLocaleString(),
    customerNotes: undefined,
    privateNotes: undefined,
    firstName: undefined,
    lastName: undefined,
    pdfName: '',
    amount: 0,
    tax: undefined,
    discountAmount: 0,
    items: [],
    status: 'Draft',
  })

  useEffect(() => {
    if (nextNumber) {
      setQuote({...quote, number: nextNumber})
    }
  }, [nextNumber])

  if (!customer || !nextNumber) return <Loading al />

  return (
    <QuoteForm
      customer={customer}
      isCustomer={isCustomer}
      leadId={leadId}
      pageName={PageNames.createQuote}
      quote={quote}
    />
  )
}

export default NewQuote
