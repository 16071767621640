import gql from 'graphql-tag'

export const CUSTOMER = gql`
  fragment Customer on CustomerResponse {
    id
    leadId
    alId
    firstName
    lastName
    address {
      lineOne
      lineTwo
      city
      state
      zip
      country
    }
    phone
    email
  }
`

export const TRANSACTION_ITEM = gql`
  fragment TransactionItem on ItemResponse {
    id
    name
    type
    description
    sku
    product {
      quantity
      price
    }
    service {
      duration
      rate
    }
    taxable
    category
  }
`
