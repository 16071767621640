import {useHistory as useRouterHistory} from 'react-router-dom'

interface UseHistoryInterface {
  replace: (pathname: string) => void
  push: (pathname: string) => void
}

const useHistory = (): UseHistoryInterface => {
  const history = useRouterHistory()

  return {
    replace: (pathname: string): void => {
      history.replace({
        search: history.location.search,
        pathname,
      })
    },
    push: (pathname: string): void => {
      history.push({
        search: history.location.search,
        pathname,
      })
    },
  }
}

export default useHistory
