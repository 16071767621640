/** @jsx jsx */
import {jsx} from '@emotion/core'
import InfiniteScroll from 'react-infinite-scroller'
import {Semantic} from '@mhd/components-library'
import Loading from '@app/components/Loading'

import {MinimalTransaction} from '@app/models/transactionModels'

import Button from '../Button'

const {Header} = Semantic

export interface TransactionItemComponentProps {
  transaction: MinimalTransaction
}

export interface TransactionListProps {
  title: string
  transactions?: Array<MinimalTransaction>
  totalRows?: number
  ItemComponent:
    | React.ComponentClass<TransactionItemComponentProps>
    | React.StatelessComponent<TransactionItemComponentProps>
  onNew: () => void
  fetchMore: () => void
  emptyText: string
  buttonId?: string
  buttonText?: string
}

const TransactionList = (props: TransactionListProps): JSX.Element => {
  const {ItemComponent} = props
  return (
    <div>
      {props.buttonText && (
        <Button
          css={{margin: '1.5rem auto !important'}}
          id={props.buttonId}
          onClick={props.onNew}
        >
          {props.buttonText}
        </Button>
      )}

      {props.transactions?.length === 0 ? (
        <div className="section">
          <Header as="h3" textAlign="center">
            <Header.Subheader className="textLight">
              <i>{props.emptyText}</i>
            </Header.Subheader>
          </Header>
        </div>
      ) : (
        <div>
          <InfiniteScroll
            hasMore={
              !props.transactions ||
              props.totalRows === undefined ||
              props.transactions.length < props.totalRows
            }
            initialLoad={false}
            loader={<Loading key={0} al />}
            loadMore={props.fetchMore}
            pageStart={0}
          >
            {props.transactions &&
              props.transactions.map((q: MinimalTransaction) => (
                <ItemComponent key={q.id} transaction={q} />
              ))}
          </InfiniteScroll>
        </div>
      )}
    </div>
  )
}

export default TransactionList
