/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useEffect} from 'react'
import {GraphQLError} from 'graphql'
import {useLazyQuery, useMutation} from '@apollo/client'
import Loading from '@app/components/Loading'

import useGraphOptions, {
  useMutationOptions,
} from '@app/helpers/customHooks/graphOptions'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'

import {GET_ITEM, SAVE_ITEM} from '@app/graphql/item.graphql'
import {PriceListItem} from '@app/models/priceListItemModels'

import PriceListItemModal from './PriceListItemModal'

export interface NewPriceListItemModalProps {
  priceListItemId?: number
  onAddItem: (item: PriceListItem) => void
  onClose: () => void
  onBack: () => void
}

const NewPriceListItemModal = (
  props: NewPriceListItemModalProps,
): JSX.Element => {
  const [getItem, item] = useLazyQuery(
    GET_ITEM,
    useGraphOptions(
      {priceListItemId: Number(props.priceListItemId)},
      {fetchPolicy: 'cache-and-network'},
    ),
  )
  const {track} = useAnalytics()

  useEffect((): void => {
    if (props.priceListItemId) {
      getItem()
    }
  }, [props.priceListItemId]) // eslint-disable-line react-hooks/exhaustive-deps

  const [saveItem] = useMutation(SAVE_ITEM, useMutationOptions())

  const handleSave = async (
    item: PriceListItem,
    close = false,
  ): Promise<string | undefined> => {
    if (!props.priceListItemId) {
      try {
        const result = await saveItem({variables: {input: item}})

        if (result.errors && result.errors.length > 0) {
          return result.errors[0].message
        } else {
          props.onAddItem({
            ...item,
            id: result.data?.savePriceListItem?.id || 0,
          })
        }
        track('Item Created and Added', {})
      } catch (ex) {
        const error = ex as GraphQLError
        return error.message
      }
    } else {
      props.onAddItem({id: props.priceListItemId, ...item})
    }
    if (close) {
      props.onClose()
    }
    return undefined
  }

  if (props.priceListItemId && !item.data?.getPriceListItem)
    return <Loading al />

  const priceListItem = props.priceListItemId
    ? (item.data?.getPriceListItem as PriceListItem)
    : {
        name: '',
        type: 'Product',
        taxable: true,
      }

  return (
    <PriceListItemModal
      item={priceListItem}
      isNew
      onBack={props.onBack}
      onClose={props.onClose}
      onSubmit={handleSave}
    />
  )
}

export default NewPriceListItemModal
