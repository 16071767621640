import gql from 'graphql-tag'

export const GET_LEAD = gql`
  query GetLead($portalId: ID!, $leadId: ID!, $alUrl: String!) {
    getLead(portalId: $portalId, leadId: $leadId, alUrl: $alUrl) {
      id
      alId
      fullName
      firstName
      lastName
      email
      phone
      address {
        fullAddress
        lineOne
        lineTwo
        city
        state
        zip
        country
      }
    }
  }
`
export const GET_LEADS = gql`
  query GetLeads($portalId: ID!, $alUrl: String!) {
    getLeads(portalId: $portalId, alUrl: $alUrl) {
      id
      title
      source
      activityId
      read
      category {
        id
        name
      }
      description
      urgency
      status
      createDate
      leadType
      customerInfo {
        id
        fullName
        firstName
        lastName
        email
        phone
        address {
          fullAddress
          lineOne
          lineTwo
          city
          state
          zip
          country
        }
      }
    }
  }
`
