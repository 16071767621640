import React, {useState} from 'react'
import Dialog from '@app/components/Layout/Dialog'

interface UseDialogInterface {
  show: () => void
  render: JSX.Element | null
}

interface UseDialogParams {
  title: string
  content: string | JSX.Element
  primaryText: string
  secondaryText?: string
  destructive?: boolean
  onSubmit?: (save: boolean) => void
}

export const useDialog = (props: UseDialogParams): UseDialogInterface => {
  const [visible, setVisible] = useState(false)

  const show = (): void => setVisible(true)

  const handleConfirm = (primary: boolean): void => {
    if (props.onSubmit) {
      props.onSubmit(primary)
    }

    setVisible(false)
  }

  const render = visible ? (
    <Dialog
      destructive={props.destructive}
      primaryText={props.primaryText}
      secondaryText={props.secondaryText}
      title={props.title}
      onPrimaryClick={(): void => {
        handleConfirm(true)
      }}
      onSecondaryClick={(): void => {
        handleConfirm(false)
      }}
    >
      {props.content}
    </Dialog>
  ) : null

  return {show, render}
}

interface UseSaveDialogParams {
  onSubmit: (save: boolean) => Promise<boolean> | boolean
}

export const useSaveDialog = (
  props: UseSaveDialogParams,
): UseDialogInterface => {
  return useDialog({
    title: 'Warning Unsaved Changes',
    content: ' Would you like to save or discard your changes?',
    primaryText: 'Save',
    secondaryText: 'Discard',
    onSubmit: props.onSubmit,
  })
}
