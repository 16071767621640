/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useQuery} from '@apollo/client'
import {useHistory} from 'react-router'

import TransactionList from '@app/components/TransactionList'

import {
  PageNames,
  DefaultPaginationOptions,
  PAGE_SIZE,
} from '@app/helpers/constants'
import useGraphOptions from '@app/helpers/customHooks/graphOptions'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'
import {CustomerRouteProps} from '@app/helpers/customHooks/useCustomer'

import {GET_INVOICES} from '@app/graphql/invoice.graphql'
import {Customer} from '@app/models/customerModels'

import InvoiceListItem from './InvoiceListItem'

export interface CustomerInvoiceProps {
  customer: Customer
}

const CustomerInvoices = ({customer}: CustomerInvoiceProps): JSX.Element => {
  const history = useHistory<CustomerRouteProps>()

  const {data, fetchMore} = useQuery(
    GET_INVOICES,
    useGraphOptions(
      {
        ...DefaultPaginationOptions,
        customerId: customer.id,
      },
      {
        fetchPolicy: 'cache-and-network',
      },
    ),
  )

  useAnalytics(PageNames.customer, {customerId: customer.id})
  const {track} = useAnalytics(PageNames.customerInvoices)

  const handleNewInvoice = (): void => {
    track('Item Clicked', {
      description: 'create invoice button clicked from the customer page',
      activityLocation: PageNames.customerInvoices,
    })
    history.push({
      pathname: `/invoices/new`,
      state: {customer, isCustomer: true},
      search: history.location.search,
    })
  }

  return (
    <div className="fullWidth">
      <TransactionList
        buttonId="bmt__invoices_invoice-list_customer-invoices_create-invoice-button"
        buttonText="Create an Invoice"
        emptyText="No existing invoices for this customer"
        fetchMore={(): void => {
          fetchMore({
            variables: {
              offset: data?.getInvoices?.invoices?.length || PAGE_SIZE,
            },
            updateQuery: (prev, {fetchMoreResult}) => {
              if (!fetchMoreResult) return prev
              return {
                ...prev,
                getInvoices: {
                  ...fetchMoreResult.getInvoices,
                  invoices: [
                    ...prev.getInvoices.invoices,
                    ...fetchMoreResult.getInvoices.invoices,
                  ],
                },
              }
            },
          })
        }}
        ItemComponent={InvoiceListItem}
        title="Invoice"
        totalRows={data?.getInvoices?.totalRows}
        transactions={data ? data.getInvoices?.invoices : undefined}
        onNew={handleNewInvoice}
      />
    </div>
  )
}

export default CustomerInvoices
