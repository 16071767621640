/** @jsx jsx */
import {css, jsx} from '@emotion/core'
import {useContext} from 'react'
import {useHistory} from 'react-router'

import Button from '@app/components/Button'
import NavBarLayout from '@app/components/Layout/NavBarLayout'
import {AuthenticationContext} from '@app/screens/App'

import {PageNames} from '@app/helpers/constants'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'
import {Customer} from '@app/models/customerModels'

import emailConfirmation from '../../assets/images/angi-emailConfirmation.png'

export interface EmailConfirmationProps {
  type?: string
  customer?: Customer
  transactionId?: number
}

const EmailConfirmation = ({
  type,
  customer,
  transactionId,
}: EmailConfirmationProps): JSX.Element => {
  const history = useHistory()
  const authState = useContext(AuthenticationContext)
  const pageName =
    type === 'Quote' ? PageNames.quoteSent : PageNames.invoiceSent
  useAnalytics(pageName, {[`${type?.toLowerCase()}Id`]: transactionId})

  return (
    <NavBarLayout
      headerStyles={css`
        font-size: 24px !important;
      `}
      title={`${type} Sent`}
      hideBack
    >
      <div className="ui centered grid" css={{padding: '40px !important'}}>
        <img
          alt="email Confirmation"
          className="ui centered grid"
          height="100%"
          src={emailConfirmation}
          width="100%"
        />
      </div>
      <p>
        {`We have delivered the ${type ? type.toLowerCase() : ''} to ${
          customer ? `${customer.firstName} ${customer.lastName}` : ''
        }. Keep an eye on your
        notifications to see when they respond.`}
      </p>
      <br />
      <Button
        event={{
          description: 'user clicks the back to lead detail button',
          activityLocation: pageName,
        }}
        onClick={(): void => {
          if (authState.intent === 'my-customers') {
            history.goBack()
          } else {
            window.location.href = '/done'
          }
        }}
      >
        Close this Window
      </Button>
    </NavBarLayout>
  )
}
export default EmailConfirmation
