import {css} from '@emotion/core'
import {containerPadding} from '@app/styles/variables'
import {secondary, secondaryDark, textLight} from './colors'

export const globals = css({
  '.fullWidth': {
    marginRight: `-${containerPadding}`,
    marginLeft: `-${containerPadding}`,
    paddingLeft: containerPadding,
    paddingRight: containerPadding,
  },

  '.section': {
    marginTop: '4rem',
  },

  '.grid': {
    display: 'grid',
    gridColumnGap: 20,
    gridRowGap: 25,
  },

  '.cols-2': {
    gridTemplateColumns: '47% 47%',
  },

  '.textLight': {
    color: `${textLight} !important`,
  },
})

export const overrides = css`
  .ui.divider,
  .ui.fitted.divider {
    margin-left: -${containerPadding};
    margin-right: -${containerPadding};
  }

  .link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: ${secondary};
  }

  .link-button:hover,
  .link-button:focus {
    text-decoration: none;
    color: ${secondaryDark};
    outline: none;
  }

  h6 {
    font-size: 1.2rem;
  }

  h6.ui.header .sub.header {
    font-size: 1.2rem;
    font-weight: 400;
  }
`

export const animations = css`
  /** slide **/
  .slide-enter,
  .slide-exit {
    transition: all 0.3s;
    position: absolute;
  }

  .slide-enter {
    left: 100%;
    right: -100%;
  }

  .slide-enter-active,
  .slide-exit {
    left: 0;
    right: 0;
  }

  .slide-exit-active {
    left: -100%;
    right: 100%;
  }

  /** slide back */
  .back.slide-enter {
    left: -100%;
    right: 100%;
  }

  .back.slide-enter-active,
  .back.slide-exit {
    left: 0;
    right: 0;
  }

  .back.slide-exit-active {
    left: 100%;
    right: -100%;
  }
`
