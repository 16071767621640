/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useContext, useEffect} from 'react'
import {useQuery, useLazyQuery} from '@apollo/client'
import {useParams} from 'react-router'
import Loading from '@app/components/Loading'

import {PageNames} from '@app/helpers/constants'
import useGraphOptions from '@app/helpers/customHooks/graphOptions'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'
import {AuthenticationContext} from '@app/screens/App'

import {GET_QUOTE} from '@app/graphql/quote.graphql'
import {GET_CUSTOMER} from '@app/graphql/customer.graphql'

import QuoteForm from './QuoteForm'

const EditQuote = (): JSX.Element => {
  const authState = useContext(AuthenticationContext)
  const {id: quoteId} = useParams()

  const quote = useQuery(GET_QUOTE, useGraphOptions({quoteId: Number(quoteId)}))

  const customer = quote.data?.getQuote.customer
  const leadId = customer?.alId || authState.alLeadId
  const graphOptions = useGraphOptions({})
  const [getContact, {data: contactData}] = useLazyQuery(GET_CUSTOMER)
  useEffect(() => {
    if (customer || leadId) {
      getContact({
        context: graphOptions.context,
        variables: {
          ...graphOptions.variables,
          customerId: customer?.id,
        },
      })
    }
  }, [customer?.id]) // eslint-disable-line
  const customerId =
    contactData && contactData.getCustomer && contactData.getCustomer.customerId
  useAnalytics(PageNames.editQuote, {leadId})

  if (!customerId) return <Loading al />

  return (
    <QuoteForm
      customer={customer}
      customerId={customerId}
      leadId={leadId}
      pageName={PageNames.editQuote}
      quote={quote.data ? quote.data.getQuote : undefined}
    />
  )
}

export default EditQuote
