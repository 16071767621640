import {css} from '@emotion/core'
import {secondaryLight} from '@app/styles/colors'

export const importBanner = css({
  backgroundColor: secondaryLight,
  color: 'white',
  position: 'relative',
  paddingTop: '2.5rem',
  paddingBottom: '2rem',
})

export const bannerClose = css({
  position: 'absolute',
  right: '1rem',
  top: '1rem',
})

export const importButton = css({
  marginTop: '2rem !important',
  background: 'white !important',
})
