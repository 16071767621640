/** @jsx jsx */
import {css, jsx} from '@emotion/core'
import {useState, ChangeEvent} from 'react'
import {useQuery} from '@apollo/client'
import InfiniteScroll from 'react-infinite-scroller'
import {Semantic} from '@mhd/components-library'
import Loading from '@app/components/Loading'

import Button from '@app/components/Button'
import SearchInput from '@app/components/Form/SearchInput'
import NavBarLayout from '@app/components/Layout/NavBarLayout'
import CloseIcon from '@app/components/Layout/CloseIcon'

import {
  PageNames,
  DefaultPaginationOptions,
  PAGE_SIZE,
  ACTIVE_STATUS,
} from '@app/helpers/constants'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'
import useGraphOptions from '@app/helpers/customHooks/graphOptions'

import {PriceListItem} from '@app/models/priceListItemModels'
import {GET_ITEMS} from '@app/graphql/item.graphql'

import PriceListItemRow from './PriceListItemRow'

import * as styles from './styles'

export interface SelectItemProps {
  onSelection: (item: PriceListItem) => void
  onCreateItem: () => void
  onClose: () => void
}

const SelectItem = (props: SelectItemProps): JSX.Element => {
  const [search, setSearch] = useState('')

  const paginationOptions = {
    ...DefaultPaginationOptions,
    sortOrder: 'desc',
    filter: {recordStatus: [ACTIVE_STATUS]},
  }
  const {data, loading, fetchMore, refetch} = useQuery(
    GET_ITEMS,
    useGraphOptions(paginationOptions, {
      fetchPolicy: 'cache-and-network',
    }),
  )
  const {track} = useAnalytics(PageNames.itemList)

  const handleClose = (): void => {
    props.onClose()
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    track('Item Clicked', {
      description: 'clicking into the items list search field',
      activityLocation: PageNames.itemList,
    })
    setSearch(e.target.value)
    refetch({
      ...paginationOptions,
      searchTerm: e.target.value,
    })
  }

  const handleNewItem = (): void => {
    track('Item Clicked', {
      description: 'create item button clicked from the items list',
      activityLocation: PageNames.itemList,
    })
    props.onCreateItem()
  }

  const handleItemSelected = (item: PriceListItem): void => {
    track('Item Clicked', {
      description: 'clicking an existing item from the items list',
      activityLocation: PageNames.itemList,
    })
    props.onSelection(item)
  }
  return (
    <NavBarLayout
      actionButton={
        <div onClick={handleClose}>
          <CloseIcon
            css={css`
              margin-bottom: 7px;
            `}
          />
        </div>
      }
      subtitle="Select or Create an Item"
      title="Items"
      hideBack
    >
      <SearchInput
        label="Name, SKU, or Description..."
        value={search}
        onChange={handleSearchChange}
      />

      <Button
        id="bmt__transaction-edit_items_select-item_create-item-button"
        onClick={handleNewItem}
      >
        Create Item
      </Button>

      <div className="fullWidth" css={styles.selectItemHeader}>
        <Semantic.Divider hidden />

        <Semantic.Header as="h6" disabled>
          <Semantic.Header.Subheader css={styles.selectItemListHeader}>
            <span>Category/Name/Description</span>
            <span>Price/Rate/SKU</span>
          </Semantic.Header.Subheader>
        </Semantic.Header>

        <Semantic.Divider fitted />
      </div>

      <InfiniteScroll
        hasMore={
          !loading &&
          (!data ||
            !data?.getPriceListItems ||
            data?.getPriceListItems?.priceListItems?.length <
              data?.getPriceListItems?.totalRows)
        }
        initialLoad={false}
        loader={<Loading key={0} al />}
        loadMore={(): void => {
          fetchMore({
            variables: {
              offset:
                data?.getPriceListItems?.priceListItems?.length || PAGE_SIZE,
            },
            updateQuery: (prev, {fetchMoreResult}) => {
              if (
                !fetchMoreResult ||
                prev.getPriceListItems.priceListItems.length ===
                  prev.getPriceListItems.totalRows
              )
                return prev
              return {
                ...prev,
                getPriceListItems: {
                  ...fetchMoreResult.getPriceListItems,
                  priceListItems: [
                    ...prev.getPriceListItems.priceListItems,
                    ...fetchMoreResult.getPriceListItems.priceListItems,
                  ],
                },
              }
            },
          })
        }}
        pageStart={0}
        threshold={75}
      >
        {data?.getPriceListItems ? (
          data?.getPriceListItems.priceListItems.map((item: PriceListItem) => (
            <PriceListItemRow
              key={item.id}
              item={item}
              onItemSelected={handleItemSelected}
            />
          ))
        ) : (
          <Loading al />
        )}
      </InfiniteScroll>
    </NavBarLayout>
  )
}

export default SelectItem
