/* eslint-disable @typescript-eslint/no-explicit-any */
import {useContext} from 'react'

import {AuthenticationContext} from '@app/screens/App'
import {
  DocumentNode,
  LazyQueryHookOptions,
  MutationHookOptions,
  MutationUpdaterFn,
  OperationVariables,
  QueryHookOptions,
} from '@apollo/client'
import {DefaultPaginationOptions} from '../constants'

const useGraphOptions = (
  otherVariables: OperationVariables,
  otherOptions?: MutationHookOptions | LazyQueryHookOptions | QueryHookOptions,
  includeAlUrl = false,
): MutationHookOptions | LazyQueryHookOptions | QueryHookOptions => {
  const authState = useContext(AuthenticationContext)

  const variables: any = {
    portalId: authState.portalId,
    ...otherVariables,
  }

  if (includeAlUrl) {
    variables.alUrl = authState.alUrl
  }

  return {
    variables,
    ...otherOptions,
  }
}

export default useGraphOptions

export const useMutationOptions = (cacheInfo?: {
  query: DocumentNode
  queryName: string
  listName: string
  isNew: boolean
  mutationName: string
  variables: any
}): MutationHookOptions => {
  const authState = useContext(AuthenticationContext)

  const update: MutationUpdaterFn = (cache, {data}) => {
    if (!cacheInfo || !data) return

    const {query, queryName, listName, isNew, mutationName} = cacheInfo

    const newItem = data[mutationName]
    const variables = {
      portalId: authState.portalId,
      ...DefaultPaginationOptions,
      ...cacheInfo.variables,
    }

    const cachedData = cache.readQuery<any>({
      query,
      variables,
    })

    if (isNew && cachedData) {
      cache.writeQuery({
        query,
        variables,
        data: {
          [queryName]: {
            ...cachedData[queryName],
            [listName]: [newItem, ...cachedData[queryName][listName]],
            totalRows: cachedData[queryName].totalRows + 1,
          },
        },
      })
    }
  }

  return {
    variables: {
      portalId: authState.portalId,
    },
    update: cacheInfo ? update : undefined,
  }
}
