import gql from 'graphql-tag'

export const GET_ITEMS = gql`
  query GetPriceListItems(
    $portalId: ID!
    $offset: Int!
    $limit: Int!
    $sortOrder: String!
    $searchTerm: String
    $filter: FilterStatus
  ) {
    getPriceListItems(
      portalId: $portalId
      offset: $offset
      limit: $limit
      sortOrder: $sortOrder
      searchTerm: $searchTerm
      filter: $filter
    ) {
      priceListItems {
        id
        name
        description
        sku
        product {
          price
        }
        service {
          rate
        }
        category
      }
      totalRows
    }
  }
`

const PRICE_LIST_ITEM = gql`
  fragment PriceListItem on PriceListItemResponse {
    id
    type
    name
    description
    sku
    product {
      quantity
      price
    }
    service {
      duration
      rate
    }
    taxable
    totalCost
    category
  }
`

export const GET_ITEM = gql`
  ${PRICE_LIST_ITEM}
  query GetPriceListItem($portalId: ID!, $priceListItemId: ID!) {
    getPriceListItem(portalId: $portalId, priceListItemId: $priceListItemId) {
      ...PriceListItem
    }
  }
`

export const SAVE_ITEM = gql`
  ${PRICE_LIST_ITEM}
  mutation SavePriceListItem($portalId: ID!, $input: PriceListItemInput!) {
    savePriceListItem(portalId: $portalId, input: $input) {
      ...PriceListItem
    }
  }
`
