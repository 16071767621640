import React from 'react'
import {SerializedStyles} from '@emotion/core'

import closeBlack from '../../assets/images/close_black.svg'

interface CloseIconProps {
  css?: SerializedStyles
  onClick?: () => void
}

const CloseIcon = (props: CloseIconProps): JSX.Element => {
  return <img alt="Close" src={closeBlack} {...props} />
}

export default CloseIcon
