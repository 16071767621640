/* eslint-disable react/display-name */
/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useContext, useState} from 'react'
import {useHistory} from 'react-router'
import {Semantic} from '@mhd/components-library'
import {useParams} from 'react-router'
import {useQuery} from '@apollo/client'
import Loading from '@app/components/Loading'

import {RouteParams} from '@app/screens/App'
import {NavContext} from '@app/context/NavContext'
import NavBarLayout from '@app/components/Layout/NavBarLayout'
import {formatPhoneNumber} from '@app/helpers'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'
import {PageNames} from '@app/helpers/constants'

import {CustomerRouteProps} from '@app/helpers/customHooks/useCustomer'
import CustomerQuotes from '../Quotes/QuoteList/CustomerQuotes'
import CustomerInvoices from '../Invoices/InvoiceList/CustomerInvoices'
import CustomerPayments from '../Payments/CustomerPayments'

import useModal from '@app/helpers/customHooks/useModal'
import EditCustomerModal from './EditCustomerModal'
import useGraphOptions from '@app/helpers/customHooks/graphOptions'
import {GET_CUSTOMER} from '@app/graphql/customer.graphql'

import pencil from '../../assets/images/pencil_outline.svg'
import locationImg from '../../assets/images/location.svg'
import mailImg from '../../assets/images/mail.svg'
import callImg from '../../assets/images/call.svg'

import * as styles from './styles'

export interface CustomerDetailRouteProps {
  tab?: string
}

const CustomerDetail = (): JSX.Element => {
  const {goBackTo, toaster} = useContext(NavContext)
  const history = useHistory<CustomerRouteProps & CustomerDetailRouteProps>()

  const params = useParams<RouteParams>()
  const urlContactId = Number(params.contactId)

  const [showFullNotes, setShowFullNotes] = useState(false)

  const {data: contactData} = useQuery(
    GET_CUSTOMER,
    useGraphOptions({customerId: urlContactId}),
  )
  const contact = contactData?.getCustomer

  const {track} = useAnalytics()
  const activeTab =
    history.location.state && history.location.state['tab']
      ? history.location.state['tab']
      : 'quotes'

  const handleTabClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    data: Semantic.MenuItemProps,
  ): void => {
    history.replace({
      pathname: `/customer/${contact?.customerId}/contact/${contact?.id}`,
      state: {tab: data.name},
      search: history.location.search,
    })
  }

  const editModal = useModal({
    children: contact ? (
      <EditCustomerModal
        contact={contact}
        autoSave
        isCustomerDetails
        onClose={(): void => editModal.hide()}
        onSubmit={(): void => {
          toaster.success('Customer Updated.')
          editModal.hide()
        }}
      />
    ) : null,
  })

  const handleEdit = (): void => {
    track('Item Clicked', {
      description: 'the edit icon is clicked from the customer page',
      activityLocation: PageNames.customerDetails,
    })
    editModal.show()
  }

  if (!contact) {
    return <Loading al />
  }

  return (
    <NavBarLayout
      actionButton={
        <div
          id="bmt__customers_customer-detail_edit-customer"
          onClick={handleEdit}
        >
          <img alt="" src={pencil} style={{width: 24, height: 24}} />
        </div>
      }
      title={`${contact?.firstName} ${contact?.lastName}`}
      greyBg
      onBack={(): boolean => {
        goBackTo('/customers')
        return false
      }}
    >
      <div
        className="fullWidth"
        css={{backgroundColor: 'white', paddingBottom: '2rem'}}
      >
        <hr className="fullWidth" />

        {toaster.render()}

        <div css={styles.customerDetail}>
          {contact.phone && (
            <img alt="" src={callImg} style={{width: 24, height: 24}} />
          )}
          {contact.phone && (
            <a
              href={`tel:${contact.phone}`}
              onClick={(): void => {
                track('Item Clicked', {
                  description:
                    'the phone field is clicked from the customer page',
                  activityLocation: PageNames.customerDetails,
                })
              }}
            >
              {formatPhoneNumber(contact.phone)}
            </a>
          )}

          <img alt="" src={mailImg} style={{width: 24, height: 24}} />

          <a
            href={`mailto:${contact.email}`}
            rel="noopener noreferrer"
            target="_blank"
            onClick={(): void => {
              track('Item Clicked', {
                description:
                  'the email address field is clicked from the customer page',
                activityLocation: PageNames.customerDetails,
              })
            }}
          >
            {contact.email}
          </a>

          <img alt="" src={locationImg} style={{width: 24, height: 24}} />

          <a
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(
              [
                contact.address?.lineOne || '',
                contact.address?.lineTwo || '',
                contact.address?.city || '',
                contact.address?.state || '',
                contact.address?.zip || '',
              ].join(' '),
            )}`}
            rel="noopener noreferrer"
            target="_blank"
            onClick={(): void => {
              track('Item Clicked', {
                description:
                  'the address field is clicked from the customer page',
                activityLocation: PageNames.customerDetails,
              })
            }}
          >
            {contact.address?.lineOne ? (
              <div>{contact.address?.lineOne}&nbsp;</div>
            ) : null}
            {contact.address?.lineTwo ? (
              <div>{contact.address?.lineTwo}&nbsp;</div>
            ) : null}
            <div>
              {contact.address?.city ? `${contact.address?.city}, ` : null}
              {contact.address?.state} {contact.address?.zip}
            </div>
          </a>
        </div>

        {contact.notes && (
          <div css={styles.notesSection}>
            <Semantic.Header as="h5" disabled>
              Customer Notes
            </Semantic.Header>
            <a
              css={styles.notes}
              onClick={(): void => {
                setShowFullNotes(cur => !cur)
              }}
            >
              {showFullNotes ? contact.notes : contact.notes.substring(0, 122)}
              {contact.notes.length > 122 &&
                (showFullNotes ? (
                  <a>&nbsp;less</a>
                ) : (
                  <span>
                    ... <a>more</a>
                  </span>
                ))}
            </a>
          </div>
        )}

        <hr className="fullWidth" />

        <Semantic.Menu pointing secondary>
          <Semantic.Menu.Item
            active={activeTab === 'quotes'}
            name="quotes"
            onClick={handleTabClick}
          />
          <Semantic.Menu.Item
            active={activeTab === 'invoices'}
            name="invoices"
            onClick={handleTabClick}
          />
          <Semantic.Menu.Item
            active={activeTab === 'payments'}
            name="payments"
            onClick={handleTabClick}
          />
        </Semantic.Menu>
      </div>
      {!contact || !contact.id ? null : activeTab === 'quotes' ? (
        <CustomerQuotes customer={contact} />
      ) : activeTab === 'invoices' ? (
        <CustomerInvoices customer={contact} />
      ) : (
        <CustomerPayments customer={contact} />
      )}

      {editModal.render}
    </NavBarLayout>
  )
}

export default CustomerDetail
