/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import {ApolloError} from '@apollo/client'
import moment from 'moment'
import * as stringUtils from 'angieslist-app-platform/lib/common/utils/stringUtils'
import {Helpers} from '@mhd/components-library'
import {MinimalTransaction} from '@app/models/transactionModels'
import {PriceListItem} from '@app/models/priceListItemModels'

const {helpers} = Helpers

export const handleApolloErrors = (error: any): void => {
  const err = error as ApolloError

  if (err && err.graphQLErrors) {
    err.graphQLErrors.forEach(e => {
      helpers.toasterError(e.message)
    })
  }
}

export const formatNumber = (value: number): string =>
  value.toLocaleString('en-US')

export const formatDecimalNumber = (value: number): string =>
  value.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

export const formatCurrency = (value: number): string =>
  typeof value === 'number' ? `$${formatDecimalNumber(value)}` : '$0.00'

export const formatDateTime = (timestamp?: string, compact = true): string => {
  if (!timestamp) return ''

  const date = moment(timestamp)
  return `${date.format('M/D/YYYY')} ${compact ? '·' : 'at'} ${date.format(
    `h:mm${compact ? '' : ' '}a`,
  )}`
}

export const formatPhoneNumber = stringUtils.formatPhoneNumber

export const transactionStatus = (transaction: MinimalTransaction): string => {
  const createdDate = new Date(transaction.createdDate)
  const updatedDate = new Date(transaction.updatedDate)
  const sentDate = transaction.sentDate ? new Date(transaction.sentDate) : null

  let statusText = sentDate
    ? `Sent on ${formatDateTime(transaction.sentDate, false)}`
    : ''

  if (
    updatedDate > createdDate &&
    (sentDate === null || updatedDate > sentDate)
  ) {
    statusText = `Updated on ${formatDateTime(transaction.updatedDate, false)}`
  }

  return statusText
}

export const equalFields = (a: {}, b: {}, fields: string[]): boolean => {
  for (let i = 0; i < fields.length; i++) {
    if (a[fields[i]] !== b[fields[i]]) return false
  }
  return true
}

export const itemQuantity = (item: PriceListItem): number =>
  item.product ? item.product.quantity : item.service?.duration || 0

export const itemRate = (item: PriceListItem): number =>
  item.product ? item.product.price : item.service?.rate || 0

export function addServerErrors<T>(
  errors: {[P in keyof T]?: string[]},
  setError: (
    fieldName: keyof T,
    error: {type: string; message: string},
  ) => void,
): void {
  return Object.keys(errors).forEach(key => {
    setError(key as keyof T, {
      type: 'server',
      message: errors[key as keyof T]?.join('. ') || '',
    })
  })
}

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isValidEmail = (val: string): boolean => EMAIL_REGEX.test(val)

export const DECIMAL_NUMBER_REGEX = /^(-?)(\d+|\d{1,3}(,\d{3})*)?(\.\d{1,2})?$/

export const VALIDATE_DECIMAL_NUMBER = {
  value: DECIMAL_NUMBER_REGEX,
  message: 'Must be a number with maximum 2 decimal places',
}

export const toNumber = (value: string): number =>
  value ? Number(value.replace(/,|\$/g, '')) || 0 : 0

export const VALIDATE_MAX_NUMBER = (x: string): boolean | string =>
  toNumber(x) <= 1000000 || 'Max 1,000,000'

export const VALIDATE_NOT_NEGATIVE = (x: string): boolean | string =>
  toNumber(x) >= 0 || 'Cannot be negative'

export const VALIDATE_NUMBER_RANGE = {
  max: VALIDATE_MAX_NUMBER,
  min: VALIDATE_NOT_NEGATIVE,
}

export const VALIDATE_NUMBER_RULES = {
  pattern: VALIDATE_DECIMAL_NUMBER,
  validate: VALIDATE_NUMBER_RANGE,
}
