/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useQuery} from '@apollo/client'
import {useHistory} from 'react-router'

import TransactionList from '@app/components/TransactionList'

import {
  PageNames,
  DefaultPaginationOptions,
  PAGE_SIZE,
} from '@app/helpers/constants'
import useGraphOptions from '@app/helpers/customHooks/graphOptions'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'
import {CustomerRouteProps} from '@app/helpers/customHooks/useCustomer'

import {GET_QUOTES} from '@app/graphql/quote.graphql'

import QuoteListItem from './QuoteListItem'
import {Customer} from '@app/models/customerModels'

export interface CustomerQuoteProps {
  customer: Customer
}

const CustomerQuotes = ({customer}: CustomerQuoteProps): JSX.Element => {
  const history = useHistory<CustomerRouteProps>()

  const {data, fetchMore} = useQuery(
    GET_QUOTES,
    useGraphOptions(
      {
        ...DefaultPaginationOptions,
        customerId: customer.id,
      },
      {
        fetchPolicy: 'cache-and-network',
      },
    ),
  )

  useAnalytics(PageNames.customer, {customerId: customer.id})
  const {track} = useAnalytics(PageNames.customerQuotes)

  const handleNewQuote = (): void => {
    track('Item Clicked', {
      description: 'create a quote button clicked from customer page',
      activityLocation: PageNames.customerQuotes,
    })
    history.push({
      pathname: `/quotes/new`,
      state: {customer, isCustomer: true},
      search: history.location.search,
    })
  }

  return (
    <div className="fullWidth">
      <TransactionList
        buttonId="bmt__quotes_quote-list_customer-quotes_create-quote-button"
        buttonText="Create a Quote"
        emptyText="No existing quotes for this customer"
        fetchMore={(): void => {
          fetchMore({
            variables: {
              offset: data?.getQuotes?.quotes?.length || PAGE_SIZE,
            },
            updateQuery: (prev, {fetchMoreResult}) => {
              if (!fetchMoreResult) return prev
              return {
                ...prev,
                getQuotes: {
                  ...fetchMoreResult.getQuotes,
                  quotes: [
                    ...prev.getQuotes.quotes,
                    ...fetchMoreResult.getQuotes.quotes,
                  ],
                },
              }
            },
          })
        }}
        ItemComponent={QuoteListItem}
        title="Quote"
        totalRows={data?.getQuotes?.totalRows}
        transactions={data ? data.getQuotes?.quotes : undefined}
        onNew={handleNewQuote}
      />
    </div>
  )
}

export default CustomerQuotes
