import gql from 'graphql-tag'

export const AUTHENTICATE = gql`
  query Authenticate($alSpid: String!, $alToken: String!, $alUrl: String!) {
    authenticate(alSpid: $alSpid, alToken: $alToken, alUrl: $alUrl) {
      token
    }
  }
`

export const LOGIN = gql`
  mutation Login($emailAddress: String!, $password: String!, $alUrl: String!) {
    login(emailAddress: $emailAddress, password: $password, alUrl: $alUrl) {
      success {
        authToken
        alSpid
      }
      error {
        status
        statusText
      }
    }
  }
`
