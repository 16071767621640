/** @jsx jsx */
import {jsx} from '@emotion/core'

import SlideoutLayout from '@app/components/Layout/Slideout'
import {TransactionStatus} from '@app/helpers/constants'
import ChangeStatusButton from '@app/components/TransactionEdit/Status/ChangeStatusButton'
import {StatusSlideoutProps} from '@app/components/TransactionEdit/Status/StatusButton'

const QuoteStatusSlideout = (props: StatusSlideoutProps): JSX.Element => {
  const statuses: TransactionStatus[] = [
    'Draft',
    'Sent',
    'Approved',
    'Declined',
    'More Info',
  ]
  const idRoot = 'bmt__quotes_add-edit-quote_quote-status-slideout_'

  return (
    <SlideoutLayout
      closeButtonId={`${idRoot}close-button`}
      title="Change Status"
      onClose={props.onClose}
    >
      {statuses.map(status => (
        <ChangeStatusButton
          key={status}
          id={`${idRoot}${
            status === 'More Info' ? 'more-info' : status.toLowerCase()
          }-button`}
          selected={props.status === status}
          status={status}
          onClick={props.onSave}
        />
      ))}
    </SlideoutLayout>
  )
}

export default QuoteStatusSlideout
