/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useEffect, useContext, useState} from 'react'
import moment from 'moment'
import {useMutation, useQuery} from '@apollo/client'
import {Semantic} from '@mhd/components-library'
import {useForm, FormProvider} from 'react-hook-form'

import Button from '@app/components/Button'
import EmailModal from '@app/components/Email/EmailModal'
import PdfPreview from '@app/components/Email/PdfPreview'
import DataField from '@app/components/Form/DataField'
import Input from '@app/components/Form/Input'
import TextArea from '@app/components/Form/TextArea'
import Footer from '@app/components/Layout/Footer'
import NavBarLayout from '@app/components/Layout/NavBarLayout'
import ActionDropdown from '@app/components/Nav/ActionButton'
import CustomerInfo from '@app/components/TransactionEdit/CustomerInfo'
import Items from '@app/components/TransactionEdit/Items'
import StatusButton from '@app/components/TransactionEdit/Status/StatusButton'
import Totals from '@app/components/TransactionEdit/Totals'

import {AuthenticationContext} from '@app/screens/App'
import {TransactionStatus} from '@app/helpers/constants'
import {NavContext} from '@app/context/NavContext'

import {useSaveDialog} from '@app/helpers/customHooks/dialog'
import useGraphOptions, {
  useMutationOptions,
} from '@app/helpers/customHooks/graphOptions'
import useModal from '@app/helpers/customHooks/useModal'
import useRootCustomer from '@app/helpers/customHooks/useRootCustomer'

import {Customer} from '@app/models/customerModels'
import {PriceListItem} from '@app/models/priceListItemModels'
import {Quote, QuoteInput} from '@app/models/quoteModels'
import {SalesTax} from '@app/models/salesTaxModels'

import {GET_QUOTES, SAVE_QUOTE} from '@app/graphql/quote.graphql'
import {GET_SALESTAXES} from '@app/graphql/salesTax.graphql'

import QuoteStatusSlideout from './QuoteStatusSlideout'
import Calculator from '@app/helpers/calculator'

export interface QuoteFormProps {
  createSuccess?: boolean
  leadId?: number
  customer?: Customer
  isCustomer?: boolean
  quote?: Quote
  pageName: string
  customerId?: number
}

type FormInputs = {
  issuedDate: string
  privateNotes: string
  customerNotes: string
}

const QuoteForm = ({leadId, ...props}: QuoteFormProps): JSX.Element => {
  const salesTaxList = useQuery(
    GET_SALESTAXES,
    useGraphOptions({
      offset: 0,
      limit: 500,
    }),
  )
  const authState = useContext(AuthenticationContext)
  const {goBack, goBackTo, toaster} = useContext(NavContext)

  const defaultValues = (q?: Quote): FormInputs => ({
    issuedDate: q?.issuedDate
      ? moment(q?.issuedDate).utc().format('YYYY-MM-DD')
      : moment(q?.createdDate).format('YYYY-MM-DD'),
    privateNotes: q?.privateNotes || '',
    customerNotes: q?.customerNotes || '',
  })

  const form = useForm<FormInputs>({
    defaultValues: defaultValues(props.quote),
  })
  const {isDirty} = form.formState

  const [quote, setQuote] = useState(props.quote ? {...props.quote} : undefined)
  const [items, setItems] = useState<PriceListItem[]>(quote?.items || [])
  const [discount, setDiscount] = useState(quote?.discount)
  const [discountAmount, setDiscountAmount] = useState(
    quote?.discountAmount || 0,
  )
  const [salesTax, setSalesTax] = useState(quote?.tax)
  const [salesTaxes, setSalesTaxes] = useState(
    (salesTaxList.data && salesTaxList.data.getSalesTaxes.salesTaxes) || [],
  )

  const [status, setStatus] = useState(
    (quote?.status || 'Draft') as TransactionStatus,
  )
  const [unsaved, setUnsaved] = useState(quote && !quote.id)
  const [customer, setCustomer] = useState(props.customer)

  const {rootCustomer} = useRootCustomer(
    props.customerId || customer?.customerId,
  )

  useEffect(() => {
    setCustomer(props.customer)
  }, [props.customer])
  const idRoot = 'bmt__quotes_add-edit-quote_quote-form_'

  const [saveQuote, {data: saveData, loading: saveLoading}] = useMutation(
    SAVE_QUOTE,
    {
      ...useMutationOptions({
        query: GET_QUOTES,
        queryName: 'getQuotes',
        listName: 'quotes',
        isNew: !quote?.id,
        mutationName: 'saveQuote',
        variables: {
          customerId: customer?.id,
        },
      }),
    },
  )

  useEffect(() => {
    if (props.createSuccess) {
      toaster.success('Customer Added!')
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    form.reset(defaultValues(props.quote))
    setItems(props.quote?.items || [])
    setQuote(props.quote)
    setDiscount(props.quote?.discount)
    setDiscountAmount(props.quote?.discountAmount || 0)
    setStatus(props.quote?.status as TransactionStatus)
    setSalesTax(props.quote?.tax)
  }, [props.quote]) // eslint-disable-line react-hooks/exhaustive-deps

  const mergeSalesTaxes = (salesTaxToSet: SalesTax[]): SalesTax[] => {
    const curTax = props.quote && props.quote.tax
    if (curTax) {
      salesTaxToSet = salesTaxToSet.filter(
        x => x.id !== curTax.id || x.value !== curTax.value,
      )
      salesTaxToSet.unshift(curTax)
    }
    return salesTaxToSet
  }

  useEffect(() => {
    const combinedSalesTaxes: SalesTax[] =
      (salesTaxList.data && salesTaxList.data.getSalesTaxes.salesTaxes) || []
    const filteredCombinedSalesTaxes = combinedSalesTaxes.filter(
      x => x.id !== '0',
    )
    if (props.quote && props.quote.tax && props.quote.tax.id === '0') {
      filteredCombinedSalesTaxes.unshift(props.quote.tax)
    }
    setSalesTaxes(mergeSalesTaxes(filteredCombinedSalesTaxes))
  }, [salesTaxList]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = async (formData: FormInputs): Promise<boolean> => {
    if (!customer) {
      return false
    }

    const input: QuoteInput = {
      id: quote?.id,
      number: quote?.number,
      createdDate: quote?.createdDate || new Date().toLocaleString(),
      customerNotes: formData.customerNotes,
      privateNotes: formData.privateNotes,
      issuedDate: formData.issuedDate
        ? moment.utc(moment(formData.issuedDate)).format()
        : undefined,
      customer: {
        id: customer.id,
        leadId: customer.leadId || leadId,
        alId: customer.alId,
        customerId: props.customerId || customer?.customerId,
        firstName: customer.firstName,
        lastName: customer.lastName,
        phone: customer.phone,
        email: customer.email,
        address:
          (customer.address && {
            lineOne: customer.address.lineOne,
            lineTwo: customer.address.lineTwo,
            city: customer.address.city,
            state: customer.address.state,
            zip: customer.address.zip,
            country: customer.address.country,
          }) ||
          {},
      },
      isCustomer: props.isCustomer,
      tax: salesTax,
      items,
      discount,
      discountAmount,
      status,
    }

    try {
      const result = await saveQuote({variables: {input}})
      if (!result.errors) {
        form.reset(defaultValues(result.data.saveQuote))
        setUnsaved(false)
        setQuote(result.data.saveQuote)
      } else {
        toaster.error('Error. Please Try Again.')
      }
      return !result.errors
    } catch (ex) {
      toaster.error('Error. Please Try Again.')
      return false
    }
  }

  const saveDialog = useSaveDialog({
    onSubmit: (save: boolean): Promise<boolean> => {
      if (save) {
        form.handleSubmit(async data => {
          const success = await handleSave(data)
          if (success) {
            if (
              authState.intent === 'quotes-new-customer' ||
              authState.intent === 'quotes-customers'
            ) {
              window.location.href = '/done'
            } else {
              goBack(() => {
                toaster.success('Quote Saved!')
              })
            }
          }
        })()
        // eslint-disable-next-line no-undef
        return new Promise<boolean>(resolve => resolve(true))
      }

      if (authState.intent === 'quotes-new-customer') {
        window.location.href = '/done'
      } else if (authState.intent === 'quotes-customers') {
        goBackTo('/customers')
      } else {
        goBack()
      }
      // eslint-disable-next-line no-undef
      return new Promise<boolean>(resolve => resolve(true))
    },
  })

  const handleBack = (): boolean => {
    if (isDirty || unsaved) {
      saveDialog.show()
      return false
    } else if (
      authState.intent === 'quotes-new-customer' ||
      authState.intent === 'quotes-customers'
    ) {
      window.location.href = '/done'
      return false
    } else {
      return true
    }
  }

  const handleUpdateItems = (
    updatedItems: PriceListItem[],
    type: 'added' | 'updated' | 'removed' | undefined = 'updated',
  ): void => {
    toaster.success(
      `Item ${
        type === 'added'
          ? 'Added!'
          : type === 'removed'
          ? 'Removed.'
          : 'Updated.'
      }`,
    )

    setUnsaved(true)
    setItems(updatedItems)

    const calc = new Calculator(updatedItems, salesTax, discount)

    setDiscountAmount(calc.discountAmount())
  }

  const handleUpdateSalesTaxes = (updatedSalesTaxes: SalesTax[]): void => {
    setUnsaved(true)
    setSalesTaxes(mergeSalesTaxes(updatedSalesTaxes))
  }

  const emailQuoteModal = useModal({
    children: (
      <EmailModal
        customer={customer}
        pdfName={saveData ? saveData.saveQuote.pdfName : ''}
        transaction={quote}
        type="Quote"
        onClose={(): void => emailQuoteModal.hide()}
      />
    ),
  })

  const handleEmailQuoteModal = async (): Promise<void> => {
    form.handleSubmit(async data => {
      const success = await handleSave(data)
      if (success) {
        emailQuoteModal.show()
      }
    })()
  }

  const pdfPreviewModal = useModal({
    children: (
      <PdfPreview
        quoteId={quote?.id}
        type="Quote"
        onClose={(): void => pdfPreviewModal.hide()}
      />
    ),
  })

  const handlePdfPreviewModal = async (): Promise<void> => {
    form.handleSubmit(async data => {
      const success = await handleSave(data)
      if (success) {
        pdfPreviewModal.show()
      }
    })()
  }

  const handleSaveButton = async (): Promise<void> => {
    form.handleSubmit(async data => {
      const success = await handleSave(data)
      if (success) {
        toaster.success('Quote Saved!')
      }
    })()
  }

  return (
    <NavBarLayout
      actionButton={
        <ActionDropdown>
          <Semantic.Dropdown.Menu>
            <Semantic.Dropdown.Item
              disabled={saveLoading}
              text="Save Quote"
              onClick={handleSaveButton}
            />
          </Semantic.Dropdown.Menu>
        </ActionDropdown>
      }
      footer={
        <Footer>
          <Button
            event={{
              description: 'user clicks preview button',
              activityLocation: props.pageName,
            }}
            fluid={false}
            id={`${idRoot}preview-button`}
            secondary
            onClick={handlePdfPreviewModal}
          >
            Preview
          </Button>
          <Button
            event={{
              description: 'user clicks the email quote button',
              activityLocation: props.pageName,
            }}
            id={`${idRoot}email-quote-button`}
            onClick={handleEmailQuoteModal}
          >
            Email Quote
          </Button>
        </Footer>
      }
      title={quote && quote.id ? 'Edit Quote' : 'Create a Quote'}
      onBack={handleBack}
    >
      {toaster.render()}
      <div className="section">
        <StatusButton
          slideoutComponent={QuoteStatusSlideout}
          status={status}
          onStatusChanged={(newStatus: TransactionStatus): void => {
            setStatus(newStatus)
            setUnsaved(true)
          }}
        />
        <CustomerInfo
          contact={customer}
          rootCustomer={rootCustomer}
          onUpdate={(updatedCustomer: Customer): void => {
            setCustomer(updatedCustomer)
            setUnsaved(true)
            toaster.success('Customer updated for this Quote.')
          }}
        />
      </div>
      <FormProvider {...form}>
        <div className="section grid cols-2">
          <DataField label="Quote #" value={quote ? quote.number : undefined} />
          <Input label="Issued Date" name="issuedDate" type="date" />
        </div>

        <div className="section">
          <Items
            items={items}
            pageName={props.pageName}
            onUpdateItems={handleUpdateItems}
          />
        </div>

        <Totals
          discount={discount}
          items={items}
          salesTax={salesTax}
          salesTaxes={salesTaxes}
          onDiscountUpdated={(a, d): void => {
            setDiscountAmount(a)
            setDiscount(d)
            setUnsaved(true)
          }}
          onSalesTaxesUpdated={handleUpdateSalesTaxes}
          onSalesTaxUpdated={(tax): void => {
            setSalesTax(tax)
            setUnsaved(true)
          }}
        />

        <div className="section">
          <TextArea label="Customer Notes" name="customerNotes" />

          <TextArea
            assistiveText="Private notes will not be shared with the customer."
            label="Private Notes"
            name="privateNotes"
          />
        </div>
      </FormProvider>

      {saveDialog.render}
      {emailQuoteModal.render}
      {pdfPreviewModal.render}
    </NavBarLayout>
  )
}

export default QuoteForm
