/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useState, useEffect} from 'react'
import {Semantic} from '@mhd/components-library'

import {equalFields} from '@app/helpers'
import useModal from '@app/helpers/customHooks/useModal'

import {Customer} from '@app/models/customerModels'

import EditCustomerModal from '@app/screens/Customers/EditCustomerModal'
import Loading from '@app/components/Loading'
import * as styles from './styles'

export interface CustomerProps {
  contact?: Customer
  rootCustomer?: Customer
  onUpdate: (customer: Customer) => void
}

const customerFields = ['firstName', 'lastName', 'email', 'phone', 'notes']
const addressFields = ['lineOne', 'lineTwo', 'city', 'state', 'zip']

const CustomerInfo = ({
  contact,
  rootCustomer,
  onUpdate,
}: CustomerProps): JSX.Element => {
  const [curContact, setCurContact] = useState(contact)
  useEffect(() => {
    setCurContact(contact)
  }, [contact])

  const editModal = useModal({
    children: curContact ? (
      <EditCustomerModal
        contact={curContact}
        rootCustomer={rootCustomer}
        onClose={(): void => editModal.hide()}
        onSubmit={(updatedCustomer: Customer): void => {
          if (
            !equalFields(updatedCustomer, curContact, customerFields) ||
            !equalFields(
              updatedCustomer.address,
              curContact.address,
              addressFields,
            )
          ) {
            onUpdate(updatedCustomer)
          }
          editModal.hide()
        }}
      />
    ) : null,
  })

  if (!curContact) return <Loading al />

  return (
    <div>
      <Semantic.Header as="h5" disabled>
        Customer
      </Semantic.Header>
      <Semantic.Header as="h2">
        {curContact?.firstName} {curContact?.lastName}
      </Semantic.Header>
      <div css={styles.customerInfo}>
        <div css={styles.customerAddress}>
          {curContact?.address?.lineOne ? (
            <span>{curContact?.address.lineOne}&nbsp;</span>
          ) : null}
          {curContact?.address?.lineTwo ? (
            <span>{curContact?.address.lineTwo}&nbsp;</span>
          ) : null}
          <span>
            {curContact?.address?.city
              ? `${curContact?.address?.city}, `
              : null}
            {curContact?.address?.state} {curContact?.address?.zip}
          </span>
        </div>

        <p>{curContact?.email}</p>

        {editModal.render}
      </div>
    </div>
  )
}

export default CustomerInfo
