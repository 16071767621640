/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useState, ChangeEvent, useContext} from 'react'
import {useHistory} from 'react-router'
import {useQuery} from '@apollo/client'
import {Semantic} from '@mhd/components-library'
import InfiniteScroll from 'react-infinite-scroller'
import Loading from '@app/components/Loading'

import SearchInput from '@app/components/Form/SearchInput'
import NavBarLayout from '@app/components/Layout/NavBarLayout'
import ListItem from '@app/components/List/ListItem'
import duplicateContact from '../../assets/images/duplicateContact.svg'

import {
  PageNames,
  DefaultPaginationOptions,
  PAGE_SIZE,
  ACTIVE_STATUS,
} from '@app/helpers/constants'
import useGraphOptions from '@app/helpers/customHooks/graphOptions'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'

import {AuthenticationContext} from '../App'

import {GET_CONTACTS} from '@app/graphql/customer.graphql'
import {Customer} from '@app/models/customerModels'

import * as styles from './styles'

const SelectCustomer = (): JSX.Element => {
  const idRoot = 'bmt__customers_select-customer_'
  const {intent} = useContext(AuthenticationContext)
  const {track} = useAnalytics(PageNames.selectCustomer)
  useAnalytics(PageNames.customerBusiness)

  const myCustomers =
    intent === 'my-customers' || intent === 'customer-payments'
  const history = useHistory()
  const [search, setSearch] = useState('')
  const paginationOptions = {
    ...DefaultPaginationOptions,
    recordStatus: [ACTIVE_STATUS],
  }
  const {data, loading, fetchMore, refetch} = useQuery(
    GET_CONTACTS,
    useGraphOptions(paginationOptions, {
      fetchPolicy: 'cache-and-network',
    }),
  )

  const handleBack = (): boolean => {
    window.location.href = '/done'
    return false
  }

  const handleNewCustomer = (): void => {
    track('Item Clicked', {
      description:
        'a user clicks the add new customer link to create a new customer',
      activityLocation: PageNames.selectCustomer,
    })
    history.push({pathname: '/customer/new', search: history.location.search})
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    track('Item Clicked', {
      description: 'a user clicks into the search field',
      activityLocation: PageNames.selectCustomer,
    })

    track('Item Clicked', {
      description: 'clicking into my business customer list search field',
      activityLocation: PageNames.customerBusiness,
    })

    setSearch(e.target.value)
    refetch({...DefaultPaginationOptions, filter: e.target.value})
  }

  const handleCustomerSelected = async (customer: Customer): Promise<void> => {
    track('Item Clicked', {
      description: 'a user clicks a customer name row',
      activityLocation: PageNames.selectCustomer,
    })

    track('Item Clicked', {
      description:
        'clicking on a customer name row from the my business customer list',
      activityLocation: PageNames.customerBusiness,
    })

    if (myCustomers) {
      history.push({
        pathname: `/customer/${customer.customerId}/contact/${customer.id}`,
        state: {
          tab: 'quotes',
        },
        search: history.location.search,
      })
    } else if (intent.includes('quote')) {
      history.push({
        pathname: '/quotes/new',
        state: {customer},
        search: history.location.search,
      })
    } else {
      history.push({
        pathname: '/invoices/new',
        state: {customer},
        search: history.location.search,
      })
    }
  }

  const customersData = data?.getContacts

  return (
    <NavBarLayout title="Select a Customer" onBack={handleBack}>
      <SearchInput
        label="Customer Name or Email"
        value={search}
        onChange={handleSearchChange}
      />

      <button
        className="link-button"
        css={{fontWeight: 'bold'}}
        id={`${idRoot}add-new-customer-button`}
        onClick={handleNewCustomer}
      >
        + Add a New Customer
      </button>

      {myCustomers && (
        <p css={styles.selectCustomerInfo}>
          Select a customer to view any quotes, invoices, and payments you have
          sent.
        </p>
      )}

      <div css={{marginTop: '2rem'}}>
        <div css={styles.subHeader}>
          <p css={styles.selectCustomerHeader}>CUSTOMERS</p>

          <p css={styles.duplicateContact}>
            <img
              alt="duplicateContact"
              css={styles.duplicateContactImg}
              src={duplicateContact}
            />
            = Duplicate Contact
          </p>
        </div>

        <InfiniteScroll
          hasMore={
            !loading &&
            (!data ||
              !customersData ||
              customersData?.contacts?.length < customersData?.totalRows)
          }
          initialLoad={false}
          loader={<Loading key={0} al />}
          loadMore={(): void => {
            fetchMore({
              variables: {
                offset: customersData?.contacts?.length || PAGE_SIZE,
              },
              updateQuery: (prev, {fetchMoreResult}) => {
                if (!fetchMoreResult) return prev
                return {
                  ...prev,
                  getContacts: {
                    ...fetchMoreResult.getContacts,
                    contacts: [
                      ...prev.getContacts.contacts,
                      ...fetchMoreResult.getContacts.contacts,
                    ],
                  },
                }
              },
            })
          }}
          pageStart={0}
        >
          {data && customersData ? (
            customersData.contacts?.map((c: Customer) => (
              <ListItem
                key={c.id}
                css={{padding: '1rem 2rem'}}
                showCaret
                onClick={(): Promise<void> => handleCustomerSelected(c)}
              >
                <Semantic.Header as="h5">
                  {c.firstName} {c.lastName}{' '}
                  {c.default ? null : (
                    <img
                      alt="duplicateContact"
                      css={{width: '17px !important'}}
                      src={duplicateContact}
                    />
                  )}
                </Semantic.Header>
                <p css={{fontSize: '1.4rem', marginBottom: 0}}>{c.email}</p>
              </ListItem>
            ))
          ) : (
            <Loading al />
          )}
        </InfiniteScroll>
      </div>
    </NavBarLayout>
  )
}

export default SelectCustomer
