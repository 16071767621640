import {css} from '@emotion/core'
import {Semantic} from '@mhd/components-library'

import {textMedium, darkOrange} from '@app/styles/colors'

export const statusLabel = css({
  borderRadius: '2rem !important',
  padding: '4px 10px !important',
  fontSize: '1.2rem !important',
  marginLeft: 'auto !important',
  height: '22px',

  '&.orange': {
    color: `${darkOrange} !important`,
    borderColor: `${darkOrange} !important`,
  },
})

export const statusButton = css({
  position: 'absolute',
  right: '2rem',
})

export const changeStatusButton = css`
  box-shadow: none !important;
  border-style: solid !important;
  border-width: 1px !important;
  font-weight: normal !important;
  margin: 0 !important;

  &:active: {
    background-color: white !important;
    color: ${textMedium} !important;
  }
  &:not(:last-of-type): {
    marginbottom: 2rem !important;
  }
`

export const changeStatusButtonSelected = css`
  border-width: 2px !important;
  font-weight: bold !important;

  &.orange {
    color: ${darkOrange} !important;
    border-color: ${darkOrange} !important;
  }
`

export const statusColor = (status: string): Semantic.SemanticCOLORS => {
  switch (status) {
    case 'Paid':
    case 'Approved':
      return 'green'
    case 'Overdue':
    case 'Declined':
      return 'orange'
    default:
      return 'grey'
  }
}
