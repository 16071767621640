/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useMutation} from '@apollo/client'

import {PageNames} from '@app/helpers/constants'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'
import {Customer} from '@app/models/customerModels'
import CustomerForm from './CustomerForm'
import useRootCustomer from '@app/helpers/customHooks/useRootCustomer'
import {SAVE_CUSTOMER, GET_CUSTOMER} from '@app/graphql/customer.graphql'
import useGraphOptions, {
  useMutationOptions,
} from '@app/helpers/customHooks/graphOptions'

export interface EditCustomerModalProps {
  contact: Customer
  rootCustomer?: Customer
  onSubmit: (customer: Customer) => void
  onClose: () => void
  autoSave?: boolean
  isCustomerDetails?: boolean
}

const EditCustomerModal = (props: EditCustomerModalProps): JSX.Element => {
  const pageName = !props.contact ? PageNames.addCustomer : ''
  const {track} = useAnalytics(pageName)

  const [saveCustomer, {loading}] = useMutation(
    SAVE_CUSTOMER,
    useMutationOptions(),
  )

  const {rootCustomer} = useRootCustomer(
    props.rootCustomer?.id || props.contact?.customerId,
  )

  const handleClose = (): void => {
    props.onClose()
  }

  const graphOptions = useGraphOptions({}, {})
  const handleSave = async (customer: Customer): Promise<void> => {
    if (!props.autoSave) {
      props.onSubmit(customer)
      props.onClose()
      return
    }
    const result = await saveCustomer({
      variables: {input: customer},
      update: (cache, {data: {saveCustomer}}) => {
        cache.writeQuery({
          query: GET_CUSTOMER,
          data: {
            getCustomer: {
              ...saveCustomer,
              fullAddress: null,
              default: null,
            },
          },
          variables: {
            ...graphOptions.variables,
            customerId: customer.id,
          },
        })
      },
    })
    if (!result.errors) {
      const savedCustomer = result.data.saveCustomer
      track('Customer Saved', {
        customerId: savedCustomer?.id,
      })
      props.onSubmit(savedCustomer)
      props.onClose()
    }
  }

  return (
    <CustomerForm
      contact={props.contact}
      isCustomerDetails={props.isCustomerDetails}
      loading={loading}
      rootCustomer={rootCustomer}
      isModal
      onClose={handleClose}
      onSave={handleSave}
    />
  )
}

export default EditCustomerModal
