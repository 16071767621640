/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Semantic} from '@mhd/components-library'
import {useEffect, useState} from 'react'

import {PERCENT_TYPE} from '@app/helpers/constants'
import {formatCurrency} from '@app/helpers'
import Calculator from '@app/helpers/calculator'
import useModal from '@app/helpers/customHooks/useModal'
import {Discount} from '@app/models/transactionModels'

import DiscountModal from './DiscountModal'
import SalesTaxModal from './SalesTaxModal'

import * as styles from './styles'
import {heart, textMedium} from '@app/styles/colors'
import {PriceListItem} from '@app/models/priceListItemModels'
import {SalesTax} from '@app/models/salesTaxModels'

export interface TotalsProps {
  items: PriceListItem[]
  salesTaxes?: SalesTax[]
  salesTax?: SalesTax
  discount?: Discount
  showBalanceDue?: boolean
  amountPaid?: number
  onDiscountUpdated: (discountAmount: number, discount?: Discount) => void
  onSalesTaxUpdated?: (salesTax?: SalesTax) => void
  onSalesTaxesUpdated?: (salesTaxes: SalesTax[]) => void
}

const Totals = (props: TotalsProps): JSX.Element => {
  const idRoot = 'bmt__transaction-edit_totals_'
  const [salesTaxAction, setSalesTaxAction] = useState('new')

  const calc = new Calculator(props.items, props.salesTax, props.discount)
  const subtotal = calc.subtotal()
  const taxAmount = calc.taxAmount()
  const discountAmount = calc.discountAmount()
  const total = calc.total()

  const discountModal = useModal({
    slideout: true,
    children: (
      <DiscountModal
        discount={props.discount}
        maxDiscount={subtotal + taxAmount}
        total={subtotal}
        onClose={(): void => discountModal.hide()}
        onRemove={(): void => {
          props.onDiscountUpdated(0, undefined)
          discountModal.hide()
        }}
        onSave={(newDiscount: Discount): void => {
          const updatedCalc = new Calculator(
            props.items,
            props.salesTax,
            newDiscount,
          )

          props.onDiscountUpdated(updatedCalc.discountAmount(), newDiscount)
          discountModal.hide()
        }}
      />
    ),
  })

  const discountField = (
    <p css={{margin: '5px 0'}}>
      Discount (
      <button
        className="link-button"
        id={`${idRoot}${props.discount ? 'edit' : 'add'}-discount-button`}
        onClick={(): void => discountModal.show()}
      >
        {props.discount ? 'Edit' : 'Add'}
      </button>
      )
    </p>
  )

  const discountValue = (
    <Semantic.Header as="h4" textAlign="right">
      {props.discount ? (
        props.discount.type === PERCENT_TYPE ? (
          <span>
            -{props.discount.amount}%&nbsp;
            <span style={{fontWeight: 300, color: textMedium}}>
              ({formatCurrency(discountAmount)})
            </span>
          </span>
        ) : (
          `-${formatCurrency(props.discount.amount)}`
        )
      ) : (
        '-'
      )}
    </Semantic.Header>
  )

  const salesTaxModal = useModal({
    slideout: true,
    children: (
      <SalesTaxModal
        salesTax={salesTaxAction === 'edit' ? props.salesTax : undefined}
        onClose={(): void => salesTaxModal.hide()}
        onSave={(newSalesTax: SalesTax): void => {
          if (props.onSalesTaxesUpdated && props.salesTaxes) {
            const filteredSalesTaxes = props.salesTaxes.filter(
              x => x.id !== newSalesTax.id,
            )
            props.onSalesTaxesUpdated([newSalesTax, ...filteredSalesTaxes])
          }
          if (props.onSalesTaxUpdated) {
            props.onSalesTaxUpdated(newSalesTax)
          }
          salesTaxModal.hide()
        }}
      />
    ),
  })

  const combinationId = (tax: SalesTax): string => `${tax.id}-${tax.value}`

  const [taxDropdownOptions, setTaxDropdownOptions] = useState<
    Semantic.DropdownItemProps[]
  >([])

  useEffect(() => {
    const updatedTaxOptions: Semantic.DropdownItemProps[] = [
      {
        key: 'new',
        text: (
          <button
            className="link-button"
            id={`${idRoot}add-new-tax-link`}
            onClick={(): void => {
              setSalesTaxAction('new')
              salesTaxModal.show()
            }}
          >
            + Add New Tax
          </button>
        ),
        value: 'new',
      },
      {
        key: 'none',
        text: '[None]',
        value: 'none',
      },
    ]

    if (props.salesTaxes && props.salesTaxes.length > 0) {
      if (
        props.salesTax &&
        props.salesTax.id !== '0' &&
        props.salesTaxes.filter(
          x => combinationId(x) === combinationId(props.salesTax as SalesTax),
        ).length === 0
      ) {
        updatedTaxOptions.push({
          key: combinationId(props.salesTax),
          text: `${props.salesTax.name} (${(props.salesTax.value * 100).toFixed(
            2,
          )}%)`,
          value: combinationId(props.salesTax),
        })
      }

      props.salesTaxes.forEach(tax => {
        updatedTaxOptions.push({
          key: combinationId(tax),
          text:
            tax.id === '0'
              ? `${tax.value * 100}%`
              : `${tax.name} (${(tax.value * 100).toFixed(2)}%)`,
          value: combinationId(tax),
        })
      })
    }

    setTaxDropdownOptions(updatedTaxOptions) // eslint-disable-next-line
  }, [props.salesTaxes, props.salesTax])

  const taxText = taxDropdownOptions.filter(
    x => x.key === (props.salesTax ? combinationId(props.salesTax) : 'none'),
  )

  return (
    <div className="section fullWidth" css={styles.totals}>
      <div css={styles.totalsGrid}>
        <Semantic.Header as="h5" disabled>
          Subtotal
        </Semantic.Header>

        <Semantic.Header
          as="h5"
          subheader={formatCurrency(subtotal)}
          textAlign="right"
        />

        {props.discount && !props.discount.postTax && discountField}
        {props.discount && !props.discount.postTax && discountValue}

        <div />
      </div>
      <div css={styles.taxRateCss}>
        <Semantic.Dropdown
          className="selection"
          id={`${idRoot}tax-rate-field`}
          label="Tax Rate"
          text={
            taxDropdownOptions.length > 0
              ? taxText && taxText.length > 0
                ? (taxText[0].text as string)
                : ''
              : ''
          }
          fluid
          scrolling
        >
          <Semantic.Dropdown.Menu>
            {taxDropdownOptions.map(x => {
              const active =
                x.key ===
                (props.salesTax ? combinationId(props.salesTax) : 'none')

              return (
                <Semantic.Dropdown.Item
                  key={x.key}
                  active={active}
                  css={active ? {display: 'none !important'} : {}}
                  text={x.text}
                  value={x.value}
                  onClick={(e, {value}): void => {
                    if (
                      props.onSalesTaxUpdated &&
                      props.salesTaxes &&
                      value !== 'new'
                    ) {
                      props.onSalesTaxUpdated(
                        props.salesTaxes.find(
                          tax => combinationId(tax) === value,
                        ),
                      )
                    }
                  }}
                />
              )
            })}
          </Semantic.Dropdown.Menu>
        </Semantic.Dropdown>
        <label css={styles.label}>Tax Rate</label>
      </div>
      <div css={styles.totalsGrid}>
        {props.salesTax && props.salesTax.id !== '0' ? (
          <p css={{margin: '5px 0'}}>
            Tax (
            <button
              className="link-button"
              id={`${idRoot}edit-tax-link`}
              onClick={(): void => {
                setSalesTaxAction('edit')
                salesTaxModal.show()
              }}
            >
              Edit
            </button>
            )
          </p>
        ) : (
          <p css={{margin: '5px 0'}}>Tax</p>
        )}

        <Semantic.Header as="h4" textAlign="right">
          {formatCurrency(taxAmount)}
        </Semantic.Header>

        {(!props.discount || props.discount.postTax) && discountField}
        {(!props.discount || props.discount.postTax) && discountValue}

        <Semantic.Header as={!props.showBalanceDue ? 'h1' : 'h2'}>
          Total
        </Semantic.Header>
        <Semantic.Header
          as={!props.showBalanceDue ? 'h1' : 'h2'}
          textAlign="right"
        >
          {formatCurrency(total)}
        </Semantic.Header>
      </div>

      {props.showBalanceDue && (
        <div>
          <hr />

          <div css={styles.totalsGrid}>
            <Semantic.Header as="h1">Balance Due</Semantic.Header>
            <Semantic.Header
              as="h1"
              color="green"
              css={{color: `${heart} !important`}}
              textAlign="right"
            >
              {formatCurrency(total - (props.amountPaid || 0))}
            </Semantic.Header>
          </div>
        </div>
      )}

      {discountModal.render}
      {salesTaxModal.render}
    </div>
  )
}

export default Totals
