/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Semantic} from '@mhd/components-library'

import ListItem from '@app/components/List/ListItem'
import {TransactionItemComponentProps} from '@app/components/TransactionList'

import {formatDateTime, formatCurrency} from '@app/helpers'

import * as styles from '@app/components/TransactionList/styles.ts'
import {MinimalPayment} from '@app/models/paymentModel'

const PaymentListItem = ({
  transaction,
}: TransactionItemComponentProps): JSX.Element => {
  const payment: MinimalPayment = transaction as MinimalPayment

  return (
    <ListItem alternating={false} css={styles.transactionListItem}>
      <Semantic.Header
        css={styles.row}
        size="tiny"
        subheader={formatDateTime(payment.paymentDate)}
      />

      <div>
        <Semantic.Header as="h3">
          Collected {formatCurrency(payment.amount)}
        </Semantic.Header>
      </div>
      <div />

      <Semantic.Header
        css={[styles.row, styles.status]}
        size="tiny"
        subheader={
          payment.source.invoiceNumber
            ? `Applied to Invoice #${payment.source.invoiceNumber}`
            : undefined
        }
      />
    </ListItem>
  )
}

export default PaymentListItem
