import {css} from '@emotion/core'
import {
  secondaryDark,
  bgGreyMedium,
  textMedium,
  textLight,
  danger,
} from '@app/styles/colors'

export const navbar = css({
  display: 'grid',
  gridTemplateColumns: '50px auto 50px',
  gridTemplateRows: '60px',
  height: 60,
  lineHeight: 1,
  marginBottom: 20,

  '.header': {
    marginTop: 0,
  },

  '& > *': {
    alignSelf: 'end',
  },
})

export const navIcon = css({
  color: secondaryDark,
  cursor: 'pointer',
  fontSize: '2rem !important',
  marginBottom: '3px !important',
})

export const navTrash = css({
  color: danger,
})

export const navHeader = css({
  marginBottom: '0 !important',
  fontSize: '16px',
  fontWeight: 700,
})

export const navSubtitle = css({
  fontSize: '1.4rem !important',
  color: `${textLight} !important`,
  marginTop: '0.2rem !important',
})

export const navBarRight = css({
  textAlign: 'right',
  marginBottom: 3,
})

export const actionDropdown = css`
  padding: 5px 4px !important;
  text-align: center;
  background-color: white !important;
  color: ${textMedium} !important;

  &.active {
    background-color: ${bgGreyMedium} !important;
  }

  & i.icon {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
`
