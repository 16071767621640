import gql from 'graphql-tag'
import {CUSTOMER, TRANSACTION_ITEM} from './transaction.graphql'

const QUOTE = gql`
  ${TRANSACTION_ITEM}
  ${CUSTOMER}
  fragment Quote on QuoteResponse {
    id
    number
    createdDate
    issuedDate
    customerNotes
    privateNotes
    pdfName
    status
    tax {
      id
      name
      value
    }
    discountAmount
    nonDiscountedTotal
    total
    discount {
      amount
      type
      postTax
    }
    items {
      ...TransactionItem
    }
    customer {
      ...Customer
    }
  }
`

export const GET_QUOTE = gql`
  ${QUOTE}
  query GetQuote($portalId: ID!, $quoteId: ID!) {
    getQuote(portalId: $portalId, quoteId: $quoteId) {
      ...Quote
    }
  }
`

export const GET_QUOTES = gql`
  query GetQuotes(
    $portalId: ID!
    $offset: Int!
    $limit: Int!
    $leadId: ID
    $customerId: ID
  ) {
    getQuotes(
      portalId: $portalId
      offset: $offset
      limit: $limit
      leadId: $leadId
      customerId: $customerId
    ) {
      quotes {
        id
        number
        createdDate
        updatedDate
        total
        sentDate
        status
      }
      offset
      limit
      totalRows
    }
  }
`

export const GET_NEXT_QUOTE_NUMBER = gql`
  query GetNextQuoteNumber($portalId: ID!) {
    getNextQuoteNumber(portalId: $portalId) {
      number
    }
  }
`

export const PREVIEW_QUOTE = gql`
  mutation PreviewQuote(
    $portalId: ID!
    $alUrl: String!
    $input: SendPreviewInput!
  ) {
    previewQuote(portalId: $portalId, alUrl: $alUrl, input: $input) {
      html
    }
  }
`

export const SAVE_QUOTE = gql`
  ${QUOTE}
  mutation SaveQuote($portalId: ID!, $input: QuoteInput!) {
    saveQuote(portalId: $portalId, input: $input) {
      ...Quote
    }
  }
`

export const SEND_QUOTE = gql`
  mutation SendQuote(
    $portalId: ID!
    $alUrl: String!
    $input: SendPreviewInput!
  ) {
    sendQuote(portalId: $portalId, alUrl: $alUrl, input: $input) {
      sent
    }
  }
`
