/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useMutation} from '@apollo/client'
import React, {useEffect, useState} from 'react'

import {TransactionStatus} from '@app/helpers/constants'
import {useDialog} from '@app/helpers/customHooks/dialog'
import {CHANGE_STATUS} from '@app/graphql/invoice.graphql'
import SlideoutLayout from '@app/components/Layout/Slideout'
import {useMutationOptions} from '@app/helpers/customHooks/graphOptions'
import {StatusSlideoutProps} from '@app/components/TransactionEdit/Status/StatusButton'
import ChangeStatusButton from '@app/components/TransactionEdit/Status/ChangeStatusButton'

const InvoiceStatusSlideout = (props: StatusSlideoutProps): JSX.Element => {
  const [newSelectedStatus, setNewSelectedStatus] = useState('')
  const [changeStatus, {data, loading}] = useMutation(
    CHANGE_STATUS,
    useMutationOptions(),
  )

  const warningDialog = useDialog({
    title: 'Status Change',
    content: (
      <React.Fragment>
        <p>This invoice can not be changed to a Void status.</p>

        <p>
          Please refund the payment through Square. Once completed, you can
          change the status to Void.
        </p>
      </React.Fragment>
    ),
    primaryText: 'Ok',
  })

  useEffect(() => {
    if (!loading && data) {
      setNewSelectedStatus('')

      if (data.changeStatus.code || data.changeStatus.details) {
        if (data.changeStatus.code === 9) {
          warningDialog.show()
        }
      } else {
        props.onSave(data.changeStatus?.status)
      }
    }
  }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeStatus = (newStatus: TransactionStatus): void => {
    setNewSelectedStatus(newStatus)
    changeStatus({variables: {invoiceId: props.invoiceId, newStatus}})
  }

  const idRoot = 'bmt__invoices_add-edit-invoice_invoice-status-slideout_'
  const statuses: TransactionStatus[] = [
    'Draft',
    'Sent',
    'Partially Paid',
    'Paid',
    'Void',
    'Overdue',
  ]

  return (
    <React.Fragment>
      {warningDialog.render}

      <SlideoutLayout
        closeButtonId={`${idRoot}close-button`}
        title="Change Status"
        onClose={props.onClose}
      >
        {statuses.map(x => (
          <ChangeStatusButton
            key={x}
            disabled={
              loading ||
              (!!props.invoiceId &&
                props.status === 'Paid' &&
                x === 'Partially Paid')
            }
            id={`${idRoot}${
              x === 'Partially Paid' ? 'partially-paid' : x.toLowerCase()
            }-button`}
            invoiceId={props.invoiceId}
            loading={newSelectedStatus === x}
            selected={props.status === x}
            status={x}
            onClick={(): void =>
              props.invoiceId ? handleChangeStatus(x) : props.onSave(x)
            }
          />
        ))}
      </SlideoutLayout>
    </React.Fragment>
  )
}

export default InvoiceStatusSlideout
