/** @jsx jsx */
import {jsx} from '@emotion/core'

import {TransactionStatus, PageNames} from '@app/helpers/constants'
import useModal from '@app/helpers/customHooks/useModal'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'

import StatusLabel from './StatusLabel'

export interface StatusSlideoutProps {
  invoiceId?: number
  status: TransactionStatus
  onClose: () => void
  onSave: (status: TransactionStatus) => void
}

export interface StatusButtonProps {
  invoiceId?: number
  status: TransactionStatus
  slideoutComponent: React.StatelessComponent<StatusSlideoutProps>
  onStatusChanged: (status: TransactionStatus) => void
}

const StatusButton = (props: StatusButtonProps): JSX.Element => {
  const SlideoutComponent = props.slideoutComponent
  const {track} = useAnalytics()
  const statusModal = useModal({
    slideout: true,
    children: (
      <SlideoutComponent
        invoiceId={props.invoiceId}
        status={props.status}
        onClose={(): void => statusModal.hide()}
        onSave={(newStatus: TransactionStatus): void => {
          statusModal.hide()
          props.onStatusChanged(newStatus)
        }}
      />
    ),
  })

  return (
    <div>
      <StatusLabel
        status={props.status}
        onClick={(): void => {
          statusModal.show()
          track('Item Clicked', {
            description: `user taps on the status menu button from ${
              props.invoiceId ? 'an inovice' : 'a quote'
            } page.`,
            activityLocation: props.invoiceId
              ? PageNames.createInvoice
              : PageNames.createQuote,
          })
        }}
      />
      {statusModal.render}
    </div>
  )
}
export default StatusButton
