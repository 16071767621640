/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Semantic} from '@mhd/components-library'

import ListItem from '@app/components/List/ListItem'

import {formatCurrency} from '@app/helpers'

import {PriceListItem} from '@app/models/priceListItemModels'
import {textMedium, text} from '@app/styles/colors'

export interface PriceListItemProps {
  item: PriceListItem
  onItemSelected: (item: PriceListItem) => void
}

const PriceListItemRow = ({
  item,
  onItemSelected,
}: PriceListItemProps): JSX.Element => (
  <ListItem key={item.id} css={{padding: '1.0rem 2.0rem'}}>
    <div css={{display: 'flex'}}>
      <div css={{flexGrow: 1, overflowX: 'hidden'}}>
        {item.category && (
          <p
            css={{
              marginBottom: 0,
              fontSize: '12px',
              lineHeight: '14px',
              color: text,
            }}
          >
            {item.category}
          </p>
        )}
        <Semantic.Header as="h4" css={{margin: '0 !important'}}>
          <button
            className="link-button"
            css={{textAlign: 'left'}}
            onClick={(): void => onItemSelected(item)}
          >
            {item.name}{' '}
          </button>
        </Semantic.Header>
        <p css={{fontSize: '1.2rem', marginBottom: 0, color: textMedium}}>
          {item.description && item.description.length > 31
            ? `${item.description?.substr(0, 31)}...`
            : item.description}
          &nbsp;
        </p>
      </div>
      <div css={{maxWidth: '80%'}}>
        <Semantic.Header
          as="h4"
          css={{margin: '0 !important', textAlign: 'right'}}
        >
          {formatCurrency(
            item.product ? item.product.price : item.service?.rate || 0,
          )}
        </Semantic.Header>
        <p
          css={{
            fontSize: '1.2rem',
            marginBottom: 0,
            color: textMedium,
            textAlign: 'right',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {item.sku}
        </p>
      </div>
    </div>
  </ListItem>
)

export default PriceListItemRow
