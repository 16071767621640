/** @jsx jsx */
import {jsx, SerializedStyles} from '@emotion/core'
import {ReactNode} from 'react'
import {Semantic} from '@mhd/components-library'

import arrowLeft from '../../assets/images/arrow_left.png'
import * as styles from './styles'

const {Header} = Semantic

export interface NavBarProps {
  title: string | ReactNode
  subtitle?: string
  hideBack?: boolean
  actionButton?: JSX.Element
  onBack?: () => boolean
  headerStyles?: SerializedStyles
}

const NavBar = (props: NavBarProps): JSX.Element => {
  return (
    <div css={styles.navbar}>
      {!props.hideBack ? (
        <img
          alt=""
          css={styles.navIcon}
          data-testid="nav-back"
          id="bmt__nav_navbar_back-button"
          src={arrowLeft}
          style={{width: 24, height: 24}}
          onClick={props.onBack}
        />
      ) : (
        <div />
      )}

      <Header
        as="h4"
        className="navbar-header"
        css={[styles.navHeader, props.headerStyles]}
        textAlign="center"
      >
        {props.title}
        {props.subtitle && (
          <Header.Subheader css={styles.navSubtitle}>
            {props.subtitle}
          </Header.Subheader>
        )}
      </Header>

      <div css={styles.navBarRight}>{props.actionButton}</div>
    </div>
  )
}

NavBar.defaultProps = {
  hideBack: false,
}

export default NavBar
