/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useState} from 'react'
import {Semantic} from '@mhd/components-library'

import Button from '@app/components/Button'

import useAnalytics from '@app/helpers/customHooks/useAnalytics'
import useModal from '@app/helpers/customHooks/useModal'

import {PriceListItem} from '@app/models/priceListItemModels'

import PriceListItemModal from './PriceListItemModal'
import SelectItemModal from './SelectItemModal'
import TransactionItem from './TransactionItem'

const {Header, Divider} = Semantic

export interface ItemsProps {
  items: PriceListItem[]
  pageName: string
  onUpdateItems: (
    items: PriceListItem[],
    type?: 'added' | 'updated' | 'removed',
  ) => void
}

const Items = (props: ItemsProps): JSX.Element => {
  const {track} = useAnalytics()

  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(-1)
  const [addItemScrollable, setAddItemScrollable] = useState(true)

  const hideModal = (): void => {
    addItemModal.hide() // eslint-disable-line @typescript-eslint/no-use-before-define
    editItemModal.hide() // eslint-disable-line @typescript-eslint/no-use-before-define
    setAddItemScrollable(true)
  }

  const addItemModal = useModal({
    scrollable: addItemScrollable,
    children: (
      <SelectItemModal
        onAddItem={(item: PriceListItem): void => {
          props.onUpdateItems([...props.items, item], 'added')
        }}
        onClose={(): void => hideModal()}
        onScrollableChange={setAddItemScrollable}
      />
    ),
  })

  const editItemModal = useModal({
    children: (
      <PriceListItemModal
        item={props.items[selectedItemIndex]}
        onClose={(): void => hideModal()}
        onRemove={(): void => {
          if (selectedItemIndex !== -1) {
            const itemsCopy = [...props.items]
            itemsCopy.splice(selectedItemIndex, 1)
            props.onUpdateItems(itemsCopy, 'removed')
          }
          hideModal()
        }}
        onSubmit={async (item: PriceListItem): Promise<string | undefined> => {
          if (selectedItemIndex !== -1) {
            const itemsCopy = [...props.items]
            itemsCopy[selectedItemIndex] = item
            props.onUpdateItems(itemsCopy)
            setSelectedItemIndex(-1)
          } else {
            props.onUpdateItems([...props.items, item])
          }
          hideModal()
          return undefined
        }}
      />
    ),
  })

  const handleAddItemClick = (): void => {
    track('Item Clicked', {
      description: 'user clicks add item button',
      activityLocation: props.pageName,
    })
    setSelectedItemIndex(-1)
    addItemModal.show()
  }

  const handleOpenItemModal = (index: number): void => {
    setSelectedItemIndex(index)
    editItemModal.show()
  }

  return (
    <div className="section">
      <Header as="h5" disabled>
        Items
      </Header>

      <Header as="h6" disabled>
        <Header.Subheader
          css={{
            display: 'flex !important',
            justifyContent: 'space-between',
          }}
        >
          <span>Category/Name</span>
          <span>Amount</span>
        </Header.Subheader>
      </Header>

      <Divider fitted />

      {props.items &&
        props.items.map((x, i) => (
          <TransactionItem
            key={i}
            item={x}
            onClick={(): void => {
              handleOpenItemModal(i)
            }}
          />
        ))}

      <Button
        css={{marginTop: '2.0rem !important'}}
        id="bmt__transaction-edit_items_index_add-item-button"
        secondary
        onClick={handleAddItemClick}
      >
        Add Item
      </Button>

      {addItemModal.render}
      {editItemModal.render}
    </div>
  )
}

export default Items
