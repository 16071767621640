/** @jsx jsx */
import {jsx} from '@emotion/core'
import {ReactNode} from 'react'
import {Semantic} from '@mhd/components-library'

import Button from '../Button'

const {Modal} = Semantic

export interface DialogProps {
  title: string
  children?: ReactNode
  primaryText: string
  secondaryText?: string
  destructive?: boolean
  onPrimaryClick: () => void
  onSecondaryClick: () => void
}

const Dialog = (props: DialogProps & Semantic.ModalProps): JSX.Element => {
  const {
    title,
    children,
    primaryText,
    secondaryText,
    onPrimaryClick,
    onSecondaryClick,
    destructive,
    ...otherProps
  } = props

  return (
    <Modal
      closeOnDimmerClick={false}
      closeOnEscape={false}
      size="tiny"
      open
      {...otherProps}
    >
      <Semantic.Header as="h4" textAlign="center">
        {title}
      </Semantic.Header>
      <Modal.Content>
        <p css={{textAlign: 'center'}}>{children}</p>
      </Modal.Content>
      <Modal.Actions>
        {secondaryText && (
          <Button
            content={secondaryText}
            basic
            secondary
            onClick={(): void => onSecondaryClick()}
          />
        )}
        <Button
          color={destructive ? 'red' : undefined}
          content={primaryText}
          onClick={(): void => onPrimaryClick()}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default Dialog
