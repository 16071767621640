import {useEffect, useContext, useState} from 'react'
import {useLazyQuery} from '@apollo/client'
import {useHistory} from 'react-router'

import useGraphOptions from '@app/helpers/customHooks/graphOptions'

import {AuthenticationContext} from '@app/screens/App'

import {GET_CUSTOMER} from '@app/graphql/customer.graphql'
import {GET_LEAD} from '@app/graphql/lead.graphql'

import {Customer} from '@app/models/customerModels'

export interface CustomerRouteProps {
  customer?: Customer
  isCustomer?: boolean
  customerId?: number
  contactId?: number
}

interface UseCustomerInterface {
  customer?: Customer
  isCustomer: boolean
  setCustomer: (customer: Customer) => void
  leadId?: number
}

const useCustomer = (
  forceLoad?: boolean,
  forceCustomerId?: number,
): UseCustomerInterface => {
  const history = useHistory<CustomerRouteProps>()
  const routerCustomer = history.location.state?.customer
  const isCustomer = history.location.state?.isCustomer || false

  const [customer, setCustomer] = useState(routerCustomer)

  const authState = useContext(AuthenticationContext)
  const graphOptions = useGraphOptions({}, {})

  // load customer if not in state and is in query string params
  const [getCustomer, {data: customerData}] = useLazyQuery(GET_CUSTOMER, {
    fetchPolicy: 'cache-and-network',
  })
  useEffect(() => {
    if (
      (forceLoad && forceCustomerId) ||
      (!routerCustomer && authState.customerId && !customer)
    ) {
      getCustomer({
        variables: {
          ...graphOptions.variables,
          customerId:
            forceLoad && forceCustomerId
              ? forceCustomerId
              : authState.customerId,
        },
      })
    }
  }, [routerCustomer, authState.customerId]) // eslint-disable-line

  const loadedCustomer = customerData?.getCustomer
  useEffect((): void => {
    if (loadedCustomer) setCustomer(loadedCustomer)
  }, [loadedCustomer])

  // load lead name/address info if no customer provided
  const [getLead, {data: leadData}] = useLazyQuery(
    GET_LEAD,
    useGraphOptions({}, {}, true),
  )
  useEffect(() => {
    if (
      !customer &&
      !routerCustomer &&
      !authState.customerId &&
      authState.alLeadId
    ) {
      getLead({
        ...graphOptions,
        variables: {...graphOptions.variables, leadId: authState.alLeadId},
      })
    }
  }, [routerCustomer, authState.alLeadId]) // eslint-disable-line

  const loadedLead = leadData?.getLead
  useEffect((): void => {
    if (loadedLead)
      setCustomer({...loadedLead, leadId: loadedLead.id, id: undefined})
  }, [loadedLead])

  return {
    customer,
    isCustomer,
    setCustomer,
    leadId: customer?.leadId || authState.alLeadId,
  }
}

export default useCustomer
