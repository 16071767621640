/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Semantic} from '@mhd/components-library'

import ListItem from '@app/components/List/ListItem'

import {transactionStatus, formatDateTime, formatCurrency} from '@app/helpers'

import * as styles from '@app/components/TransactionList/styles.ts'

import {TransactionItemComponentProps} from '.'
import StatusLabel from '../TransactionEdit/Status/StatusLabel'
import Calculator from '@app/helpers/calculator'

export interface TransactionListItemProps {
  type: string
  selected?: boolean
  onClick?: () => void
}

const TransactionListItem = (
  props: TransactionItemComponentProps & TransactionListItemProps,
): JSX.Element => {
  const {transaction} = props
  return (
    <ListItem
      css={styles.transactionListItem}
      selected={props.selected}
      onClick={props.onClick}
    >
      <Semantic.Header
        size="tiny"
        subheader={formatDateTime(transaction.createdDate)}
      />
      <StatusLabel status={transaction.status} />

      <div>
        <Semantic.Header as="h6" color="grey">
          {props.type} #
        </Semantic.Header>
        <Semantic.Header as="h3">{transaction.number}</Semantic.Header>
      </div>
      <div>
        <Semantic.Header as="h6" color="grey">
          Amount
        </Semantic.Header>
        <Semantic.Header as="h3">
          {formatCurrency(Calculator.bankersRound(transaction.total))}
        </Semantic.Header>
      </div>

      <div css={[styles.rowGrid]}>
        <Semantic.Header
          css={[styles.statusCol, styles.status]}
          size="tiny"
          subheader={transactionStatus(transaction)}
        />
        <Semantic.Header as="h5" css={[styles.selectCol]}>
          {props.selected ? (
            <div css={styles.selectText}>
              Selected{' '}
              <Semantic.Icon
                color="green"
                css={styles.selectIcon}
                name="check circle outline"
              />
            </div>
          ) : null}
        </Semantic.Header>
      </div>
    </ListItem>
  )
}

export default TransactionListItem
