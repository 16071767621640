/** @jsx jsx */
import {jsx} from '@emotion/core'

import Button from '@app/components/Button'
import {containerPadding} from '@app/styles/variables'

import sadface from '../../assets/images/sadface.svg'
import {Semantic} from '@mhd/components-library'
import {textDanger} from '@app/styles/colors'

const ErrorPage = (): JSX.Element => {
  return (
    <div
      css={{
        padding: containerPadding,
        textAlign: 'center',
        marginTop: 100,
      }}
    >
      <Semantic.Header as="h1" textAlign="center">
        Error found
      </Semantic.Header>

      <img alt="error" css={{margin: 25}} src={sadface} />

      <p
        css={{
          color: textDanger,
          textAlign: 'center',
          marginBottom: '4rem',
        }}
      >
        An error has been found while trying to authenticate your connection.
        Click the “Back” button to try again.
      </p>

      <Button
        onClick={(): void => {
          window.location.href = '/done'
        }}
      >
        Back
      </Button>
    </div>
  )
}
export default ErrorPage
