/* eslint-disable @typescript-eslint/camelcase */
/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useRef, useContext} from 'react'
import Script from 'react-load-script'
import {useFormContext} from 'react-hook-form'

import Input from '@app/components/Form/Input'
import {UISettingsContext} from '@app/context/UISettingsContext'

import * as styles from './styles'
import {Address} from '@app/models/customerModels'
import Loading from '@app/components/Loading'

declare global {
  interface Window {
    google: any
  }
}

window.google = window.google || {}

interface Place {
  address_components: {
    short_name: string
    long_name: string
    types: string[]
  }[]
}

interface Autocomplete {
  setComponentRestrictions: (options: {country: string[]}) => void
  getPlace: () => Place
  setFields: (fields: string[]) => void
  addListener: (name: string, callback: () => void) => void
}

const addressFields = {
  locality: {
    formField: 'city',
    placeField: 'long_name',
  },
  administrative_area_level_1: {
    formField: 'state',
    placeField: 'short_name',
  },
  postal_code: {
    formField: 'zip',
    placeField: 'short_name',
  },
}

export interface AddressFormInputs {
  address?: Address
}

const AddressInput = (): JSX.Element => {
  const form = useFormContext()
  const settings = useContext(UISettingsContext)
  const idRoot = `bmt__form_address-input_`

  const autocomplete = useRef<Autocomplete | null>(null)

  const handlePlaceSelect = (): void => {
    const place: Place | undefined = autocomplete.current?.getPlace()
    if (!place) return

    const {address_components} = place

    if (address_components) {
      const streetNumber = address_components.find(
        x => x.types[0] === 'street_number',
      )
      const streetAddr = address_components.find(x => x.types[0] === 'route')
      form.setValue(
        'address.lineOne',
        `${streetNumber ? `${streetNumber.short_name} ` : ''}${
          streetAddr ? streetAddr.long_name : ''
        }`,
      )

      for (let i = 0; i < address_components.length; i++) {
        const type = address_components[i].types[0]
        if (addressFields[type]) {
          const val = address_components[i][addressFields[type].placeField]
          form.setValue(`address.${addressFields[type].formField}`, val)
        }
      }
    }
  }

  const handleScriptLoad = (): void => {
    autocomplete.current = new window.google.maps.places.Autocomplete(
      document.getElementById(`${idRoot}address-line-one-field`),
    )
    autocomplete.current?.setComponentRestrictions({country: ['us']})
    autocomplete.current?.setFields(['address_components', 'formatted_address'])
    autocomplete.current?.addListener('place_changed', handlePlaceSelect)
  }

  const handleAddressChange = (e: {target: {value: string}}): void => {
    form.setValue('address.lineOne', e.target.value)
  }

  const lineOne: string = form.watch('address.lineOne')

  const emptyValue = lineOne === '' || lineOne === undefined

  if (!settings) {
    return <Loading al />
  }

  return (
    <div>
      {settings && settings.mapsKey && (
        <Script
          url={`https://maps.googleapis.com/maps/api/js?key=${settings.mapsKey}&libraries=places`}
          onLoad={handleScriptLoad}
        />
      )}

      <div css={styles.fieldWrapper}>
        <label>
          <input
            ref={form.register}
            css={[styles.input, emptyValue ? styles.emptyInput : null]}
            data-testid="address-autocomplete"
            id={`${idRoot}address-line-one-field`}
            name="address.lineOne"
            placeholder=""
            value={lineOne || ''}
            onChange={handleAddressChange}
          />

          <span
            className="label"
            css={
              emptyValue
                ? [styles.label, styles.placeholderLabel]
                : styles.label
            }
          >
            Address
          </span>
        </label>
      </div>

      <Input
        id={`${idRoot}address-line-two-field`}
        label="Line 2"
        name="address.lineTwo"
      />

      <div className="grid" css={styles.cityState}>
        <Input id={`${idRoot}city-field`} label="City" name="address.city" />
        <Input id={`${idRoot}state-field`} label="State" name="address.state" />
      </div>

      <div className="grid" css={styles.zip}>
        <Input
          id={`${idRoot}zip-field`}
          inputMode="numeric"
          label="ZIP"
          name="address.zip"
          type="number"
        />
      </div>
    </div>
  )
}

export default AddressInput
