/** @jsx jsx */
import {jsx} from '@emotion/core'
import {useContext} from 'react'
import {Semantic} from '@mhd/components-library'
import {useQuery} from '@apollo/client'
import {useHistory} from 'react-router'

import NavBarLayout from '@app/components/Layout/NavBarLayout'
import ListItem from '@app/components/List/ListItem'

import {formatDateTime} from '@app/helpers'
import {PageNames} from '@app/helpers/constants'
import useAnalytics from '@app/helpers/customHooks/useAnalytics'
import useGraphOptions from '@app/helpers/customHooks/graphOptions'
import {AuthenticationContext} from '../App'

import {GET_LEADS} from '@app/graphql/lead.graphql'
import {LeadDetail} from '@app/models/leadModels'

import * as styles from './styles'

const LeadList = (): JSX.Element => {
  const {intent, alLeadId} = useContext(AuthenticationContext)
  const history = useHistory()

  const pageName =
    intent === 'quotes' ? PageNames.quoteList : PageNames.invoiceList
  const {track} = useAnalytics(pageName, {leadId: alLeadId ? alLeadId : null})

  const leads = useQuery(GET_LEADS, useGraphOptions({}, {}, true))

  const handleClick = (lead: LeadDetail): void => {
    track('Item Clicked', {
      description: 'user selects a card',
      activityLocation: pageName,
    })
    const customer = {
      ...lead.customerInfo,
      leadId: lead.id,
      alId: lead.customerInfo.id,
      id: undefined,
    }
    if (intent === 'quotes') {
      history.push({
        pathname: '/quotes/new',
        state: {customer},
        search: history.location.search,
      })
    } else {
      history.push({
        pathname: '/invoices/new',
        state: {customer},
        search: history.location.search,
      })
    }
  }

  return (
    <NavBarLayout
      subtitle="Select a lead"
      title={`Create a ${intent === 'quotes' ? 'Quote' : 'Invoice'}`}
      onBack={(): boolean => {
        window.location.href = '/done'
        return false
      }}
    >
      {leads.data &&
        leads.data.getLeads.map((x: LeadDetail) => (
          <ListItem
            key={x.id}
            css={styles.leadListItem}
            onClick={(): void => {
              handleClick(x)
            }}
          >
            <Semantic.Header as="h5" css={styles.leadTime}>
              {formatDateTime(x.createDate)}
            </Semantic.Header>

            <Semantic.Label
              color={x.leadType === 'Deal' ? 'orange' : 'blue'}
              css={styles.leadTypeLabel}
              basic
            >
              {x.leadType}
            </Semantic.Label>

            <Semantic.Header as="h5" css={styles.leadName}>
              {x.customerInfo.fullName}
            </Semantic.Header>

            <Semantic.Header as="h5" color="grey" css={styles.leadInfo}>
              {x.category.name}
              <br />
              {x.customerInfo.address.city}, {x.customerInfo.address.state}
            </Semantic.Header>
          </ListItem>
        ))}
    </NavBarLayout>
  )
}

export default LeadList
